export * from './lib/shared-data-access-graphql.module';
export * from './lib/operations/mutation';
export * from './lib/operations/query';
export * from './lib/operations/query/get-accesses';
export * from './lib/operations/query/social/all-profiles';
export * from './lib/operations/query/get-operations-and-errors';
export * from './lib/operations/query/social/get-user-profile';
export * from './lib/operations/query/organizations/get-organization';
export * from './lib/gql.service';
export * from './lib/gql-context-name';
