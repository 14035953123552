import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { CacheService } from './cache.service';
import { OnboardingDates, Profile, ProfileCache } from '@tgx/shared/interfaces';
import { GqlContextName, GqlService, socialMutations, socialQueries } from '@tgx/shared/data-access/graphql';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  profileSelected = new BehaviorSubject<Profile>(null as any);
  profiles = new BehaviorSubject<Profile[]>(null as any);

  allProfiles: Profile[];

  profileFields: { name: string; label: string }[] = [
    { name: 'name', label: 'Company name' },
    { name: 'websiteUrl', label: 'Website URL' },
    { name: 'description', label: 'Description' },
    { name: 'logoUrl', label: 'Company logo' },
  ];

  organizationFields: { name: string; label: string }[] = [
    { name: 'country', label: 'Headquarters location' },
    { name: 'types', label: 'Company type' },
  ];

  constructor(
    private apollo: Apollo,
    private gqlService: GqlService,
    private cacheService: CacheService,
  ) {}

  setProfile(profile: Profile): void {
    if (!profile && this.profiles.value && this.profiles.value.length > 0) {
      profile = this.profiles.value.sort((a, b) =>
        parseInt(a.code.replace('prf', '')) < parseInt(b.code.replace('prf', '')) ? 1 : -1,
      )[0];
    }

    localStorage.setItem('profile', JSON.stringify(profile));
    this.profileSelected.next(profile);
  }

  getAllProfiles(where: any): Promise<Profile[]> {
    return new Promise((resolve, reject) => {
      this.cacheService.retrieveProfilesByCodes([where.ownerCode.equals]).then(
        (res: ProfileCache[]) => {
          const profiles = this.buildProfilesByCache(res);

          return resolve(
            profiles.sort((a, b) => {
              if (a.profileData.name < b.profileData.name) {
                return -1;
              } else if (a.profileData.name > b.profileData.name) {
                return 1;
              } else {
                return 0;
              }
            }),
          );
        },
        (err) => {
          console.log(err);
          // this.notificationService.handleGatewayAndGraphqlErrors(err);
          return reject(err);
        },
      );
    });
  }

  buildProfilesByCache(profiles: ProfileCache[]): any[] {
    return profiles?.map((profile) => {
      return {
        code: profile.profileCode,
        updatedAt: profile.updatedAt,
        createdAt: profile.createdAt,
        profileData: {
          owner: {
            code: profile.code,
            organizationData: {
              deleted_at: profile.deletedAt,
              isBuyer: profile.isBuyer,
              isSeller: profile.isSeller,
            },
          },
          group: {
            code: profile?.groupCode,
          },
          name: profile.name,
          country: profile.country,
          status: profile.status,
          slug: profile.slug,
          classifications: profile?.classifications,
          logoUrl: profile.logoUrl,
          websiteUrl: profile.websiteUrl,
          buyerCategory: profile.buyerCategory,
          sellerCategory: profile.sellerCategory,
          buyerSubCategory: profile.buyerSubCategory,
          sellerSubCategory: profile.sellerSubCategory,
          isDemandBridge: profile?.isDemandBridge,
          isSupplyBridge: profile?.isSupplyBridge,
          buyerInsightsCountries: profile.buyerInsightsCountries,
          sellerInsightsCountries: profile.sellerInsightsCountries,
          buyerIntegrationType: profile.buyerIntegrationType,
          sellerIntegrationType: profile.sellerIntegrationType,
          buyerOnboardingStart: profile?.buyerOnboardingStart,
          buyerOnboardingEnd: profile?.buyerOnboardingEnd,
          sellerOnboardingStart: profile?.sellerOnboardingStart,
          sellerOnboardingEnd: profile?.sellerOnboardingEnd,
          buyerPartnerStatus: profile?.buyerPartnerStatus,
          sellerPartnerStatus: profile?.sellerPartnerStatus,
          buyerTechBridge: profile?.buyerTechBridge,
          sellerTechBridge: profile?.sellerTechBridge,
        },
      };
    });
  }

  retrieveCompanyTypes(): Promise<any> {
    return new Promise((resolve) => {
      this.apollo
        .use('gateway')
        .query<any>({
          query: gql`
            query IntrospectionQuery {
              __type(name: "ProfileClassification") {
                enumValues {
                  name
                }
              }
            }
          `,
        })
        .subscribe(
          (res: any) => {
            const enumValues = res?.data?.__type?.enumValues;
            if (enumValues?.length > 0) {
              return resolve(enumValues.map((v) => v.name));
            }
            return resolve([]);
          },
          (err) => {
            console.error(err);
          },
        );
    });
  }

  async getCategories(): Promise<{ name: string; subCategories: string[] }[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY_PUBLIC, socialQueries.getCategories)
        .then((res: any) => {
          if (res?.social?.allCategories?.edges) {
            const edges = res.social.allCategories.edges || [];
            resolve(
              edges.map((edge) => {
                const category = edge?.node?.categoryData;
                return {
                  name: category?.code,
                  subCategories: (category?.subCategories?.edges || []).map((sc) => sc.node.code),
                };
              }),
            );
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  retrieveOnboardingDates(orgCode: string): Promise<OnboardingDates> {
    if (!orgCode) return null;
    const body = {
      where: { ownerCode: orgCode },
    };

    return new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY, socialQueries.getOnboardingDates, body)
        .then((apiResponse: any) => {
          resolve({
            buyerOnboardingStart: apiResponse?.social?.profile?.profileData?.buyerOnboardingStart,
            buyerOnboardingEnd: apiResponse?.social?.profile?.profileData?.buyerOnboardingEnd,
            sellerOnboardingStart: apiResponse?.social?.profile?.profileData?.sellerOnboardingStart,
            sellerOnboardingEnd: apiResponse?.social?.profile?.profileData?.sellerOnboardingEnd,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateOnboardingDate(dateToUpdate: string, orgCode: string, date: string): Promise<OnboardingDates> {
    const body = {
      where: { ownerCode: orgCode },
      data: JSON.parse(`{ "${dateToUpdate}": "${date}" }`),
    };

    return new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, socialMutations.updateBuyerOnboardingDate, body)
        .then((apiResponse: any) => {
          resolve({
            buyerOnboardingStart: apiResponse?.social?.updateProfile?.profileData?.buyerOnboardingStart,
            buyerOnboardingEnd: apiResponse?.social?.updateProfile?.profileData?.buyerOnboardingEnd,
            sellerOnboardingStart: apiResponse?.social?.updateProfile?.profileData?.sellerOnboardingStart,
            sellerOnboardingEnd: apiResponse?.social?.updateProfile?.profileData?.sellerOnboardingEnd,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateSellerOnboardingStartedDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('sellerOnboardingStart', orgCode, date);
  }

  updateSellerOnboardingEndDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('sellerOnboardingEnd', orgCode, date);
  }

  updateBuyerOnboardingStartedDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('buyerOnboardingStart', orgCode, date);
  }

  updateBuyerOnboardingEndDate(orgCode: string, date: string): Promise<OnboardingDates> {
    return this.updateOnboardingDate('buyerOnboardingEnd', orgCode, date);
  }

  async getAllProfilesForSearch(): Promise<any[]> {
    return await new Promise((resolve, reject) => {
      this.cacheService
        .retrieveProfiles()
        .then((profiles: ProfileCache[]) => {
          if (profiles?.length) {
            const searchList = profiles.filter((prf) => prf.status === 'PUBLISHED' && !this.isOnboarding(prf));

            resolve(searchList);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // check if the profile has a type being onboarded
  isOnboarding(profile: any) {
    if (profile.buyerCategory && profile.buyerOnboardingEnd) {
      return false;
    }

    if (profile.sellerCategory && profile.sellerOnboardingEnd) {
      return false;
    }

    return true;
  }

  canDisplayTag(profile: any, type: string) {
    if (type.toLocaleLowerCase() === 'buyer') {
      return profile.buyerCategory && profile.buyerOnboardingEnd;
    }
    if (type.toLocaleLowerCase() === 'seller') {
      return profile.sellerCategory && profile.sellerOnboardingEnd;
    }
  }
}
