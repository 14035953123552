import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsTableComponent } from './components/clients-table/clients-table.component';

import { ClientsComponent } from './components/clients.component';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { ClientsLegacyConnectionModalComponent } from './components/clients-legacy-connection-modal/clients-legacy-connection-modal.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ClientsLegacyPasswordModalComponent } from './components/clients-legacyPassword-modal/clients-legacyPassword-modal.component';
import { ClientsLogsModalComponent } from './components/clients-logs-modal/clients-logs-modal.component';

@NgModule({
  declarations: [
    ClientsTableComponent,
    ClientsComponent,
    ClientsLegacyConnectionModalComponent,
    ClientsLegacyPasswordModalComponent,
    ClientsLogsModalComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClientsRoutingModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    MultiSelectModule,
    DropdownModule,
    NbToggleModule,
    TableModule,
    InputSwitchModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ClientsModule {}
