import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'xen-meta-app',
  templateUrl: './meta-app.component.html',
  styleUrls: ['./meta-app.component.scss'],
})
export class MetaAppComponent {
  url: string;
  appIcon = '';

  @Input() appType:
    | 'stats'
    | 'logging'
    | 'alerts'
    | 'speed'
    | 'traffic'
    | 'insights'
    | 'inventory'
    | 'distribution'
    | 'billing'
    | 'connections'
    | 'mapping'
    | 'portfolio'
    | 'tickets'
    | 'b2b' = 'stats';

  @Input() appTitle: string;
  @Input() appSubtitle: string;
  @Input() appInfo: string;
  @Input() appLink: string;
  @Input() buttonLabel: string;
  @Input() haveTutorial: boolean = true;
  @Input() tutorialUrlSafe: SafeResourceUrl;

  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    const urlMap = {
      'stats': 'https://www.youtube.com/embed/HTp6aG1rpwQ',
      'logging': 'https://www.youtube.com/embed/qyaafcLhR9c',
      'alerts': 'https://www.youtube.com/embed/g8JSJ_OBlRY',
      'speed': 'https://www.youtube.com/embed/uZO0Wm74wMA?si=xcgNtCULmuRGlkoE',
      'traffic': 'https://www.youtube.com/embed/3383vV-Ggu8?si=eEkakJI8D95rseSy',
      'billing': 'https://www.youtube.com/embed/s-E0AQuS3HI',
      'insights': '',
      'connections': 'https://www.youtube.com/embed/yb8FeFRsQ1A',
    };

    if (urlMap[this.appType]) {
      this.tutorialUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlMap[this.appType]);
    }
  }

  goLink() {
    window.open(this.appLink, '_blank');
  }

  openModal(tutorial) {
    this.modalService.open(tutorial, {
      centered: true,
      size: 'lg',
      windowClass: 'xen-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.activeModal.close();
    this.modalService.dismissAll();
  }
}
