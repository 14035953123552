import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { ContractDiscountService } from './../../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  ObjectWhereUniqueInputById,
  ContractDiscount,
  ContractDiscountCreateInput,
  ContractDiscountUpdateInput,
} from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-discounts-modal',
  styleUrls: ['./discounts-modal.component.scss'],
  templateUrl: 'discounts-modal.component.html',
})
export class BillingDiscountsModalComponent implements OnInit {
  @Input() discount: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;

  isCreating: boolean;
  discountAux: ContractDiscount;
  ended = false;

  buyerOptions: any[];
  sellerOptions: any[];

  public discountForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private contractDiscountService: ContractDiscountService,
  ) {}

  async ngOnInit() {
    this.isCreating = true;

    if (this.discount) {
      this.isCreating = false;
    }

    this.buildForm();

    if (!this.isCreating) {
      this.discountForm.get('startDate').setValue(new Date(this.discount.startDate));
      this.discountForm.get('endDate').setValue(new Date(this.discount.endDate));
      this.discountForm.get('concept').setValue(this.discount.concept);
      this.discountForm.get('amount').setValue(this.discount.amount);
      this.discountForm.get('account').setValue(this.discount.account);

      this.discountForm.get('startDate').disable();
      this.discountForm.get('endDate').disable();

      if (!this.isEditor) {
        this.discountForm.get('startDate').disable();
        this.discountForm.get('endDate').disable();
        this.discountForm.get('concept').disable();
        this.discountForm.get('amount').disable();
        this.discountForm.get('account').disable();
      }
    }
  }

  buildForm() {
    this.discountForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      concept: ['', Validators.required],
      amount: ['', Validators.required],
      account: ['', Validators.required],
    });
  }

  closeModal() {
    this.discount = clone(this.discountAux);
    this.activeModal.close(true);
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create
      let date = new Date(this.discountForm.get('startDate').value);
      let dateStr = date.getFullYear().toString();
      let month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      let day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T00:00:00.000Z';

      const startDate: any = dateStr;

      date = new Date(this.discountForm.get('endDate').value);
      dateStr = date.getFullYear().toString();
      month = date.getMonth() + 1;
      if (month < 10) {
        dateStr += '-0' + month.toString();
      } else {
        dateStr += '-' + month.toString();
      }
      day = date.getDate();
      if (day < 10) {
        dateStr += '-0' + day.toString();
      } else {
        dateStr += '-' + day.toString();
      }
      dateStr += 'T23:59:59.000Z';

      const endDate: any = dateStr;

      const createData: ContractDiscountCreateInput = {
        startDate: startDate,
        endDate: endDate,
        concept: this.discountForm.get('concept').value,
        amount: this.discountForm.get('amount').value.toString(),
        account: this.discountForm.get('account').value,
        Contract: { connect: { id: this.contractId } },
      };

      this.contractDiscountService
        .createContractDiscount(createData)
        .then((rs) => {
          this.notificationService.success('Contract discount succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: ContractDiscountUpdateInput = {
        concept: { set: this.discountForm.get('concept').value },
        amount: { set: this.discountForm.get('amount').value.toString() },
        account: { set: this.discountForm.get('account').value },
      };

      const where: ObjectWhereUniqueInputById = { id: this.discount.id };

      this.contractDiscountService
        .updateContractDiscount(updateData, where)
        .then((rs) => {
          this.notificationService.success('Contract discount succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
