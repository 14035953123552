export enum NotificationsType {
  release = 'release',
  other = 'other',
  network = 'network',
  billing = 'billing',
  tickets = 'tickets',
  connections = 'connections',
  apps = 'apps',
  users = 'users',
  inventory = 'inventory',
}
