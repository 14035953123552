import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { BehaviorSubject } from 'rxjs';
import { getWidgetProfiles } from './queries/getWidgetProfiles';
import { CacheService } from '@tgx/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ProfileNetworkService {
  prfCodes = new BehaviorSubject<string[]>(null);

  constructor(
    private gqlService: GqlService,
    private cacheService: CacheService,
  ) {}

  async getPartnersWithoutCrmID(): Promise<number> {
    return await new Promise((resolve) => {
      this.cacheService
        .retrieveProfiles()
        .then((profiles) => {
          return resolve(profiles.filter((profile) => profile.crmId === null).length);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  getProfilesWidget(): Promise<{ data: Map<string, string>; mapPrfsNoOrgs?: Map<string, string>; error?: any }> {
    const mapPrfsWithOrgs = new Map<string, string>();
    const mapPrfsNoOrgs = new Map<string, string>();

    return this.gqlService
      .queryGateway(getWidgetProfiles)
      .then((res: any) => {
        const edges = res?.social?.allProfilesV2?.edges;
        if (edges?.length > 0) {
          edges.forEach((elem: any) => {
            const prfData = elem?.node?.profileData;
            if (prfData?.owner.code) {
              mapPrfsWithOrgs.set(elem.node.code, prfData.owner.code);
            } else {
              mapPrfsNoOrgs.set(elem.node.code, prfData.name);
            }
          });

          return { data: mapPrfsWithOrgs, mapPrfsNoOrgs: mapPrfsNoOrgs, total: edges.length };
        } else {
          return { data: null, mapPrfsNoOrgs: null, error: 'The social response is empty' };
        }
      })
      .catch((err) => {
        return { data: null, mapPrfsNoOrgs: null, error: err };
      });
  }

  setProfilesCodes(prfCodes) {
    this.prfCodes.next(prfCodes);
  }
}
