import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { WebAdminService } from './../../../../../../../@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from './../../../../../../../features/entities/interfaces/_index-entities.interfaces';
import {
  ContractService,
  PartnerService,
  InvoiceService,
  ContractConnectionService,
  ConnectionService,
  BillingGatewayService,
} from '../../../../../services/_index-billing.services';
import {
  Contract,
  Partner,
  ContractConnection,
  ConciliateData,
  ConciliateConnection,
  ConciliateBreakdown,
  PartnerModelType,
  ConciliateConnectionItem,
  ConnectionFullInfo,
  Invoice,
} from '../../../../../interfaces/_index.billing.interfaces';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'tgx-admin-billing-invoice-legacy-conciliate',
  templateUrl: './invoice-legacy-conciliate.component.html',
  styleUrls: ['./invoice-legacy-conciliate.component.scss'],
})
export class BillingInvoiceLegacyConciliateComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable', { static: false }) dataTable!: Table;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  $subs: Subscription[] = [];
  orgSelected: OrganizationSelector;

  contracts: Contract[];
  partner: Partner;

  isByItem = false;
  isLoading: boolean;
  isEditor: boolean;

  startDate: any;
  endDate: any;

  billSource = [];
  selectedBill = [];

  connectionSuggestion: any[] | undefined;

  mapClients: Map<string, string[]>;
  mapSuppliers: Map<string, string[]>;

  clientSuggestion: any[] | undefined;
  supplierSuggestion: any[] | undefined;

  validConnectionSelected = false;
  validClientSelected = false;

  invoices: Invoice[];
  invoicesSelector: Options[] = [{ code: null, label: 'None' }];
  selectedInvoice: Options;
  startDateEnabled = true;
  endDateEnabled = true;

  header = [
    { label: 'Connection', field: 'connection' },
    { label: 'Client', field: 'client' },
    { label: 'Supplier', field: 'supplier' },
    { label: 'Amount', field: 'amount' },
    { label: 'Quantity', field: 'quantity' },
    { label: 'Type', field: 'type' },
    { label: 'Options', field: 'Options' },
  ];

  types = [
    { value: 'Setup', title: 'Setup' },
    { value: 'Maintenance', title: 'Maintenance' },
    { value: 'Traffic', title: 'Traffic' },
    { value: 'GMV', title: 'GMV' },
    { value: 'Excess', title: 'Excess' },
    { value: 'Bookings', title: 'Bookings' },
  ];

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private invoiceService: InvoiceService,
    private contractConnectionService: ContractConnectionService,
    private connectionService: ConnectionService,
    private readonly billingGatewayService: BillingGatewayService,
    private modalService: NgbModal,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildOptions();

    this.mapClients = new Map<string, string[]>();
    this.mapSuppliers = new Map<string, string[]>();

    if (this.isEditor) {
      this.$subs.push(
        this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
          if (orgSelected?.code) {
            this.orgSelected = orgSelected;
            await this.partnerService.getPartner(orgSelected.code).then((partner) => {
              if (partner) {
                this.partner = partner;
                this.getContracts(this.partner.orgCode);
              } else {
                this.partner = null;
                this.withoutContracts();
                this.notificationService.warning('This partner doesnt exists in billing section.');
              }
            });
          }
        }),
      );

      await this.billingGatewayService
        .getAllClientSuppliers()
        .then((rs) => {
          rs?.allClients?.edges?.forEach((edge) => {
            const currentNode = edge?.node?.clientData;
            if (currentNode) {
              if (!this.mapClients.has(currentNode.owner?.code)) {
                this.mapClients.set(currentNode.owner?.code, []);
              }
              this.mapClients.get(currentNode.owner?.code).push(currentNode.name);
            }
          });

          rs?.allSuppliers.edges.forEach((edge) => {
            const currentNode = edge?.node?.supplierData;
            if (currentNode) {
              if (!this.mapSuppliers.has(currentNode.owner?.code)) {
                this.mapSuppliers.set(currentNode.owner?.code, []);
              }
              this.mapSuppliers.get(currentNode.owner?.code).push(currentNode.name + ' || ' + currentNode.code);
            }
          });
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  validConnection($event: any, dropdown: string) {
    switch (dropdown) {
      case 'CONNECTION':
        this.validConnectionSelected = $event?.connection?.value !== '';
        this.buildClientSuggestions(this.validConnectionSelected ? $event?.connection?.buyer : '');
        break;
      case 'CLIENT':
        this.validClientSelected = $event?.client?.value !== '';
        this.buildSupplierSuggestions(this.validClientSelected ? $event?.connection?.seller : '');
        break;
    }
  }

  buildClientSuggestions(owner: string) {
    this.clientSuggestion = [];
    if (owner) {
      this.clientSuggestion.push({
        value: 'Main',
        title: 'Main',
      });
      if (this.mapClients.has(owner)) {
        this.mapClients.get(owner).forEach((cliName) => {
          this.clientSuggestion.push({
            value: cliName,
            title: cliName,
          });
        });
      }
    }
  }

  buildSupplierSuggestions(owner: string) {
    this.supplierSuggestion = [];
    if (owner) {
      this.supplierSuggestion.push({
        value: 'Main',
        title: 'Main',
      });
      if (this.mapSuppliers.has(owner)) {
        this.mapSuppliers.get(owner).forEach((supplierName) => {
          this.supplierSuggestion.push({
            value: supplierName,
            title: supplierName,
          });
        });
      }
    }
  }
  addRow() {
    this.validConnectionSelected = false;
    this.validClientSelected = false;

    this.billSource = [
      {
        id: this.billSource.length,
        connection: { value: '', buyer: '', seller: '', title: '' },
        client: { value: '', title: '' },
        supplier: { value: '', title: '' },
        amount: 0,
        quantity: 0,
        type: { value: '', title: '' },
      },
      ...this.billSource,
    ];
    this.dataTable.initRowEdit(this.billSource[0]);
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async getContracts(orgCode: string) {
    if (orgCode) {
      await this.contractService
        .getContracts(orgCode)
        .then(async (cnts) => {
          if (cnts?.length > 0) {
            this.contractsSelector = [];
            this.contracts = cnts.filter((x) => x.modelType !== PartnerModelType.CM22);

            if (this.contracts?.length === 0) {
              this.withoutContracts();
            } else {
              this.buildSelector();
              this.selectedContract = this.contractsSelector[0];
              await this.getConnections();
              await this.getInvoices();
            }
          } else {
            this.withoutContracts();
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  async getInvoices() {
    this.invoices = [];

    if (this.selectedContract?.code) {
      await this.invoiceService
        .getInvoices(Number(this.selectedContract?.code))
        .then((rs) => {
          if (rs?.length > 0) {
            this.invoices = rs;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });

      if (this.invoices?.length > 0) {
        this.buildInvoicesSelector();
      } else {
        this.withoutInvoices();
      }
    } else {
      this.withoutInvoices();
    }
  }

  withoutInvoices() {
    this.invoicesSelector = [];
    this.invoicesSelector.push({ code: null, label: 'Without invoices' });
  }

  buildInvoicesSelector() {
    this.invoicesSelector = [];
    this.invoicesSelector.push({ code: null, label: 'None' });

    this.invoices = this.invoices.slice().sort((x, y) => {
      if (x.id > y.id) {
        return -1;
      }
      if (x.id < y.id) {
        return 1;
      }
      return 0;
    });

    this.invoices?.forEach((inv) => {
      const amount = Number(inv.amount);
      if (amount > 0) {
        const startMonth = formatDate(inv.startDate, 'MMM-yy', 'en-US');

        this.invoicesSelector.push({
          code: inv.id.toString(),
          label: `InvoiceId: ${inv.id} Amount: ${inv.amount} ${inv.currency} Month: ${startMonth} OdooCode: ${inv.odooCode ? inv.odooCode : 'Without code'}`,
        });
      }
    });
  }

  assignStartAndEnd() {
    if (this.selectedInvoice?.code) {
      const index = this.invoices.findIndex((x) => x.id === Number(this.selectedInvoice?.code));
      if (index > -1) {
        this.startDate = new Date(this.invoices[index].startDate);
        this.endDate = new Date(new Date(this.invoices[index].endDate).setDate(-0.5));
        this.startDateEnabled = false;
        this.endDateEnabled = false;
        return;
      }
    }
    this.startDateEnabled = true;
    this.endDateEnabled = true;
    this.startDate = null;
    this.endDate = null;
  }

  async getConnections() {
    if (this.selectedContract?.code) {
      const contractConnections = await this.contractConnectionService.getContractConnections(
        Number(this.selectedContract?.code),
      );

      const connectionCodes: number[] = [];

      if (contractConnections?.length > 0) {
        contractConnections.forEach((con) => {
          const index = connectionCodes.findIndex((x) => x === con.connection);

          if (index === -1) {
            connectionCodes.push(con.connection);
          }
        });
      }

      let connections: ConnectionFullInfo[] = [];

      if (connectionCodes.length > 0) {
        await this.connectionService
          .getConnectionsFullInfoById(connectionCodes)
          .then((rs) => {
            connections = rs;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
            this.isLoading = false;
          });
      }

      if (contractConnections?.length > 0) {
        this.buildConnectionsSelector(contractConnections, connections);
      } else {
        this.withoutConnections();
      }
    } else {
      this.withoutConnections();
    }
  }

  withoutConnections() {
    this.connectionSuggestion = [];
  }

  async buildConnectionsSelector(contractConnections: ContractConnection[], connections: ConnectionFullInfo[]) {
    const conIds: string[] = [];

    this.connectionSuggestion = [];

    contractConnections.forEach((con) => {
      const index = conIds.findIndex((x) => x === con.id.toString());

      const indexSup = connections.findIndex((x) => x.id === con.connection);

      if (index === -1 && indexSup > -1) {
        this.connectionSuggestion.push({
          value: con.id.toString(),
          buyer: connections[indexSup].buyerCode.toLowerCase(),
          seller: connections[indexSup].sellerCode.toLowerCase(),
          title: `ContractConnectionId: ${con.id} Buyer: ${connections[indexSup].buyerName} (${connections[indexSup].buyerCode}) Seller: ${connections[indexSup].sellerName} (${connections[indexSup].sellerCode})`,
        });
        conIds.push(con.id.toString());
      }
    });
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without contracts' });
  }

  async onCreate($event: any): Promise<void> {
    $event.amount = $event.amount.replaceAll(',', '.');
    $event.quantity = $event.quantity.replaceAll(',', '.');
    const bills = this.billSource.filter(
      (x) =>
        x.connection.title === $event.connection.title &&
        x.client.title === $event.client.title &&
        x.supplier.title === $event.supplier.title &&
        x.type.value === $event.type.value,
    );

    if (bills.length > 1) {
      this.billSource = this.billSource.filter((bill) => {
        return (
          bill.connection.value !== $event.connection.value ||
          $event.client.value !== bill.client.value ||
          $event.supplier.value !== bill.supplier.value ||
          $event.quantity !== bill.quantity ||
          $event.amount !== bill.amount ||
          bill.type.value !== $event.type.value
        );
      });
      //Fill table with first bill if the filter remove both
      if (!this.billSource.length) {
        this.billSource = [bills[0]];
      }
      this.notificationService.error(
        'Already exists an entry for same connection and same type, please update it or remove it first.',
      );
    }
  }

  onDelete($event): void {
    this.billSource = this.billSource.filter((bill) => {
      return (
        bill.connection.value !== $event.connection.value ||
        $event.client.value !== bill.client.value ||
        $event.supplier.value !== bill.supplier.value ||
        bill.type.value !== $event.type.value
      );
    });
  }

  resetTable() {
    this.startDate = undefined;
    this.endDate = undefined;
    this.billSource = [];
    this.selectedInvoice = null;
  }

  async runConciliate() {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to create a new conciliation invoice with these parameters?';
    modalRef.componentInstance.title = 'Create Conciliation';
    modalRef.componentInstance.buttonOkTitle = 'Bill';

    const self = this;

    let allValid = true;

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          const mapConnectionBreakdowns = new Map<number, Map<string, ConciliateConnectionItem>>();

          this.billSource.forEach((row) => {
            const index = this.connectionSuggestion.findIndex((x) => x.title === row.connection.title);
            let conID: number;

            if (index > -1) {
              conID = Number(this.connectionSuggestion[index].value);

              if (!mapConnectionBreakdowns.has(conID)) {
                mapConnectionBreakdowns.set(conID, new Map<string, ConciliateConnectionItem>());
              }

              const cliName = row.client?.value ? row.client?.value : 'Main';
              const supName = row.supplier?.value ? row.supplier?.value : 'Main';

              const supData = supName.split(' || ');
              const supCode = supData?.length > 1 ? supData[1] : '';

              const key = cliName + '|' + supName;

              if (!mapConnectionBreakdowns.get(conID).has(key)) {
                mapConnectionBreakdowns
                  .get(conID)
                  .set(key, { clientName: cliName, supplierName: supData[0], supplierCode: supCode });
              }

              const item: ConciliateConnectionItem = mapConnectionBreakdowns.get(conID).get(key);

              const bkd: ConciliateBreakdown = { amount: Number(row.amount), quantity: Number(row.quantity) };

              if (bkd.amount >= 0 || bkd.quantity >= 0) {
                allValid = false;
              }

              switch (row.type?.value) {
                case 'GMV':
                  item.gmv = bkd;
                  break;
                case 'Setup':
                  item.setup = bkd;
                  break;
                case 'Maintenance':
                  item.maintenance = bkd;
                  break;
                case 'Traffic':
                  item.traffic = bkd;
                  break;
                case 'Excess':
                  item.excess = bkd;
                  break;
                case 'Bookings':
                  item.bookings = bkd;
                  break;
              }
            }
          });

          if (allValid) {
            const rq: ConciliateData = { conciliateConnections: [] };

            mapConnectionBreakdowns.forEach((mapItems: Map<string, ConciliateConnectionItem>, conId: number) => {
              const connection: ConciliateConnection = {
                contractConnectionId: conId,
                conciliateConnectionItems: Array.from(mapItems.values()),
              };

              rq.conciliateConnections.push(connection);
            });

            this.isLoading = true;

            const startDate = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US');
            const endDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US');

            const invoiceId = this.selectedInvoice?.code ? this.selectedInvoice.code : '';

            await this.invoiceService
              .legacyConciliateInvoice(
                this.partner.id,
                Number(this.selectedContract.code),
                startDate,
                endDate,
                rq,
                invoiceId,
              )
              .then((file) => {
                const fileName = 'billing_conciliate_' + endDate + '.json';
                saveAs(file, fileName);
                this.isLoading = false;
                this.notificationService.success(
                  'Invoice succesfully conciliated and ' + fileName + ' downloaded!',
                  'Success',
                );
                this.resetTable();
              })
              .catch((err) => {
                const parsedErr = JSON.parse(err.error);
                this.notificationService.handleGatewayAndGraphqlErrors(parsedErr.errors[0]);
                this.isLoading = false;
              });
          } else {
            this.notificationService.error('Some amounts or quantities are positive, all must be negative.');
          }
        }
      })
      .catch((error) => {
        this.isLoading = false;
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }
}
