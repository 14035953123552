import { Component, Input, OnInit, forwardRef, OnChanges } from '@angular/core';
import { FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'xen-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements OnInit, OnChanges {
  form: FormGroup;

  @Input() checked: boolean;
  @Input() indeterminated: boolean;
  @Input() disabled: boolean;
  @Input() reverse: boolean;
  @Input() label: string;
  @Input() value: string;
  @Input() id: string;
  @Input() formControlName: string;
  @Input() validation: boolean;
  @Input() validation_status: string;
  @Input() feedback: string;
  @Input() tooltip: boolean;
  @Input() tooltip_text: string;
  @Input() tooltip_placement: string;

  tooltipText: string;
  isHtmlLabel: boolean;

  constructor(private rootFormGroup: FormGroupDirective) {}

  onChange() {}
  onTouched() {}

  ngOnInit(): void {
    this.isHtmlLabel = this.containsHtml(this.label);
    this.form = this.rootFormGroup.control;

    if (this.disabled) {
      this.form.controls[this.formControlName].disable();
    }
  }

  ngOnChanges(): void {
    if (this.disabled && this.form) {
      this.form.get(this.formControlName).disable();
    } else if (this.form) {
      this.form.get(this.formControlName).enable();
    }
  }

  public get classes(): Array<string> {
    return ['form-check xen-checkbox', this.reverse ? 'form-check-reverse' : ''];
  }

  public get inputClasses(): Array<string> {
    return [
      this.validation && this.validation_status ? `${this.validation_status}` : '',
      this.indeterminated ? 'is-indeterminated' : '',
    ];
  }

  public get feedbackClasses(): Array<string> {
    return [
      this.validation && this.validation_status === 'is-valid' ? 'valid-feedback' : '',
      this.validation && this.validation_status === 'is-invalid' ? 'invalid-feedback' : '',
    ];
  }

  private containsHtml(text: string): boolean {
    const doc = new DOMParser().parseFromString(text, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
}
