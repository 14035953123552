import { gql } from 'apollo-angular';

export const getAllCurrentContracts = gql`
  query {
    getAllCurrentContracts {
      orgCode
      partnerType
      contractEndDate
    }
  }
`;
