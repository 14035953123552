import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getUserProfile, GqlService, getOrganization } from '@tgx/shared/data-access/graphql';
import { UserProfile } from '@tgx/shared/interfaces';
import { InputNotification } from '../modules/notifications/interfaces/inputNotification.interface';
import { PlatformBrowserService } from '@tgx/shared/services';
import { environment } from 'environments/environment.production';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private gqlService: GqlService,
    private platformBrowserService: PlatformBrowserService,
    private http: HttpClient,
  ) {}

  async getSocialUser(myemail: string): Promise<UserProfile> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(getUserProfile, { where: { email: myemail } })
        .then((response: any) => {
          if (response['social']['userProfile']) {
            resolve(response['social']['userProfile']);
          } else {
            reject("The user doesn't exist.");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOrganization(orgCode: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(getOrganization, { where: { search: orgCode, searchBy: 'CODE' } })
        .then((response: any) => {
          if (response.adviseMessage?.length > 0) {
            reject(response);
          } else if (response.organizationsData !== null) {
            resolve(response);
          } else {
            reject('No response');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // SEND NOTIFICATION
  sendNotification(inputNotification: InputNotification): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const apiUrl = environment.apis.notifications_service + `send-notification`;

      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + this.platformBrowserService.getToken());
      headers = headers.set('accept', 'application/json');

      if (!inputNotification) {
        return reject('Error');
      }

      this.http.post<string>(apiUrl, inputNotification, { headers, responseType: 'text' as 'json' }).subscribe({
        next: (res: string) => {
          resolve(res);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
