import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleType } from '../../../../../../@core/interfaces/user-permissions';
import { NotificationService } from '../../../../../../@core/services/notification.service';
import { WebAdminService } from '../../../../../../@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector, SupplierSelector } from '../../../../interfaces/_index-entities.interfaces';
import { AccessesService } from '../../../../services/accesses.service';
import { Router } from '@angular/router';
import { AccessEdge } from '../../../../interfaces/models/accesses.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Options } from '../../../../../../@core/interfaces/_index.interfaces';
import { OrganizationEntitiesService } from '../../../../services/organization-entities.service';
import { AccessesModalComponent } from '../accesses-modal/accesses-modal.component';
import { AccessesGroupsModalComponent } from '../accesses-groups-modal/accesses-groups-modal.component';
import { AccessesSafeDeactivateModalComponent } from '../accesses-safeDeactivate-modal/accesses-safeDeactivate-modal.component';
import { AccessesToggleActiveModalComponent } from '../accesses-ToggleActive-modal/accesses-ToggleActive-modal.component';
import { AccessLogsModalComponent } from '../accesses-logs-modal/accesses-logs-modal.component';

@Component({
  selector: 'tgx-admin-accesses-table',
  templateUrl: './accesses-table.component.html',
  styleUrls: ['./accesses-table.component.scss'],
})
export class AccessesTableComponent implements OnInit, OnDestroy {
  selectedAccesses: any[];
  selectedAccessesAux: any[];
  clientName: string;
  isLoading: boolean;
  accesses: AccessEdge[];
  accessesLoadSource: any[];
  sharedAccesses: any[];
  groups: Options[];
  $subs: Subscription[] = [];

  public queryFormOrganizations: UntypedFormGroup;
  organizations: Options[];
  owners: Options[];
  suppliers: Options[];

  providers: Options[];
  groupContent_context: Options[];
  orgSelectors: OrganizationSelector[];
  supplierSelectors: SupplierSelector[];
  ownerSelectors: Options[];
  selectedOwner: Options;
  selecteSupplier: Options;

  selectedOrg: Options;
  selectedProvider: Options;

  header = [
    { label: 'Code', field: 'code', placeholder: 'Code' },
    { label: 'Name', field: 'name', placeholder: 'Name' },
    { label: 'Owner', field: 'ownerCode', placeholder: 'Owner' },
    { label: 'Supplier code', field: 'supplier', placeholder: 'Supplier code' },
    { label: 'Active', field: 'isActive', placeholder: 'Active' },
    { label: 'Archived', field: 'deleteAt', placeholder: 'Archived' },
    { label: 'Shared', field: 'shared', placeholder: 'Shared' },
    { label: 'Options', field: 'Options', placeholder: 'Options' },
  ];

  supplierCode: string;
  selectedGroupContentContext: Options;
  assignedGroupsOptions: Options[];

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private notificationService: NotificationService,
    private accessesService: AccessesService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private organizationsService: OrganizationEntitiesService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      supplierCode: string;
    };

    this.supplierCode = state && state.supplierCode ? state.supplierCode : '';
  }

  onCustom($event: any, action: string): void {
    switch (action) {
      case 'edit-access':
        this.onEdit($event);
        break;
      case 'access-groups':
        this.onAccessGroups($event);
        break;
      case 'access-deactivate':
        this.onSafeDeactivate($event);
        break;
      case 'access-logs':
        this.onLogs($event);
        break;
    }
  }

  async onAccessGroups(access: any) {
    const activeModal = this.modalService.open(AccessesGroupsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Accesses Groups | ' + access.name;
    activeModal.componentInstance.organizations = this.organizations;
    activeModal.componentInstance.access = access;
  }

  async onSafeDeactivate(event: any) {
    const access: any = event;
    const activeModal = this.modalService.open(AccessesSafeDeactivateModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Confirm deactivate: ' + access.code;
    activeModal.componentInstance.access = access;
  }

  async onEdit(event: any) {
    const access: any = event;
    const activeModal = this.modalService.open(AccessesModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    //filter organizations to only show the related organization.
    const filteredOrganizations = this.organizations.filter((org) => org.code === access.ownerCode);
    if (filteredOrganizations.length > 0) {
      this.selectedOrg = filteredOrganizations[0];
    }

    activeModal.componentInstance.header = 'Update Access';
    activeModal.componentInstance.organizations = this.organizations;
    activeModal.componentInstance.selectedOrg = this.selectedOrg;
    activeModal.componentInstance.groups = this.groups;
    activeModal.componentInstance.isCreating = false;
    activeModal.componentInstance.access = access;

    let showOwner = false;
    this.organizations.forEach((org) => {
      if (org.code === access.ownerCode) {
        showOwner = true;
      }
    });

    activeModal.componentInstance.showOwner = showOwner;

    activeModal.result.then(async (res) => {
      // on close
      if (typeof res === 'boolean' && res) {
        //todo  ??
      } else if (typeof res === 'boolean' && !res) {
        //todo  ??
      }
      // OK
      else if (typeof res === 'object') {
        this.notificationService.success('Access succesfully updated.', 'Success');
        await this.retrieveFilteredAccesses();
      }
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.isLoading = false;
    this.organizations = [];
    this.orgSelectors = [];

    this.$subs.push(
      this.accessesService.onToggleTable$.subscribe((access) => {
        if (access) {
          this.toggleActive(access);
        }
      }),
      this.accessesService.onToggleArchive$.subscribe((access) => {
        if (access) {
          this.toggleArchive(access);
        }
      }),

      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.orgSelectors = this.orgSelectors.concat(orgs);
          this.buildOrganizationsDropdown(orgs);
          this.organizations = this.organizations.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
          );
        }
      }),

      this.webAdminService.allTeams.subscribe((teams) => {
        if (teams?.length > 0) {
          this.orgSelectors = this.orgSelectors.concat(teams);
          this.buildOrganizationsDropdown(teams);
          this.organizations = this.organizations.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
          );
        }
      }),

      this.webAdminService.allSuppliers.subscribe((suppliers) => {
        if (suppliers?.length > 0) {
          this.supplierSelectors = suppliers;
          this.buildSuppliersDropdown();
        }
      }),
    );

    if (this.supplierCode !== '') {
      this.queryFormOrganizations
        .get('filter_supplier')
        .setValue([{ code: this.supplierCode, label: this.supplierCode }]);
      this.retrieveFilteredAccesses();
    }
  }

  toggleActive(access) {
    const isActived = access.isActive;
    const action = isActived ? 'deactived' : 'actived';
    const actionTitle = isActived ? 'Deactive' : 'Active';
    const message = `Are you sure you want to ${action} access ${access.code}?`;

    const modalRef = this.modalService.open(AccessesToggleActiveModalComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = message;
    modalRef.componentInstance.header = `${actionTitle} access`;
    modalRef.componentInstance.btnLabel = actionTitle;

    modalRef.dismissed.subscribe(() => {
      access.isActiveModel = access.isActive;
    });

    modalRef.result.then((confirmed) => {
      if (confirmed) {
        const activeAction = !isActived
          ? this.accessesService.activateAccess({ search: access.code, searchBy: 'ID' })
          : this.accessesService.deactivateAccess({ search: access.code, searchBy: 'ID' });

        activeAction
          .then(() => {
            this.notificationService.success(`Access successfully ${action}`, 'Success');
            access.isActiveModel = !access.isActive;
            access.isActive = !isActived;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
      }
    });
  }

  toggleArchive(access) {
    const isArchived = access.isDeleted;
    const action = isArchived ? 'archived' : 'unarchived';
    const actionTitle = isArchived ? 'Archive' : 'Unarchive';
    const message = `Are you sure you want to ${action} access ${access.code}?`;

    const modalRef = this.modalService.open(AccessesToggleActiveModalComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = message;
    modalRef.componentInstance.header = `${actionTitle} access`;
    modalRef.componentInstance.btnLabel = actionTitle;

    modalRef.dismissed.subscribe(() => {
      access.isDeleted = !access.isDeleted;
    });

    modalRef.result.then((confirmed) => {
      if (confirmed) {
        const archiveAction = isArchived
          ? this.accessesService.archiveAccess({ search: access.code, searchBy: 'ID' })
          : this.accessesService.unArchiveAccess({ search: access.code, searchBy: 'ID' });

        archiveAction
          .then(() => {
            this.notificationService.success(`Access successfully ${action}`, 'Success');
            access.isArchived = !isArchived;
          })
          .catch((err) => {
            this.notificationService.handleGatewayAndGraphqlErrors(err);
          });
      }
    });
  }

  async retrieveFilteredAccesses() {
    let executeSearch = false;
    const input: any = {};

    const filterAccessId = this.queryFormOrganizations.get('filter_accessID')?.value;
    if (filterAccessId) {
      input.code_in = [filterAccessId];
      executeSearch = true;
    }

    const supplierCodes =
      this.queryFormOrganizations.get('filter_supplier').value !== ''
        ? this.queryFormOrganizations.get('filter_supplier').value.map((value) => value.code)
        : null;
    if (supplierCodes && supplierCodes.length > 0) {
      input.supplier_in = supplierCodes && supplierCodes.length > 0 ? supplierCodes : [];
      executeSearch = true;
    }

    const filterOwners =
      this.queryFormOrganizations.get('filter_owners').value !== ''
        ? this.queryFormOrganizations.get('filter_owners').value.map((value) => value.code)
        : null;
    if (filterOwners && filterOwners.length > 0) {
      input.owner_in = filterOwners && filterOwners.length > 0 ? filterOwners : [];
      executeSearch = true;
    }

    const includeArchived = this.queryFormOrganizations.get('includeArchived')?.value ?? false;
    input['includeArchived'] = includeArchived;

    if (executeSearch) {
      const filteredAccesses = await this.accessesService.getAllAccesses(input);

      this.selectedAccesses = [];
      this.selectedAccessesAux = [];
      this.accesses = filteredAccesses;
      this.buildAccesses();
      this.isLoading = false;
    } else {
      this.notificationService.info('Please, set filters to get some results');
    }
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.ENTITIES);
  }

  composeContext = (groupContentDataCode: string, contextDataCode: string) => {
    let context = groupContentDataCode && groupContentDataCode ? groupContentDataCode : '';
    context += ' : ';
    context += contextDataCode ? contextDataCode : '';
    return ' : ' === context ? '' : context;
  };

  buildAccesses() {
    this.accessesLoadSource = [];
    this.sharedAccesses = [];
    const checkSharedCode = [];
    this.accesses.forEach((mb) => {
      const data = this.formatAccessForSource(mb.node);
      this.accessesLoadSource.push(data);
      if (data.shared || data.shared === '') {
        checkSharedCode.push(data.shared);
      }
      this.isLoading = false;
    });
    if (checkSharedCode.length) {
      for (const code of checkSharedCode) {
        if (!this.sharedAccesses.includes(code)) {
          this.sharedAccesses.push(code);
        }
      }
    }
  }

  formatAccessForSource(mb: any) {
    const master = mb.accessData.master && mb.accessData.master.code ? mb.accessData.master.code : '';
    const shared = mb.accessData.shared && mb.accessData.shared.code ? mb.accessData.shared.code : '';
    const supplier = mb.accessData.supplier && mb.accessData.supplier ? mb.accessData.supplier.code : '';
    const ownerCode = mb.accessData?.owner?.code ?? '';

    return {
      code: mb.accessData.code,
      name: mb.accessData.name,
      isActive: mb.accessData.isActive,
      isActiveModel: mb.accessData.isActive,
      isTest: mb.accessData.isTest,
      isShared: mb.accessData.isShared,
      isSchedulerActive: mb.accessData.isSchedulerActive,
      user: mb.accessData.user,
      password: mb.accessData.password,
      parameters: mb.accessData.parameters,
      urls: mb.accessData.urls,
      master: master,
      shared: shared,
      markets: mb.accessData.markets,
      rateRules: mb.accessData.rateRules,
      descriptiveInfoLimit: mb.accessData.descriptiveInfoLimit,
      updateList: mb.accessData.updateList,
      updateDateRange: mb.accessData.updateDateRange,
      updateDescriptiveInfo: mb.accessData.updateDescriptiveInfo,
      supplier: supplier,
      groups: mb.accessData.groups,
      isDeleted: !!mb.deletedAt,
      ownerCode: ownerCode,
      isSupplierActive: mb.accessData.supplier?.supplierData?.isActive ?? false,
    };
  }

  resetTable() {
    this.accessesLoadSource = [];
    this.sharedAccesses = [];
    this.queryFormOrganizations.get('codes').setValue([]);
    this.queryFormOrganizations.get('filter_supplier').setValue([]);
    this.queryFormOrganizations.get('filter_owners').setValue('');
    this.queryFormOrganizations.get('filter_group').setValue('');
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  onCreate(): void {
    const activeModal = this.modalService.open(AccessesModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.header = 'Add new Access connection';
    activeModal.componentInstance.isCreating = true;
    activeModal.componentInstance.organizations = this.organizations;
    activeModal.componentInstance.providers = this.providers;
    activeModal.componentInstance.groups = this.groups;
    activeModal.componentInstance.groupContent_context = this.groupContent_context;
    activeModal.componentInstance.showOwner = true;

    const self = this;

    activeModal.result
      .then(async (res) => {
        // on close
        if (typeof res === 'boolean' && res) {
          //do nothing
        } else if (typeof res === 'boolean' && !res) {
          //do nothing
        }
        // OK
        else if (typeof res === 'object') {
          if (res.adviseMessage && res.adviseMessage.length > 0) {
            throw new Error(res.adviseMessage[0].description);
          }
          await self.retrieveFilteredAccesses();
          this.notificationService.success('Access succesfully created.', 'Success');
        }
      })
      .catch((error) => {
        this.notificationService.error(error);
      });
  }

  buildForm() {
    this.queryFormOrganizations = this.fb.group({
      codes: [''],
      filter_supplier: [''],
      filter_group: '',
      filter_name: '',
      filter_accessID: '',
      filter_owners: [''],
      includeArchived: false,
    });
  }

  selectType(field: string) {
    switch (field) {
      case 'isActive':
      case 'deleteAt':
        return 'boolean';
      case 'shared':
        return '';
      default:
        return 'text';
    }
  }

  buildOrganizationsDropdown(groupsToAdd: any) {
    if (this.organizations.length === 0) {
      this.organizations = [];
    }

    groupsToAdd.forEach((group) => {
      let label = group.label + ' (' + group.code + ')';
      label += group.isTeam ? ' > TEAM' : '';
      this.organizations.push({ code: group.code, label: label, isTeam: group.isTeam });
    });
  }
  buildSuppliersDropdown() {
    this.suppliers = [];
    this.supplierSelectors.forEach((sup) => {
      this.suppliers.push({ code: sup.code, label: sup.code });
    });
  }
  runQuery() {
    this.retrieveFilteredAccesses();
  }

  async onLogs(event: any) {
    const access: any = event;
    const activeModal = this.modalService.open(AccessLogsModalComponent, {
      size: 'xl',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Access logs: ' + access.code;
    activeModal.componentInstance.accessId = access.code;
  }
}
