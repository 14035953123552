import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnqueuerFilter, Masters, SchedulerFilter, Task } from '../interfaces/_index-content.interfaces';
import { Inject, Injectable } from '@angular/core';
import { copyObject } from '@tgx/shared/utils';
import { AllowedHeaders } from '@tgx/shared/constants';
import { TaskManagerAddTaskResult, GetTaskByFilterResponse } from '../models/task-manager.models';

@Injectable({
  providedIn: 'root',
})
export class TaskManagerService {
  constructor(
    private http: HttpClient,
    @Inject('urlTaskManager') private urlTaskManager: string,
  ) {}

  private createHeaders(enqueuerFilter: EnqueuerFilter): HttpHeaders {
    let headers = new HttpHeaders();
    if (enqueuerFilter.enableTestAccesses) {
      headers = headers.set(AllowedHeaders.TGXEnableTestAccesses, 'true');
    }
    if (enqueuerFilter.requestSource) {
      headers = headers.set(AllowedHeaders.TGXRequestSource, enqueuerFilter.requestSource);
    }
    return headers;
  }

  private createRequestBody(enqueuerFilter: EnqueuerFilter, allSuppliers: boolean = false): any {
    const requestBody: any = {
      operation: [enqueuerFilter.serviceOperations],
      force: enqueuerFilter.force,
      priority: enqueuerFilter.priority,
      serviceType: enqueuerFilter.serviceType,
      enableTestAccesses: enqueuerFilter.enableTestAccesses,
      deleteIndex: enqueuerFilter.deleteIndex,
    };

    if (allSuppliers || !enqueuerFilter.suppliers || enqueuerFilter.suppliers.length === 0) {
      requestBody.allSuppliers = true;
    } else {
      requestBody.supplierCodes = Array.isArray(enqueuerFilter.suppliers)
        ? enqueuerFilter.suppliers
        : [enqueuerFilter.suppliers];
    }

    return requestBody;
  }

  addTaskByAccess(enqueuerFilter: EnqueuerFilter): Promise<TaskManagerAddTaskResult> {
    const headers = this.createHeaders(enqueuerFilter);
    const requestBody = {
      accessCodes: enqueuerFilter.accesses,
      ...this.createRequestBody(enqueuerFilter),
      obsoleteDays: enqueuerFilter.obsoleteDays,
    };

    return new Promise<TaskManagerAddTaskResult>((resolve, reject) => {
      this.http.post(`${this.urlTaskManager}/Task`, requestBody, { headers }).subscribe({
        next: (res: TaskManagerAddTaskResult) => resolve(res),
        error: (err) => reject(err),
      });
    });
  }

  addTaskBySupplier(enqueuerFilter: EnqueuerFilter): Promise<TaskManagerAddTaskResult> {
    const headers = this.createHeaders(enqueuerFilter);
    const requestBody = this.createRequestBody(enqueuerFilter);

    return new Promise<TaskManagerAddTaskResult>((resolve, reject) => {
      this.http.post(`${this.urlTaskManager}/Task/AddTaskSpecifiedBySupplier`, requestBody, { headers }).subscribe({
        next: (res: TaskManagerAddTaskResult) => resolve(res),
        error: (err) => reject(err),
      });
    });
  }

  addTaskAll(enqueuerFilter: EnqueuerFilter): Promise<TaskManagerAddTaskResult> {
    const headers = this.createHeaders(enqueuerFilter);
    const requestBody = this.createRequestBody(enqueuerFilter, enqueuerFilter.allSuppliers);

    return new Promise<TaskManagerAddTaskResult>((resolve, reject) => {
      this.http.post(`${this.urlTaskManager}/Task/AddTaskSpecifiedBySupplier`, requestBody, { headers }).subscribe({
        next: (res: TaskManagerAddTaskResult) => resolve(res),
        error: (err) => reject(err),
      });
    });
  }

  abortTask(taskID: Number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.delete(`${this.urlTaskManager}/Task/${taskID}`).subscribe({
        next: () => resolve(),
        error: (err) => reject(err),
      });
    });
  }

  getMasterData(): Promise<Masters> {
    return new Promise((resolve) => {
      resolve({
        Statuses: ['Enqueued', 'TryExecute', 'Executing', 'Completed', 'Aborted', 'Failed'],
        ServiceOperations: [
          'HotelList',
          'DescriptiveInfo',
          'GeographicTree',
          'MealPlanList',
          'CategoryList',
          'RoomTypeList',
          'CurrencyList',
          'MarketList',
          'AmenitiesList',
          'MetaData',
          'Configuration',
          'GIATAList',
          'MappingTgx',
        ],
      });
    });
  }

  getListQueue(filter: SchedulerFilter): Promise<Task[]> {
    const request = {
      supplierCodes: filter.filters?.Suppliers,
      operations: filter.filters?.ServiceOperations,
      statuses: filter.filters?.Statuses,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
    };

    return new Promise<Task[]>((resolve, reject) => {
      this.http
        .post<GetTaskByFilterResponse>(`${this.urlTaskManager}/Task/Filter`, request, { observe: 'response' })
        .subscribe({
          next: (response) => {
            if (response.status === 204) {
              resolve([]);
            } else {
              const res = response.body;
              if (res) {
                resolve(
                  res.tasks.map(
                    (t) =>
                      <Task>{
                        stats: t.stats,
                        activationID: t.activationId,
                        enqueuedTime: t.enqueuedTime,
                        recievedTime: t.executedTime,
                        executedTime: t.executedTime,
                        completeTime: t.finishTime,
                        environment: t.environment,
                        force: t.force,
                        taskStatus: t.stats,
                        status: t.status,
                        schedulerStatus: t.integrationStatus,
                        machine: t.machine,
                        user: t.userEnqueued,
                        classification: MapClasification(t.status),
                        kibanaLink: t.kibanaLink,
                        taskID: t.id,
                        accessCode: t.accessData.code,
                        provider: t.accessData.providerCode,
                        supplier: t.accessData.supplierCode,
                        client: 'scheduler',
                        serviceOperation: t.serviceOperation,
                        serviceType: 'hotel',
                        priority: t.priority,
                        supplierGroup: t.accessData.supplierGroup,
                        adviseMessage: t.integrationError.description,
                        adviseMessageCode: t.integrationError.code,
                      },
                  ),
                );
              } else {
                resolve([]);
              }
            }
          },
          error: (err) => reject(err),
        });
    });
  }

  dateMonthsDiff(dateFrom: Date, dateTo: Date): number {
    return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  getDaysInMonth(year: number, month: number) {
    return [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  addMonths(date: Date, months: number): Date {
    const tempDate = new Date(copyObject(date));
    const n = tempDate.getDate();
    tempDate.setDate(1);
    tempDate.setMonth(date.getMonth() + months);
    return new Date(tempDate.setDate(Math.min(n, this.getDaysInMonth(date.getFullYear(), date.getMonth()))));
  }
}

function MapClasification(status: string): string {
  if (status == 'Enqueued') return 'Enqueued';
  if (status == 'TryExecute' || status == 'Executing') return 'Executing';
  return 'Complete';
}
