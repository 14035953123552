import { gql } from 'apollo-angular';

export const getOrganization = gql`
  query getOrganization($where: OrganizationWhereUniqueInput!) {
    admin {
      organization(where: $where) {
        organizationsData {
          label
          code
        }
        adviseMessage {
          code
          description
          level
        }
      }
    }
  }
`;
