<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Modal content -->
  <div class="client-logs-modal">
    <nb-card>
      <nb-card-body>
        <p-table
          #dt1
          [value]="auditorySource"
          [paginator]="true"
          [rows]="5"
          [showCurrentPageReport]="true"
          styleClass="p-datatable-gridlines p-datatable-striped"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
          [globalFilterFields]="['code', 'modifiedBy', 'actionType', 'entityType']"
          [loading]="isLoading"
          [loadingIcon]="loadingIcon"
        >
          <ng-template pTemplate="caption">
            <div class="flex, align-right">
              <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder=""
                  class="search-input"
                />
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let head of tableHeader" [pSortableColumn]="head.field">
                {{ head.label }}
                <p-sortIcon [field]="head.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-auditory>
            <tr>
              <td>{{ auditory.code }}</td>
              <td>{{ auditory.actionType }}</td>
              <td>
                <p-table
                  [value]="auditory.auditDetails"
                  rowGroupMode="rowspan"
                  groupRowsBy="field"
                  [rows]="10"
                  styleClass="p-datatable-gridlines p-datatable-striped"
                  [tableStyle]="{ 'min-width': '50rem' }"
                >
                  <ng-template
                    pTemplate="body"
                    let-audit
                    let-rowIndex="rowIndex"
                    let-rowgroup="rowgroup"
                    let-rowspan="rowspan"
                  >
                    <tr>
                      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                        {{ audit.field }}
                      </td>
                      <td>
                        {{ audit.previous }}
                      </td>
                      <td>
                        {{ audit.current }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </td>
              <td>{{ auditory.createdAt }}</td>
              <td>{{ auditory.modifiedBy }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="10" class="text-center">No auditories found.</td>
            </tr>
          </ng-template>
        </p-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
</div>
