<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>

<nb-card *ngIf="!isLoading">
  <nb-card-header>
    <div class="row">
      <div class="col-3" *ngIf="selectedProviders?.length === 0">
        <span>Providers</span>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt1
      *ngIf="!isLoading"
      [value]="providersLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      styleClass="p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              *ngIf="head.field !== 'Options'"
              [showClearButton]="false"
              [placeholder]="head.placeholder"
              [type]="selectType(head.field)"
              [field]="head.field"
              matchMode="contains"
              (input)="dt1.filter($event.target.value, head.field, 'contains')"
            >
            </p-columnFilter>
            <p-button
              *ngIf="head.field === 'Options'"
              title="Add new Provider"
              class="p-button"
              icon="pi pi-plus"
              (onClick)="onCreate()"
            ></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-providers>
        <tr>
          <td>{{ providers.code }}</td>
          <td>{{ providers.name }}</td>
          <td>
            <p-inputSwitch
              [(ngModel)]="providers.isActiveModel"
              (onChange)="onToggleActivateDeactiveProvider(providers)"
            ></p-inputSwitch>
          </td>
          <td>
            <i class="nb-grid-b-outline icon" title="Show suppliers" (click)="onShowSuppliers(providers)"></i>
            <i class="nb-compose icon mx-2" title="Logs" (click)="onLogs(providers)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
