import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastsService } from '@tgx/shared/toasts';
import { AssetsProfile, Cacheable, ProfileCache } from '@tgx/shared/interfaces';
import { GqlService, allProfiles } from '@tgx/shared/data-access/graphql';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  hasProfilesExtraInformation: ReplaySubject<boolean> = new ReplaySubject(1);
  profiles: Cacheable<ProfileCache[]> = new Cacheable<ProfileCache[]>();

  constructor(
    private gqlService: GqlService,
    private toastService: ToastsService,
  ) {
    this.hasProfilesExtraInformation.next(false);

    this.retrieveProfiles();
  }

  //#region Profiles implementation
  retrieveProfiles() {
    this.profiles.getHandler = this.setProfilesHandler();
    return this.profiles.getData();
  }

  async retrieveProfilesByCodes(codes: string[]) {
    this.profiles.getHandler = this.setProfilesHandler();
    const profiles = await this.profiles.getData();
    return profiles.filter((profile) => codes.includes(profile.code));
  }

  async retrieveProfilesBySlug(slug: string) {
    this.profiles.getHandler = this.setProfilesHandler();
    const profiles = await this.profiles.getData();
    return profiles.find((profile) => profile.slug === slug);
  }

  setProfilesHandler(): () => Promise<ProfileCache[]> {
    return () => {
      return new Promise((resolve) => {
        this.gqlService
          .queryPublicGateway(allProfiles)
          .then((res: any) => {
            if (res.social.allProfilesV2.adviseMessage) {
              this.toastService.addToast(
                'Profiles error',
                'bg-danger',
                res.social.allProfilesV2.adviseMessage[0].description,
              );
              resolve(undefined);
            }
            resolve(this.buildProfiles(res.social.allProfilesV2.edges));
          })
          .catch((err) => {
            this.toastService.addToast('Profiles error', 'bg-danger', err);
          })
          .finally(() => {
            this.hasProfilesExtraInformation.next(true);
          });
      });
    };
  }

  updateProfileCached(code: string, newProfile: ProfileCache) {
    const fieldsToUpdate = Object.keys(newProfile);
    const profile = this.profiles.data.find((prf) => {
      return code === prf.code;
    });
    fieldsToUpdate.forEach((field) => {
      if (field === 'Asset') {
        delete newProfile[field];
      }
      profile[field] = newProfile[field];
    });
  }

  buildProfiles(edges: any[]): ProfileCache[] {
    return edges?.map((edge) => {
      const profileCached = this.profiles.data?.find((profile) => {
        return profile.profileCode === edge.node.code;
      });
      return {
        ...profileCached,
        profileCode: edge.node.code,
        code: edge.node.profileData.owner.code,
        name: edge.node.profileData.name,
        slug: edge.node.profileData.slug,
        crmId: edge.node.profileData.crmId,
        updatedAt: edge.node.updatedAt,
        createdAt: edge.node.createdAt,
        buyerCategory: edge.node.profileData.buyerCategory,
        sellerCategory: edge.node.profileData.sellerCategory,
        buyerIntegrationType: edge.node.profileData.buyerIntegrationType,
        sellerIntegrationType: edge.node.profileData.sellerIntegrationType,
        isBuyer: edge.node.profileData.buyerCategory ? true : false,
        isSeller: edge.node.profileData.sellerCategory ? true : false,
        websiteUrl: edge.node.profileData.websiteUrl,
        status: edge.node.profileData.status,
        groupCode: edge.node.profileData?.group?.code,
        country: edge.node.profileData.country,
        classifications: edge.node.profileData?.classifications,
        logoUrl: this.getLastLogo(edge.node.profileData.assets).url ?? 'assets/img/pages/network/no-photo.svg',
        buyerSubCategory: edge.node.profileData.buyerSubCategory,
        sellerSubCategory: edge.node.profileData.sellerSubCategory,
        isDemandBridge: edge.node.profileData.isDemandBridge,
        isSupplyBridge: edge.node.profileData.isSupplyBridge,
        buyerInsightsCountries: edge.node.profileData.buyerInsightsCountries,
        sellerInsightsCountries: edge.node.profileData.sellerInsightsCountries,
        deletedAt: edge.node.profileData.owner?.deletedAt,
        buyerOnboardingStart: edge.node.profileData?.buyerOnboardingStart,
        buyerOnboardingEnd: edge.node.profileData?.buyerOnboardingEnd,
        sellerOnboardingStart: edge.node.profileData?.sellerOnboardingStart,
        sellerOnboardingEnd: edge.node.profileData?.sellerOnboardingEnd,
        buyerPartnerStatus: edge.node.profileData?.buyerPartnerStatus,
        sellerPartnerStatus: edge.node.profileData?.sellerPartnerStatus,
        buyerTechBridge: edge.node.profileData?.buyerTechBridge,
        sellerTechBridge: edge.node.profileData?.sellerTechBridge,
        mainMarkets: edge.node.profileData?.mainMarkets,
      };
    });
  }

  getLastLogo(assets: AssetsProfile[]): any {
    const logos = assets
      ?.filter((asset) => asset.type === 'LOGO')
      .sort((a: any, b: any) => {
        if (new Date(a.updatedAt) < new Date(b.updatedAt)) {
          return 1;
        }

        if (new Date(a.updatedAt) > new Date(b.updatedAt)) {
          return -1;
        }

        return 0;
      });

    return logos ? logos[0] : {};
  }
  //#endregion
}
