<div class="modal-header">
  <span>{{ header }}</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Modal content -->
  <div class="legacy-password-modal">
    <div>
      {{ password }}
      <span *ngIf="showCopied" class="nb-checkmark-circle icon" style="color: green"></span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-md btn-primary" (click)="onCopyToClipboard()">Copy to clipboard</button>
  <button class="btn btn-md btn-danger" (click)="closeModal()">Close</button>
</div>
