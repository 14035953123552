<div class="xen-score-card">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <h5 class="xen-score-card__title">
      {{ card_title }}
      <i
        *ngIf="tooltip"
        ngbTooltip="{{ tooltip_text }}"
        tooltipClass="xen-tooltip"
        class="fa-solid fa-circle-info xen-score-card__tooltip-icon"
      ></i>
    </h5>
    <i class="{{ icon }} xen-score-card__icon"></i>
  </div>
  <div class="d-flex justify-content-between align-items-end">
    <div>
      <p *ngIf="num_one" class="mb-1 xen-score-card__one {{ num_one_class | lowercase }}">
        {{ num_one_value | number: '1.2-2' }}<span *ngIf="num_one_value_perc">%</span>
      </p>
      <p class="mb-1 xen-score-card__two {{ num_two_class | lowercase }}">
        {{ num_two_value | number: '1.0-0' }}<span *ngIf="num_two_value_perc">%</span
        ><span *ngIf="num_two_add_text" class="ms-1">{{ num_two_text }}</span>
      </p>
      <p class="mb-1 xen-score-card__two {{ num_two_class | lowercase }}" *ngIf="num_two_add">
        {{ num_two_value_add | number: '1.0-0' }}<span *ngIf="num_two_add_value_perc">%</span
        ><span *ngIf="num_two_add_text" class="ms-1">{{ num_two_text }}</span>
      </p>
      <p *ngIf="num_three" class="mb-0 xen-score-card__nok">
        ({{ num_three_value | number: '1.2-2' }}<span *ngIf="num_three_value_perc">%</span
        ><span> {{ num_three_text }}</span
        >)
      </p>
    </div>
    <xen-btn
      *ngIf="button"
      [type]="'primary'"
      [size]="'small'"
      [label]="buttonLabel"
      [id]="'button id'"
      (onClick)="onClickEvent($event)"
    ></xen-btn>
  </div>
</div>
