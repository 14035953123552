<div>
  <div [formGroup]="notificationForm">
    <nb-card>
      <nb-card-header>
        Set a Notification
        <a
          class="fw-lighter text-reset"
          href="https://platform.travelgatex.com/notifications-service/documentation/"
          target="_blank"
          >More info</a
        ></nb-card-header
      >
      <nb-card-body>
        <div class="row">
          <label class="col-12 ps-0 mb-2 form-label">Message <span class="is-required">*</span></label>
          <textarea [formControlName]="'notificationMessage'" required (input)="getNotificationBody()"></textarea>
          <!-- Error Messages -->
          <div
            *ngIf="
              notificationForm.get('notificationMessage')?.touched &&
              notificationForm.get('notificationMessage')?.invalid
            "
            class="text-danger ps-0"
          >
            <div *ngIf="notificationForm.get('notificationMessage')?.errors">
              {{
                notificationForm.get('notificationMessage')?.errors?.['required']
                  ? 'The message is mandatory.'
                  : notificationForm.get('notificationMessage')?.errors?.errorMsg
              }}
            </div>
          </div>
          <div class="col-12 d-flex p-0 mb-2">
            <div class="col-6 pe-2">
              <label class="ps-0 my-2 form-label">Url <span class="is-required">*</span></label>
              <input
                class="form-control"
                id="'url'"
                type="'string'"
                [formControlName]="'notificationUrl'"
                required
                placeholder="Enter a url"
                (input)="getNotificationUrl()"
              />
              <!--Error Messages -->
              <div
                *ngIf="
                  notificationForm.get('notificationUrl')?.touched && notificationForm.get('notificationUrl')?.invalid
                "
                class="text-danger"
              >
                <div *ngIf="notificationForm.get('notificationUrl')?.errors">
                  {{
                    notificationForm.get('notificationUrl')?.errors?.['required']
                      ? 'The URL is mandatory.'
                      : notificationForm.get('notificationUrl')?.errors?.errorMsg
                  }}
                </div>
              </div>
            </div>
            <div class="col-6 ps-2">
              <label class="ps-0 my-2 form-label">Type <span class="is-required">*</span></label>
              <select type="text" class="form-select me-2" [formControlName]="'notificationType'" required>
                <option *ngFor="let type of types" [value]="type.value">{{ type.name }}</option>
              </select>
              <!--Error Messages -->
              <div
                *ngIf="
                  notificationForm.get('notificationType')?.touched && notificationForm.get('notificationType')?.invalid
                "
                class="text-danger"
              >
                <div *ngIf="notificationForm.get('notificationType')?.errors?.['required']">Type is mandatory.</div>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex p-0">
            <div class="col-6 pe-2">
              <label class="ps-0 mb-2 form-label">Scope <span class="is-required">*</span></label>
              <select
                type="text"
                class="form-select me-2"
                [formControlName]="'notificationScope'"
                required
                (change)="getNotificationsScope($event)"
              >
                <option *ngFor="let scope of scopes" [value]="scope.value">{{ scope.name }}</option>
              </select>
              <!-- Error Messages -->
              <div
                *ngIf="
                  (notificationForm.get('notificationScope')?.touched &&
                    notificationForm.get('notificationScope')?.invalid) ||
                  (notificationForm.get('notificationScope').touched &&
                    notificationForm.get('notificationScope').value === null)
                "
                class="text-danger"
              >
                <div *ngIf="notificationForm.get('notificationScope')?.errors?.['required']">Scope is mandatory.</div>
              </div>
            </div>
            <div class="col-6 ps-2">
              <label class="ps-0 mb-2" [ngbTooltip]="tooltipContent"
                >Scope Data <i class="fa-solid fa-circle-info"></i>
              </label>
              <input
                class="form-control"
                id="'scopeData'"
                type="'string'"
                [formControlName]="'notificationScopeData'"
                placeholder="Enter a scope data"
                (input)="getNotificationScopeData()"
              />
              <!-- Error Messages -->
              <div
                *ngIf="errorMessage?.length > 0 || message?.length > 0"
                class="{{ message.length > 0 ? 'mt-1 text-secondary' : 'text-danger' }}"
              >
                <div>
                  <i class="fa-regular fa-circle-exclamation" *ngIf="message?.length > 0"></i>
                  {{ message?.length > 0 ? message : errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex flex-column p-0 mt-3">
            <div>
              <label class="pe-2 mb-0 form-label">Send Email</label>
              <input class="form-check-input" type="checkbox" [formControlName]="'notificationsSendMail'" />
            </div>
            <span class="text-secondary form-email-info p-0">
              This email contains the information from the notification, and redirects to the same link provided in the
              url field.
            </span>
          </div>
          <div class="col-6 mt-4 text-end p-0">
            <button
              class="btn btn-md btn-primary"
              (click)="sendNotification()"
              [disabled]="!notificationForm.valid || this.errorMessage.length > 0 || isDisabled"
            >
              Send Notification
            </button>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
