<tgx-spinner [isLoading]="isLoading"></tgx-spinner>
<nb-card *ngIf="!isLoading">
  <nb-card-header> Enqueuer </nb-card-header>
  <nb-card-body>
    <form [formGroup]="searchForm">
      <div class="row mb-3">
        <div class="col-4">
          <div class="form-group">
            <label class="mb-1" for="operations">Operations</label>
            <p-dropdown
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="operations"
              [options]="operationsSelector"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} operations selected"
              (onChange)="changeType($event)"
            ></p-dropdown>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="mb-1" for="contractConnectionId">Suppliers</label>
            <p-multiSelect
              [filter]="true"
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="suppliers"
              [options]="filteredSuppliersSelector"
              [selectionLimit]="isSingleSupplier ? 1 : undefined"
              (onChange)="getAccessesSchedulderActivated($event)"
              (onFilter)="handleFilter($event)"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} suppliers selected"
            >
              <ng-template pTemplate="footer">
                <app-letter-carousel
                  [selectedSupplier]="searchForm.get('suppliers').value[0]"
                  (letterSelected)="filterSuppliersByLetter($event)"
                ></app-letter-carousel>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="col-4" *ngIf="this.serviceType === 'Hotel'">
          <div class="form-group">
            <label class="mb-1" for="accesses">Accesses</label>
            <p-multiSelect
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="accesses"
              [options]="accessSelector"
              defaultLabel="All"
              optionLabel="label"
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} accesses selected"
            ></p-multiSelect>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="force" id="forceCheckbox" />
            <label
              class="form-check-label"
              for="forceCheckbox"
              ngbTooltip="Force the statics to be launched regardless of whether the accesses are active or not meet default time set up."
            >
              Force
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="priority" id="priorityCheckbox" />
            <label
              class="form-check-label"
              for="priorityCheckbox"
              ngbTooltip="Critical-priority task: it should be executed as soon as possible."
            >
              Priority
            </label>
          </div>
          <div class="form-check" *ngIf="showFullInventory">
            <input class="form-check-input" type="checkbox" formControlName="obsoleteDays" id="obsoleteDaysCheckbox" />
            <label
              class="form-check-label"
              for="obsoleteDaysCheckbox"
              ngbTooltip="Only applicable for DescriptiveInfo! We will request 100% hotels to be updated."
            >
              Update Full Inventory (DescriptiveInfo)
            </label>
          </div>
          <div class="form-check" *ngIf="showEnableTestAccesses">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="enableTestAccesses"
              id="enableTestAccessesCheckbox"
            />
            <label class="form-check-label" for="enableTestAccessesCheckbox"> Enable Test Accesses </label>
          </div>
          <div class="form-check" *ngIf="showDeleteIndex">
            <input class="form-check-input" type="checkbox" formControlName="deleteIndex" id="deleteIndexCheckbox" />
            <label class="form-check-label" for="deleteIndexCheckbox"> Delete Index </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-md btn-primary" [disabled]="searchForm.invalid" (click)="enqueuer()">Enqueuer</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
