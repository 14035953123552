import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';
import { ActionType, AdminUserPermissions } from '../interfaces/_index.interfaces';
import { WebAdminService } from './web-admin.service';

@Injectable()
export class MenuService {
  private menuItems: NbMenuItem[] = [];
  menu = new BehaviorSubject<NbMenuItem[]>(this.menuItems);

  appsMenu: NbMenuItem;
  homeMenu: NbMenuItem;
  backMenu: NbMenuItem;

  webMenu: NbMenuItem;
  webBanner: NbMenuItem;
  webNotifications: NbMenuItem;

  adminMenu: NbMenuItem;
  networkMenu: NbMenuItem;
  billingMenu: NbMenuItem;
  clientsMenu: NbMenuItem;
  contentMenu: NbMenuItem;
  MappingMenu: NbMenuItem;
  entitiesMenu: NbMenuItem;

  constructor(private webAdminService: WebAdminService) {
    this.buildItems();
    this.webAdminService.userPermissions.subscribe((up) => {
      if (up) {
        this.buildMenu(up);
      }
    });
  }

  buildMenu(up: AdminUserPermissions) {
    this.menuItems = [];
    this.menuItems.push({ title: 'SECTIONS', group: true });

    this.menuItems.push(this.homeMenu);
    this.menuItems.push(this.networkMenu);
    this.menuItems.push(this.entitiesMenu);

    if (up.billing) {
      this.menuItems.push(this.billingMenu);
    }

    this.menuItems.push(this.appsMenu);
    this.menuItems.push(this.contentMenu);
    this.menuItems.push(this.MappingMenu);

    /* User permissions for Banner and Notifications */
    if (up.banner) {
      const childs: NbMenuItem[] = [this.webBanner, this.webNotifications];
      this.webMenu.children.push(...childs);
      this.menuItems.push(this.webMenu);
    }

    this.menu.next(this.menuItems);
  }

  buildItems() {
    this.homeMenu = {
      title: 'Dashboard',
      icon: 'home-outline',
      link: '/dashboard',
      home: true,
    };

    this.contentMenu = {
      title: 'Content',
      icon: 'archive-outline',
      children: [
        {
          title: 'Scheduler',
          icon: 'calendar-outline',
          link: '/content/scheduler',
        },
        {
          title: 'Enqueuer',
          icon: 'shopping-cart-outline',
          link: '/content/enqueuer',
        },
      ],
    };

    this.MappingMenu = {
      title: 'Mapping',
      icon: 'map-outline',
      children: [
        {
          title: 'Mapping Tool',
          link: '/mapping/mapping-tool',
        },
      ],
    };

    this.webMenu = {
      title: 'Web',
      icon: 'browser-outline',
      children: [],
    };

    this.webBanner = {
      title: 'Banner message',
      icon: 'message-square-outline',
      link: '/web/web-banner',
    };
    this.webNotifications = {
      title: 'Notifications',
      icon: 'bell-outline',
      link: '/web/notifications',
    };

    this.networkMenu = {
      title: 'Network',
      icon: 'globe-outline',
      children: [
        {
          icon: 'briefcase-outline',
          title: 'Org. Profiles',
          link: '/network/profiles',
        },
        {
          icon: 'book-open-outline',
          title: 'Categories',
          children: [
            {
              title: 'Category',
              link: '/network/categories',
            },
            {
              title: 'Subcategory',
              link: '/network/subcategories',
            },
          ],
        },
        {
          icon: 'folder-outline',
          title: 'Help Utilities',
          children: [
            {
              icon: 'done-all-outline',
              title: 'Signup Status',
              link: '/network/help-tools/signup-status',
            },
            {
              icon: 'done-all-outline',
              title: 'Manage Regions',
              link: '/network/help-tools/manage-regions',
            },
          ],
        },
      ],
    };

    this.entitiesMenu = {
      title: 'Entities',
      icon: 'settings-2-outline',
      children: [
        {
          title: 'Clients',
          link: '/entities/clients',
        },
        {
          title: 'Legacy connections',
          link: '/entities/legacy-connections',
        },
        {
          title: 'Suppliers',
          link: '/entities/suppliers',
        },
        {
          title: 'Accesses',
          link: '/entities/accesses',
        },
        {
          title: 'Providers',
          link: '/entities/providers',
        },
        {
          title: 'Group content | Context',
          link: '/entities/group-content-context',
        },
        {
          title: 'Members',
          link: '/entities/members',
        },
        {
          title: 'Apikeys',
          link: '/entities/apikeys',
        },
        {
          title: 'Organizations',
          link: '/entities/organizations',
        },
        {
          title: 'Data logs',
          link: '/entities/auditory',
        },
      ],
    };

    this.appsMenu = {
      title: 'PowerApps',
      icon: 'flash-outline',
      children: [
        {
          title: 'Logging',
          link: '/apps/logging',
        },
        {
          title: 'Audit',
          link: '/apps/audit',
        },
        {
          title: 'Hotel-X Certificator',
          link: '/apps/hotel-x-certificator',
        },
        {
          title: 'Reporting',
          link: '/apps/reporting',
        },
      ],
    };

    this.billingMenu = {
      title: 'Billing',
      icon: 'credit-card-outline',
      children: [
        {
          title: 'Dashboard',
          icon: 'home-outline',
          link: '/billing/dashboard',
        },
        {
          title: 'Reports',
          icon: 'activity-outline',
          link: '/billing/reports',
        },
        {
          title: 'Partner',
          icon: 'map-outline',
          link: '/billing/partners',
        },
        {
          title: 'BillingAccount',
          icon: 'keypad-outline',
          link: '/billing/billing-accounts',
        },
        {
          title: 'PDF Agreements',
          icon: 'checkmark-square-outline',
          children: [
            {
              title: 'Templates',
              link: '/billing/agreements/templates',
            },
            {
              title: 'Partners',
              link: '/billing/agreements/partners',
            },
          ],
        },
        {
          title: 'Contract',
          icon: 'edit-2-outline',
          children: [
            {
              title: 'Subtypes',
              link: '/billing/contract/subtypes',
            },
            {
              title: 'Contracts',
              link: '/billing/contract/contracts',
            },
            {
              title: 'Plugins',
              link: '/billing/contract/plugins',
            },
            {
              title: 'Discounts',
              link: '/billing/contract/discounts',
            },
          ],
        },
        {
          title: 'Connections',
          icon: 'shuffle-outline',
          children: [
            {
              title: 'Main',
              link: '/billing/connections/main',
            },
            {
              title: 'Inconclusive',
              link: '/billing/connections/inconclusive',
            },
            {
              title: 'Relations',
              link: '/billing/connections/relations',
            },
            {
              title: 'ByContract',
              link: '/billing/connections/contract',
            },
            {
              title: 'Discounts',
              link: '/billing/connections/discounts',
            },
            {
              title: 'Load',
              link: '/billing/connections/load',
            },
          ],
        },
        {
          title: 'Payment Requests',
          icon: 'repeat-outline',
          children: [
            {
              title: 'ByContract',
              link: '/billing/payment-requests/contract',
            },
            {
              title: 'Global',
              link: '/billing/payment-requests/global',
            },
          ],
        },
        {
          title: 'Costs',
          icon: 'pie-chart-outline',
          children: [
            {
              title: 'Default Costs',
              link: '/billing/costs/default-costs',
            },
            {
              title: 'Legacy & CM23 Costs',
              link: '/billing/costs/costs',
            },
            {
              title: 'L2B Costs',
              link: '/billing/costs/l2b-costs',
            },
            {
              title: 'CM22 Costs',
              link: '/billing/costs/new-pricing-costs',
            },
          ],
        },
        {
          title: 'Invoicing Process',
          icon: 'repeat-outline',
          children: [
            {
              title: 'Pre-step',
              link: '/billing/invoicing-process/pre-step',
            },
            {
              title: 'Scheduled',
              link: '/billing/invoicing-process/scheduled',
            },
            {
              title: 'Post-step',
              link: '/billing/invoicing-process/post-step',
            },
          ],
        },

        {
          title: 'Invoices',
          icon: 'file-text-outline',
          children: [
            {
              title: 'List',
              link: '/billing/invoices/list',
            },
            {
              title: 'Create',
              link: '/billing/invoices/create',
            },
            {
              title: 'Credit Note',
              link: '/billing/invoices/credit-note',
            },
            {
              title: 'Legacy & CM23 Conciliate',
              link: '/billing/invoices/legacy-conciliate',
            },
            {
              title: 'CM22 Conciliate',
              link: '/billing/invoices/newpricing-conciliate',
            },
          ],
        },
      ],
    };
  }
}
