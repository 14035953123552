<nb-card>
  <nb-card-header>
    Billing Amounts per contract VS another month
    <button class="btn btn-md btn-primary" (click)="downloadCSV()">DownloadCSV</button>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt
      [value]="requestsSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [loading]="isLoading"
      loadingIcon="pi pi-spin pi-spinner"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              type="text"
              *ngIf="head.field !== 'Options'"
              [field]="head.field"
              [showMenu]="false"
              [showClearButton]="false"
              (input)="filter($event.target.value, head.label)"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-request>
        <tr>
          <td class="my-center-text">{{ request.PartnerId }}</td>
          <td class="my-center-text">{{ request.OrgCode }}</td>
          <td class="my-center-text">{{ request.PartnerName }}</td>
          <td class="my-center-text">{{ request.ContractId }}</td>
          <td class="my-center-text">{{ request.PartnerType }}</td>
          <td class="my-center-text">{{ request.ModelType }}</td>
          <td class="my-center-text">{{ request.SubType }}</td>
          <td class="my-center-text">{{ request.StartDate }}</td>
          <td class="my-center-text">{{ request.TotalAmount }}</td>
          <td class="my-center-text">{{ request.TotalGMV }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="15" class="text-center">No amounts by contract found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
