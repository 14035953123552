import { getProfiles } from './getProfiles';
import { getOrganizations } from './getOrganizations';
import { getCategories } from './getCategories';
import { getWidgetProfiles } from './getWidgetProfiles';
import { getTotalProfiles } from './getTotalProfiles';
import { getProfilesCsvData } from './getProfilesCsvData';
import { getAllCurrentContracts } from './getAllCurrentContracts';

export const GraphqlNetworkQueries = {
  getCategories: getCategories,
  getProfiles: getProfiles,
  getOrganizations: getOrganizations,
  getWidgetProfiles: getWidgetProfiles,
  getTotalProfiles: getTotalProfiles,
  getProfilesCsvData: getProfilesCsvData,
  getAllCurrentContracts: getAllCurrentContracts,
};
