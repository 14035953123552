import { Apollo } from 'apollo-angular';
import { Inject, Injectable } from '@angular/core';
import {
  getClients,
  getOrgs,
  getSuppliers,
  getAccesses,
  getOperationsAndErrors,
  GqlService,
} from '@tgx/shared/data-access/graphql';

/**
 * Enum with the list of available operation types
 */
export enum OperationTypes {
  OTHER = 'OTHER',
  BOOKING = 'BOOKING',
  QUOTE = 'QUOTE',
  SEARCH = 'SEARCH',
  CANCEL = 'CANCEL',
}

/**
 * Enum with the list of available error types
 */
export enum ErrorTypes {
  OK = 'OK',
  PROVIDER = 'PROVIDER',
  COMMUNICATION = 'COMMUNICATION',
  INTEGRATION = 'INTEGRATION',
  TIMEOUT = 'TIMEOUT',
  SCHEDULER = 'SCHEDULER',
}

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  // Dictionaries caches
  private accessesDictionary: Map<string, any>;
  private organizationsDictionary: Map<string, any>;
  private organizationByClientDictionary: Map<string, any>;
  private organizationBySupplierDictionary: Map<string, any>;
  private clientsByOrganizationDictionary: Map<string, any>;
  private suppliersByOrganizationDictionary: Map<string, any>;
  private operationsDictionary: Map<string, any>;
  private errorsDictionary: Map<string, any>;

  constructor(
    @Inject('productionBuild') private productionBuild: boolean,
    private apollo: Apollo,
    private gqlService: GqlService,
  ) {}

  /**
   * Function to get an access' info by its code, this function should be called using await
   * @param accessCode Code of the access to retrieve
   * @returns Information about the access
   */
  async getAccessByCode(accessCode: string) {
    if (!this.accessesDictionary) {
      !this.productionBuild && console.log('Loading accesses dictionary for first time');
      const accessesData = await this.loadAccesses();
      this.buildAccessesData(accessesData);
    }
    const access = this.accessesDictionary.get(accessCode);
    return access;
  }

  /**
   * Function to get accesses info from accesses codes provided, this function should be called using await
   * @param orgCode Code of the organization to retrieve
   * @returns Information about the organization
   */
  async getAccessesByCodes(accessesCodes: string[]) {
    const ret: any[] = [];
    for (const accessCode of accessesCodes) {
      ret.push(await this.getAccessByCode(accessCode));
    }

    return ret;
  }

  /**
   * Function to get the accesses info of a provided connection, this function should be called using await
   * @param orgCode Code of the connection to retrieve
   * @returns Information about the access
   */
  async getAccessesByOrganization(orgCode: string) {
    if (!this.accessesDictionary) {
      !this.productionBuild && console.log('Loading accesses dictionary for first time');
      const accessesData = await this.loadAccesses();
      this.buildAccessesData(accessesData);
    }

    return Array.from(this.accessesDictionary.values()).filter((access) => {
      return access.owner?.code === orgCode;
    });
  }

  /**
   * Function to get the accesses info of a provided connections, this function should be called using await
   * @param orgCode Code of the connection to retrieve
   * @returns Information about the access
   */
  async getAccessesByOrganizations(orgCodes: string[]) {
    const ret: any[] = [];
    for (const orgCode of orgCodes) {
      ret.push(...(await this.getAccessesByOrganization(orgCode)));
    }

    return ret;
  }

  /**
   * Function to get an organization's info by its code, this function should be called using await
   * @param orgCode Code of the organization to retrieve
   * @returns Information about the organization
   */
  async getOrganizationByCode(orgCode: string) {
    if (!this.organizationsDictionary) {
      !this.productionBuild && console.log('Loading organizations dictionary for first time');
      const orgData = await this.loadOrganizations();
      this.buildOrganizationsData(orgData);
    }
    const org = this.organizationsDictionary.get(orgCode);
    return org;
  }

  /**
   * Function to get organizations info of organization codes provided, this function should be called using await
   * @param orgCode Code of the organization to retrieve
   * @returns Information about the organization
   */
  async getOrganizationsByCodes(organizationCodes: string[]) {
    const ret: any[] = [];
    for (const orgCode of organizationCodes) {
      ret.push(await this.getOrganizationByCode(orgCode));
    }

    return ret;
  }

  /**
   * Function to get the organization that owns the given client, this function should be called using await
   * @param clients Code of the client which owner we want to retrieve
   * @returns Org that owns the given client
   */
  async getOrganizationsByClient(clients: string[]) {
    if (!this.organizationsDictionary) {
      !this.productionBuild && console.log('Loading organizations dictionary for first time');
      const orgData = await this.loadOrganizations();
      this.buildOrganizationsData(orgData);
    }
    if (!this.organizationByClientDictionary) {
      !this.productionBuild && console.log('Loading clients dictionary for first time');
      const clientsData = await this.loadClients();
      this.buildClientToOrgData(clientsData);
      this.buildOrgToClientsData(clientsData);
    }

    const ret: any[] = [];
    clients.forEach((client) => {
      ret.push(this.organizationByClientDictionary.get(client));
    });

    return ret;
  }

  async getOrganizationsBySuppliers(suppliers: string[]) {
    if (!this.organizationsDictionary) {
      !this.productionBuild && console.log('Loading organizations dictionary for first time');
      const orgData = await this.loadOrganizations();
      this.buildOrganizationsData(orgData);
    }
    if (!this.organizationBySupplierDictionary) {
      !this.productionBuild && console.log('Loading clients dictionary for first time');
      const supplierData = await this.loadSuppliers();
      this.buildSupplierToOrgData(supplierData);
      this.buildOrgToSupplierData(supplierData);
    }

    const ret: Map<string, any> = new Map();
    suppliers.forEach((supplier) => {
      const supplierData = this.organizationBySupplierDictionary.get(supplier);
      if (!ret.has(supplierData.owner)) {
        ret.set(supplierData.owner, supplierData);
      }
    });

    return [...ret.values()];
  }

  /**
   * Function to get the clients that belong to a given organization, this function should be called using await
   * @param orgCode Code of the org which clients we want to retrieve
   * @returns Clients that belong to the given org
   */
  async getClientsByOrganization(orgCode: string) {
    if (!this.organizationsDictionary) {
      !this.productionBuild && console.log('Loading organizations dictionary for first time');
      const orgData = await this.loadOrganizations();
      this.buildOrganizationsData(orgData);
    }
    if (!this.clientsByOrganizationDictionary) {
      !this.productionBuild && console.log('Loading clients dictionary for first time');
      const clientsData = await this.loadClients();
      this.buildOrgToClientsData(clientsData);
      this.buildClientToOrgData(clientsData);
    }
    const org = this.clientsByOrganizationDictionary.get(orgCode);
    return org;
  }

  /**
   * Function to get the suppliers that belong to a given organization, this function should be called using await
   * @param orgCode Code of the org which suppliers we want to retrieve
   * @returns Suppliers that belong to the given org
   */
  async getSupliersByOrganization(orgCode: string) {
    if (!this.organizationsDictionary) {
      !this.productionBuild && console.log('Loading organizations dictionary for first time');
      const orgData = await this.loadOrganizations();
      this.buildOrganizationsData(orgData);
    }
    if (!this.suppliersByOrganizationDictionary) {
      !this.productionBuild && console.log('Loading suppliers dictionary for first time');
      const suppliersData = await this.loadSuppliers();
      this.buildOrgToSupplierData(suppliersData);
      this.buildSupplierToOrgData(suppliersData);
    }
    const org = this.suppliersByOrganizationDictionary.get(orgCode);
    return org;
  }

  /**
   * Function to get operation info for the given code, this function should be called using await
   * @param operationCode Code of the operation to retrieve
   * @returns Operation info
   */
  async getOperationByCode(operationCode: string) {
    if (!this.operationsDictionary) {
      const operationsAndErrorsData = await this.loadOperationsAndErrors();
      this.buildOperationsAndErrorsData(operationsAndErrorsData);
    }
    const operation = this.operationsDictionary.get(operationCode);
    return operation;
  }

  /**
   * Function to get error info for the given code, this function should be called using await
   * @param errorCode Code of the error to retrieve
   * @returns Error info
   */
  async getErrorByCode(errorCode: string) {
    if (!this.errorsDictionary) {
      const operationsAndErrorsData = await this.loadOperationsAndErrors();
      this.buildOperationsAndErrorsData(operationsAndErrorsData);
    }
    const error = this.errorsDictionary.get(errorCode);
    return error;
  }

  /**
   * Function to get error codes for the given error type, this function should be called using await
   * @param errorType of the errors to retrieve
   * @returns Error code as number
   */
  async getErrorCodesByType(errorType: ErrorTypes): Promise<number[]> {
    if (!this.errorsDictionary) {
      const operationsAndErrorsData = await this.loadOperationsAndErrors();
      this.buildOperationsAndErrorsData(operationsAndErrorsData);
    }

    const errorsByType: any[] = [];
    this.errorsDictionary.forEach((value, key) => {
      if (value?.type === errorType) errorsByType.push(+key);
    });
    return errorsByType;
  }

  /**
   * Function to get the whole map of operations, this function should be called using await
   * @returns Operations dictionary
   */
  async getOperations() {
    if (!this.operationsDictionary) {
      const operationsAndErrorsData = await this.loadOperationsAndErrors();
      this.buildOperationsAndErrorsData(operationsAndErrorsData);
    }
    return this.operationsDictionary;
  }

  /**
   * Function to get the whole map of errors, this function should be called using await
   * @returns Errors dictionary
   */
  async getErrors() {
    if (!this.errorsDictionary) {
      const operationsAndErrorsData = await this.loadOperationsAndErrors();
      this.buildOperationsAndErrorsData(operationsAndErrorsData);
    }
    return this.errorsDictionary;
  }

  /**
   * Function to retrieve the list of operations of a given type, this function should be called using await
   * @param operationType Operation type to retrieve its operations
   * @returns An array containing the list of operations for the given operation type
   */
  async getOperationsByType(operationType: OperationTypes) {
    if (!this.operationsDictionary) {
      const operationsAndErrorsData = await this.loadOperationsAndErrors();
      this.buildOperationsAndErrorsData(operationsAndErrorsData);
    }
    const operationsByType: any[] = [];
    this.operationsDictionary.forEach((value) => {
      if (value.types.includes(operationType)) operationsByType.push(value);
    });
    return operationsByType;
  }

  private loadAccesses(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.apollo
        .use('gateway')
        .query({
          query: getAccesses,
          variables: {
            groups: null,
          },
        })
        .subscribe(
          (res: any) => {
            return resolve(res);
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  private buildAccessesData(data: any) {
    this.accessesDictionary = new Map<string, any>();
    const accessesData = data.data.admin.accesses.edges;
    if (accessesData) {
      accessesData.forEach((accessData: any) => {
        const dataToSave: {
          code: string;
          isActive: boolean;
          name: string;
          isTest: boolean;
          owner: { code: string };
          supplier: string;
        } = {
          code: accessData.node.accessData.code,
          name: accessData.node.accessData.name,
          isActive: accessData.node.accessData.isActive,
          isTest: accessData.node.accessData.isTest,
          owner: accessData.node.accessData.owner,
          supplier: accessData.node.accessData.supplier,
        };

        this.accessesDictionary.set(accessData.node.accessData.code, dataToSave);
      });
    }
  }
  private async loadOrganizations(): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryPublicGateway(getOrgs, {})
        .then((res: any) => {
          if (res?.admin?.allOrganizations?.edges) {
            const orgs = res?.admin?.allOrganizations?.edges;
            resolve(orgs);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private buildOrganizationsData(data: any) {
    this.organizationsDictionary = new Map<string, any>();
    data.forEach((orgData: any) => {
      const { organizationsData } = orgData?.node;
      this.organizationsDictionary.set(organizationsData?.code, organizationsData);
    });
  }

  private async loadClients(): Promise<any[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(getClients, {})
        .then((res: any) => {
          if (res?.admin?.allClients?.edges) {
            const clients = this.getClients(res.admin.allClients.edges);
            resolve(clients);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private getClients(clientEdges: any[]): any[] {
    const clients: any[] = [];
    clientEdges.forEach((clientEdge) => {
      if (!clientEdge?.node?.clientData) {
        return;
      }
      clients.push({
        code: clientEdge.node.clientData.code,
        name: clientEdge.node.clientData.name,
        isActive: clientEdge.node.clientData.isActive,
        owner: clientEdge.node.clientData.owner ? clientEdge.node.clientData.owner.code : null,
        group: clientEdge.node.clientData.group ? clientEdge.node.clientData.group.code : null,
      });
    });

    return clients;
  }

  private async loadSuppliers(): Promise<any[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryPublicGateway(getSuppliers, {})
        .then((res: any) => {
          if (res && res?.admin?.suppliers?.edges) {
            const suppliers = this.getSuppliers(res?.admin?.suppliers?.edges);
            resolve(suppliers);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  private getSuppliers(suppliersEdges: any[]): any[] {
    const suppliers: any[] = [];
    suppliersEdges.forEach((supplierEdge) => {
      if (!supplierEdge.node.supplierData) {
        return;
      }
      suppliers.push({
        code: supplierEdge.node.supplierData.code,
        name: supplierEdge.node.supplierData.name,
        isActive: supplierEdge.node.supplierData.isActive,
        owner: supplierEdge.node.supplierData.owner ? supplierEdge.node.supplierData.owner.code : null,
        group: this.getHotelXGroup(supplierEdge.node.supplierData.groups),
      });
    });

    return suppliers;
  }

  getHotelXGroup(groups: any) {
    let ret: string = null;
    groups?.edges?.forEach((edge) => {
      if (edge?.node?.code.startsWith('HotelX_')) {
        ret = edge.node.code;
      }
    });

    return ret;
  }
  private buildClientToOrgData(data: any) {
    this.organizationByClientDictionary = new Map<string, any>();
    data.forEach((clientData: any) => {
      const clientToOrgData = clientData;
      const orgData = this.organizationsDictionary.get(clientData.owner);
      clientToOrgData.owner = orgData;
      this.organizationByClientDictionary.set(clientData.code, clientToOrgData);
    });
  }

  private buildOrgToClientsData(data: any) {
    this.clientsByOrganizationDictionary = new Map<string, any>();
    data.forEach((clientData: any) => {
      if (clientData.owner) {
        const org = this.clientsByOrganizationDictionary.get(clientData.owner);
        if (!org) {
          this.clientsByOrganizationDictionary.set(clientData.owner, [clientData]);
        } else {
          org.push(clientData);
        }
      }
    });
  }

  private buildSupplierToOrgData(data: any) {
    this.organizationBySupplierDictionary = new Map<string, any>();
    data.forEach((supplierData: any) => {
      const supplierToOrgData = supplierData;
      const orgData = this.organizationsDictionary.get(supplierData.owner);
      supplierToOrgData.owner = orgData;
      this.organizationBySupplierDictionary.set(supplierData.code, supplierToOrgData);
    });
  }

  private buildOrgToSupplierData(data: any) {
    this.suppliersByOrganizationDictionary = new Map<string, any>();
    data.forEach((supplierData: any) => {
      if (supplierData.owner) {
        const org = this.suppliersByOrganizationDictionary.get(supplierData.owner);
        if (!org) {
          this.suppliersByOrganizationDictionary.set(supplierData.owner, [supplierData]);
        } else {
          org.push(supplierData);
        }
      }
    });
  }

  private loadOperationsAndErrors(): Promise<any> {
    const filter = {
      codes: ['hotel'],
    };

    return new Promise((resolve, reject) => {
      this.apollo
        .use('gateway')
        .query({ query: getOperationsAndErrors, variables: filter, fetchPolicy: 'network-only' })
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              resolve(res.data);
            } else if (res && res.adviseMessage) {
              reject(res.adviseMessage);
            }
          },
          (err) => {
            reject(err);
          },
        );
    });
  }

  private buildOperationsAndErrorsData(data: any) {
    this.errorsDictionary = new Map<string, any>();
    this.operationsDictionary = new Map<string, any>();
    const apis = data.admin.apis.edges;

    apis.forEach((api: any) => {
      const errors = api.node.apiData.adviseMessageCatalog;
      errors.forEach((error: any) => {
        this.errorsDictionary.set(error.code, error);
      });

      const operations = api.node.apiData.operations.edges;
      operations.forEach((op: any) => {
        this.operationsDictionary.set(op.node.operationData.code, op.node.operationData);
      });
    });
  }
}
