import { Permission } from '@tgx/shared/interfaces';
import { MembersRole, PartnerType, WebComponent, WebFeature, PermissionsService as Service } from '@tgx/shared/enums';
// Use constants for roles and partner types if they're reused throughout the application
const EDITOR = MembersRole.EDITOR;
const ADMIN = MembersRole.ADMIN;
const BUYER = PartnerType.BUYER;

// Improved visibilityPermissions with type aliases and constants
export const VisibilityPermissions = new Map<WebComponent, Map<WebFeature, Permission>>([
  [
    WebComponent.MY_CONNECTIONS,
    new Map([
      [WebFeature.CREATE_ACCESS, { role: ADMIN, service: Service.ENTITIES }],
      [WebFeature.EDIT_ACCESS, { role: EDITOR, service: Service.ENTITIES }],
      [WebFeature.COPY_ACCESS, { role: ADMIN, service: Service.ENTITIES }],
      [WebFeature.CREATE_CONNECTION, { role: ADMIN, service: Service.ENTITIES }],
      [WebFeature.PLUGINS, { partnerType: BUYER, allowedOrgs: new Set(['hob', 'hotxdptm']) }],
    ]),
  ],
  [WebComponent.API_SETTINGS, new Map([[WebFeature.FULL_COMPONENT, { partnerType: BUYER }]])],
  [WebComponent.SETTINGS, new Map([[WebFeature.CREATE_APIKEY, { role: ADMIN, service: Service.ENTITIES }]])],
  [WebComponent.B2B, new Map([[WebFeature.FULL_COMPONENT, { partnerType: BUYER }]])],
]);
