import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'tgx-clients-legacyPassword-modal',
  templateUrl: './clients-legacyPassword-modal.component.html',
  styleUrls: ['./clients-legacyPassword-modal.component.scss'],
})
export class ClientsLegacyPasswordModalComponent implements OnInit {
  @Input() password: string;
  @Input() header: string;

  public showCopied: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private clipboard: Clipboard,
  ) {
    this.showCopied = false;
  }

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close(true);
  }

  onCopyToClipboard() {
    this.clipboard.copy(this.password);
    this.showCopied = true;
  }
}
