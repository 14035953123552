import { Injectable } from '@angular/core';
import { GqlService } from '@tgx/shared/data-access/graphql';
import { GraphqlGatewayQueries } from './queries/_index-entities.queries';
import { OrganizationSelector, OrganizationResponse, Organization } from './../interfaces/_index-entities.interfaces';
import { GraphqlGatewayMutations } from './mutations/_index-entities.mutations';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationNetworkService {
  onToggleArchived$: Subject<any> = new Subject();

  filteredOrgList: OrganizationSelector[];
  filteredPrfList: OrganizationSelector[];
  orgCodes = new BehaviorSubject<string[]>(null);

  constructor(private gqlService: GqlService) {}

  async updateOrganizationGiata(orgCode: string, configuration: string, isActive: boolean): Promise<boolean> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.updateOrganizationGiata, {
          orgCode: orgCode,
          configuration: configuration,
          isActive: isActive,
        })
        .then((response) => {
          if (response['admin']['updateOrganizationGiata']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async setOrganizationOwner(orgCode: string, ownerCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      const where = { search: orgCode, searchBy: 'CODE' };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.setOrganizationOwner, { where: where, ownerCode: ownerCode })
        .then((response) => {
          if (response['admin']['setOrganizationOwner']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async removeOrganizationOwner(orgCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      const where = { search: orgCode, searchBy: 'CODE' };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.removeOrganizationOwner, { where: where })
        .then((response) => {
          if (response['admin']['removeOrganizationOwner']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async changeOrganizationMemberOwner(orgCode: string, memberCode: string): Promise<boolean> {
    return await new Promise((resolve) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.changeOrganizationMemberOwner, {
          member: memberCode,
          organization: orgCode,
        })
        .then((response) => {
          if (response['admin']['changeOrganizationMemberOwner']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async getOrganizationsSelector(): Promise<OrganizationSelector[]> {
    const orgs: OrganizationSelector[] = [];

    return await new Promise((resolve) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getMinInfoOrganizations, {})
        .then((response) => {
          if (
            response['admin']['allOrganizations']['edges'] &&
            response['admin']['allOrganizations']['edges'].length > 0
          ) {
            response['admin']['allOrganizations']['edges'].forEach((org) => {
              const orgNode = org.node?.organizationsData;
              orgs.push({
                code: orgNode.code,
                label: orgNode.label,
                isTeam: false,
                partnerCode: orgNode.owner?.code,
              });
            });
            resolve(orgs);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrganizations(orgCodes: string[], includeArchived: boolean): Promise<OrganizationResponse> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getOrganizations, { includeArchived: includeArchived, code_in: orgCodes })
        .then((response) => {
          if (response['admin']['allOrganizations']) {
            resolve(response['admin']['allOrganizations']);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getOrganizationGiata(orgCode: string): Promise<OrganizationResponse> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlGatewayQueries.getOrganizationGiata, { code: orgCode })
        .then((response) => {
          if (response['admin']['organization']) {
            resolve(response['admin']['organization']);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async blackListOrganization(where: any): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.blackListOrganization, { where: where })
        .then((response) => {
          if (
            response['admin']['addOrganizationToBlacklist']['adviseMessage'] &&
            response['admin']['addOrganizationToBlacklist']['adviseMessage']?.length > 0
          ) {
            reject(response['admin']['addOrganizationToBlacklist']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['addOrganizationToBlacklist'].code,
              createdAt: response['admin']['addOrganizationToBlacklist'].createdAt,
              updatedAt: response['admin']['addOrganizationToBlacklist'].updatedAt,
              node: {
                organizationData: response['admin']['addOrganizationToBlacklist'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async setTestValueOrganization(where: any, testValue: boolean): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.SetOrganizationTestValue, { where: where, isTest: testValue })
        .then((response) => {
          if (
            response['admin']['setOrganizationTestValue']['adviseMessage'] &&
            response['admin']['setOrganizationTestValue']['adviseMessage']?.length > 0
          ) {
            reject(response['admin']['setOrganizationTestValue']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['setOrganizationTestValue'].code,
              createdAt: response['admin']['setOrganizationTestValue'].createdAt,
              updatedAt: response['admin']['setOrganizationTestValue'].updatedAt,
              node: {
                organizationData: response['admin']['setOrganizationTestValue'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async setIsPublicValueOrganization(where: Record<string, any>, isPublicValue: boolean): Promise<Organization> {
    try {
      const response = await this.gqlService.mutationGateway<{
        admin: {
          SetOrganizationIsPublicValue: {
            adviseMessage?: string[];
            code: string;
            createdAt: Date;
            updatedAt: Date;
            organizationData: any;
          };
        };
      }>(GraphqlGatewayMutations.SetOrganizationIsPublicValue, {
        where,
        isPublic: isPublicValue,
      });

      const adviseMessage = response?.admin?.SetOrganizationIsPublicValue?.adviseMessage;

      if (adviseMessage?.length > 0) {
        throw new Error(adviseMessage[0]);
      }

      const { code, createdAt, updatedAt, organizationData } = response?.admin?.SetOrganizationIsPublicValue || {};

      return {
        code,
        createdAt,
        updatedAt,
        node: { organizationData },
      };
    } catch (error) {
      throw error;
    }
  }

  async unBlackListOrganization(where: any): Promise<Organization> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unBlackListOrganization, { where: where })
        .then((response) => {
          if (
            response['admin']['removeOrganizationFromBlacklist']['adviseMessage'] &&
            response['admin']['removeOrganizationFromBlacklist']['adviseMessage']?.length > 0
          ) {
            reject(response['admin']['removeOrganizationFromBlacklist']['adviseMessage'][0]);
          } else {
            const org: Organization = {
              code: response['admin']['removeOrganizationFromBlacklist'].code,
              createdAt: response['admin']['removeOrganizationFromBlacklist'].createdAt,
              updatedAt: response['admin']['removeOrganizationFromBlacklist'].updatedAt,
              node: {
                organizationData: response['admin']['removeOrganizationFromBlacklist'].organizationData,
              },
            };
            resolve(org);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async archiveOrganization(orgCode: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      const where = { search: orgCode, searchBy: 'CODE' };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.archiveOrganization, { where: where })
        .then((response) => {
          if (response['admin']['archiveOrganization']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async unArchiveOrganization(orgCode: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      const where = { search: orgCode, searchBy: 'CODE' };
      this.gqlService
        .mutationGateway(GraphqlGatewayMutations.unArchiveOrganization, { where: where })
        .then((response) => {
          if (response['admin']['unArchiveOrganization']['code'].length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }
  filteredOrgs(localMapOrg, localMapPrf, widget) {
    const arrayKeyOrgs: any = Array.from(localMapOrg.keys());
    const arrayKeyProfiles: any = Array.from(localMapPrf.values());

    if (widget === 'orgs') {
      this.filteredOrgList = arrayKeyOrgs.filter(function (n) {
        return arrayKeyProfiles.indexOf(n) === -1;
      });
      return this.filteredOrgList;
    }
    if (widget === 'prfs') {
      this.filteredPrfList = arrayKeyProfiles.filter(function (n) {
        return arrayKeyOrgs.indexOf(n) === -1;
      });
      return this.filteredPrfList;
    }
  }

  setOrganizationsCodes(orgCodes) {
    this.orgCodes.next(orgCodes);
  }
}
