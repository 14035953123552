import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InputNotification } from '../interfaces/inputNotification.interface';
import { UserProfile } from '@tgx/shared/interfaces';
import { NotificationsService } from '../../../services/notifications.service';
import { NotificationsType } from '../enums/notificationsType.enum';
import { ScopeType } from '../enums/scopeType.enum';
import { FormValidator } from '@tgx/shared/utils';
import { ToastsService } from '@tgx/shared/toasts';

@Component({
  selector: 'tgx-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  isLoading = false;
  notificationForm: FormGroup;
  isDisabled: boolean = false;
  nonExistentData: string[] = [];
  scopeData: string[] = [];
  orgCodeAvoidSpaces: string;
  errorMessage: string;
  message: string;
  tooltipContent: string;

  types: { name: string; value: NotificationsType }[] = [
    { name: 'Select a type', value: null },
    { name: 'Release', value: NotificationsType.release },
    { name: 'Other', value: NotificationsType.other },
    { name: 'Network', value: NotificationsType.network },
    { name: 'Billing', value: NotificationsType.billing },
    { name: 'Tickets', value: NotificationsType.tickets },
    { name: 'Connections', value: NotificationsType.connections },
    { name: 'Apps', value: NotificationsType.apps },
    { name: 'Users', value: NotificationsType.users },
    { name: 'Inventory', value: NotificationsType.inventory },
  ];

  scopes: { name: string; value: ScopeType }[] = [
    { name: 'Select a scope', value: null },
    { name: 'General', value: ScopeType.general },
    { name: 'Organization', value: ScopeType.organization },
    { name: 'User/s', value: ScopeType.user },
  ];
  constructor(
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    private toast: ToastsService,
  ) {}

  ngOnInit(): void {
    this.tooltipContent =
      "If Scope is 'General', ignore Scope Data.\nIf Scope is 'User', set an email(s).\nIf Sopce is Organization' set an orgCode.";
    this.buildForm();
  }

  buildForm() {
    this.notificationForm = this.fb.group({
      notificationMessage: ['', [Validators.required, FormValidator.textLength('Message', 30)]],
      notificationUrl: ['', [Validators.required, FormValidator.validateUrl('url')]],
      notificationType: [null, Validators.required],
      notificationScope: [null, Validators.required],
      notificationScopeData: { value: null, disabled: false },
      notificationsSendMail: [false],
    });

    this.notificationForm.valueChanges.subscribe(() => {
      if (this.isDisabled) {
        this.isDisabled = false;
      }
    });
  }
  getNotificationBody() {
    this.notificationForm.get('notificationMessage').value;
  }
  getNotificationUrl() {
    this.notificationForm.get('notificationUrl').value;
  }
  getNotificationsScope(event: any) {
    this.message = '';
    this.errorMessage = '';
    if (event.target.value === 'general') {
      this.notificationForm.get('notificationScopeData').disable();
    } else {
      this.checkIfScopeDataHasValue();
      this.notificationForm.get('notificationScopeData').enable();
    }

    const value = event.target.value === 'null' ? null : event.target.value;
    this.notificationForm.get('notificationScope').setValue(value);
  }
  async getNotificationScopeData() {
    this.errorMessage = '';
    this.message = '';
    this.notificationForm.get('notificationScope')?.valueChanges.subscribe((value) => {
      if (value === 'general') {
        this.notificationForm.get('notificationScopeData')?.setValue('');
      }
      this.checkIfScopeDataHasValue();
    });
    this.checkIfScopeDataHasValue();
  }

  checkIfScopeDataHasValue() {
    if (this.notificationForm.get('notificationScope').value !== 'general') {
      if (
        this.notificationForm.get('notificationScopeData').value === '' ||
        this.notificationForm.get('notificationScopeData').value === null
      ) {
        this.errorMessage = 'Scope Data is mandatory.';
      }
    }
    this.checkSpacesInInput();
  }

  checkSpacesInInput() {
    const rawValue: string = this.notificationForm.get('notificationScopeData').value;
    if (this.notificationForm.get('notificationScope').value === 'organization') {
      if (rawValue?.includes(' ')) {
        this.orgCodeAvoidSpaces = rawValue.match(/^\S+/)?.[0] || '';
        this.message =
          'The string contains spaces. Only the first orgcode appearing before the first space will be sent. The orgcode sent will be: ' +
          "'" +
          `${this.orgCodeAvoidSpaces}` +
          "'";
      } else {
        this.orgCodeAvoidSpaces = rawValue;
      }
      if (this.orgCodeAvoidSpaces?.length === 0) {
        this.errorMessage = 'No orgCode provided.';
        return;
      }
    }
    if (rawValue?.includes(' ')) {
      if (this.notificationForm.get('notificationScope').value === 'user') {
        this.message = 'The string contains spaces. Only the valid email(s) will be sent.';
      }
    }
  }

  async formatNotificationInput(): Promise<InputNotification> {
    const InputNotification = {
      body: this.notificationForm.get('notificationMessage').value,
      url: this.notificationForm.get('notificationUrl').value,
      type: this.notificationForm.get('notificationType').value,
      scope: {
        scopeType: this.notificationForm.get('notificationScope').value,
        scopeData: this.notificationForm.get('notificationScope').value !== 'general' ? this.scopeData : null,
      },
      sendEmail: this.notificationForm.get('notificationsSendMail').value,
    };
    return InputNotification;
  }

  async checkValidEmails() {
    this.nonExistentData.splice(0, this.nonExistentData?.length);
    this.message = '';
    const rawValue: string = this.notificationForm.get('notificationScopeData').value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const emails = rawValue.split(' ').filter((email) => emailRegex.test(email));

    if (emails.length === 0) {
      this.errorMessage = 'No valid emails provided.';
      return;
    }

    for (const email of emails) {
      const exists = await this.checkUserExists(email);
      if (!exists) {
        this.nonExistentData.push(email);
      }
    }
    // Update dynamic message after the loop
    if (this.nonExistentData.length > 0) {
      const emailList = this.nonExistentData.join(', ');
      this.errorMessage = `The following email(s) do not exist: ${emailList}`;
    } else {
      this.errorMessage = '';
    }
  }

  async checkValidOrgs() {
    this.nonExistentData.splice(0, this.nonExistentData.length);
    const exists = await this.checkOrgCodeExists(this.orgCodeAvoidSpaces);

    if (!exists) {
      this.nonExistentData.push(this.orgCodeAvoidSpaces);
      this.message = '';
      this.errorMessage = `The following orgCode '${this.nonExistentData}' does not exist`;
    } else {
      this.errorMessage = '';
    }
  }

  async checkUserExists(email: string): Promise<boolean> {
    this.isLoading = true;
    let userExists = false;

    await this.notificationsService.getSocialUser(email).then(async (response: UserProfile) => {
      if (response.code) {
        userExists = true;
        this.scopeData.push(response.userProfileData?.email);
      }
    });
    this.isLoading = false;
    return userExists;
  }

  async checkOrgCodeExists(orgCode: string): Promise<boolean> {
    this.isLoading = true;
    let orgCodeExists = false;

    await this.notificationsService.getOrganization(orgCode).then(async (response: any) => {
      if (response.admin?.organization?.adviseMessage?.length > 0) {
        this.errorMessage = response.admin.organization.adviseMessage[0].description;
      } else if (response.admin?.organization?.organizationsData !== null) {
        orgCodeExists = true;
        this.scopeData.push(response.admin?.organization?.organizationsData.code);
      }
    });
    this.isLoading = false;
    return orgCodeExists;
  }

  async sendNotification() {
    this.scopeData = [];

    if (this.notificationForm.valid) {
      if (this.notificationForm.get('notificationScope').value === 'user') {
        await this.checkValidEmails();
      }
      if (this.notificationForm.get('notificationScope').value === 'organization') {
        await this.checkValidOrgs();
      }
      if (this.nonExistentData.length === 0) {
        const input = await this.formatNotificationInput();
        await this.notificationsService.sendNotification(input).then((res) => {
          if (res === 'OK') {
            this.toast.addToast('Success', 'bg-success', 'Notification sent successfully', 5);
            this.isDisabled = true;
          } else {
            this.toast.addToast('Error', 'bg-danger', 'Error sending notification', 5);
          }
        });
      } else {
        console.log('Something went wrong, there are values that dont exist', this.nonExistentData);
        return;
      }
    } else {
      console.log('Form is invalid');
    }
  }
}
