<div class="xen-empty-state">
  <div *ngIf="imgPath" class="xen-empty-state__image"><img [src]="imgPath" [alt]="imgAlt" /></div>
  <ng-container *ngIf="icon && iconSnippet">
    <div class="xen-empty-state__icon"><i class="{{ iconSnippet }}"></i></div>
  </ng-container>
  <markdown class="xen-empty-state__title" [data]="title"></markdown>
  <div *ngIf="subtitle" class="xen-empty-state__subtitle" [innerHtml]="subtitle">{{ subtitle }}</div>
  <div *ngIf="buttonLabel" class="xen-empty-state__cta">
    <xen-btn [label]="buttonLabel" (onClick)="onClickEvent($event)"></xen-btn>
  </div>
  <div *ngIf="footer" class="xen-empty-state__footer" [innerHtml]="footer"></div>
</div>
