import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { CostsL2BService } from './../../../../../services/_index-billing.services';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { clone } from '../../../../../../../@core/utils/utils';
import {
  BookingL2BCost,
  BookingL2BCostCreateInput,
  BookingL2BCostUpdateInput,
  ObjectWhereUniqueInputById,
  PaymentType,
} from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-l2b-cost-modal',
  styleUrls: ['./l2b-cost-modal.component.scss'],
  templateUrl: 'l2b-cost-modal.component.html',
})
export class BillingL2BCostModalComponent implements OnInit, OnDestroy {
  @Input() cost: any = null;
  @Input() header: string;
  @Input() contractId: number;
  @Input() isEditor: boolean;

  isCreating: boolean;
  costAux: BookingL2BCost;

  public costForm: UntypedFormGroup;

  subscriptions: Subscription[] = [];

  paymentTypes: Options[] = [{ code: null, label: 'Select One' }];

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private costsL2BService: CostsL2BService,
  ) {}

  async ngOnInit() {
    this.buildSelects();

    this.costForm = this.fb.group({
      id: [{ value: this.cost ? this.cost.id : '', disabled: true }],
      minl2b: [this.cost ? this.cost.minl2b : '', Validators.required],
      maxl2b: [this.cost ? this.cost.maxl2b : ''],
      minBookings: [this.cost ? this.cost.minBook : '', Validators.required],
      maxBookings: [this.cost ? this.cost.maxBook : ''],
      amount: [this.cost ? this.cost.amount : '', Validators.required],
      paymentType: ['', Validators.required],
    });

    this.isCreating = true;

    if (this.cost) {
      this.isCreating = false;
    }

    if (!this.isCreating) {
      if (!this.isEditor) {
        this.costForm.get('minl2b').disable();
        this.costForm.get('maxl2b').disable();
        this.costForm.get('minBookings').disable();
        this.costForm.get('maxBookings').disable();
        this.costForm.get('amount').disable();
        this.costForm.get('paymentType').disable();
      }

      this.costForm
        .get('paymentType')
        .setValue(this.paymentTypes[this.paymentTypes.findIndex((x) => x.code === this.cost.paymentType)]);
    } else {
      this.costForm.get('paymentType').setValue(this.paymentTypes[0]);
    }
  }

  buildSelects() {
    Object.keys(PaymentType).forEach((value) => {
      if (value === PaymentType.SUPPLEMENT) {
        this.paymentTypes.push({ code: value, label: value });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  closeModal() {
    this.cost = clone(this.costAux);
    this.activeModal.close(true);
  }

  validType(field: string) {
    if (!this.costForm.get(field).value.code) {
      this.costForm.get(field).setErrors({ 'Required': true });
    } else {
      this.costForm.get(field).setErrors(null);
    }
  }

  onSave() {
    if (this.isCreating) {
      //Mutation create

      const createData: BookingL2BCostCreateInput = {
        minl2b: this.costForm.get('minl2b').value,
        minbookings: this.costForm.get('minBookings').value,
        amount: this.costForm.get('amount').value.toString(),
        paymentType: this.costForm.get('paymentType').value.code,
        Contract: {
          connect: { id: this.contractId },
        },
      };

      if (this.costForm.get('maxl2b').value) {
        createData.maxl2b = this.costForm.get('maxl2b').value;
      }

      if (this.costForm.get('maxBookings').value) {
        createData.maxbookings = this.costForm.get('maxBookings').value;
      }

      this.costsL2BService
        .createL2BCost(createData)
        .then((rs) => {
          this.notificationService.success('L2B Cost succesfully created', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    } else {
      ///Mutation update

      const updateData: BookingL2BCostUpdateInput = {
        minl2b: { set: this.costForm.get('minl2b').value },
        minbookings: { set: this.costForm.get('minBookings').value },
        amount: { set: this.costForm.get('amount').value.toString() },
        paymentType: { set: this.costForm.get('paymentType').value.code },
      };

      if (this.costForm.get('maxl2b').value) {
        updateData.maxl2b = { set: this.costForm.get('maxl2b').value };
      }

      if (this.costForm.get('maxBookings').value) {
        updateData.maxbookings = { set: this.costForm.get('maxBookings').value };
      }

      const where: ObjectWhereUniqueInputById = { id: this.cost.id };

      this.costsL2BService
        .updateL2BCost(updateData, where)
        .then((rs) => {
          this.notificationService.success('L2B Cost succesfully updated', 'Success');
          this.activeModal.close(rs);
        })
        .catch((err) => {
          this.activeModal.close(false);
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }
}
