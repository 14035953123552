import { gql } from 'apollo-angular';

export const setOrganizationIsPublicValue = gql`
  mutation setOrganizationIsPublicValue($isPublic: Boolean!, $where: OrganizationWhereUniqueInput!) {
    admin {
      setIsPublicOrganization(isPublic: $isPublic, where: $where) {
        code
        createdAt
        updatedAt
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
