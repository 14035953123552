import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleType } from '../../../../../../../../..//web-admin/src/app/@core/interfaces/user-permissions';
import { NotificationService } from '../../../../../../../../..//web-admin/src/app/@core/services/notification.service';
import { WebAdminService } from '../../../../../../../../..//web-admin/src/app/@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from '../../../../interfaces/_index-entities.interfaces';
import { ProvidersService } from '../../../../services/providers.service';
import { NavigationExtras, Router } from '@angular/router';
import { ProviderNode } from '../../../../interfaces/models/providers.interface';
import { ProviderModalComponent } from '../provider-modal/provider-modal.component';
import { ProviderLogsModalComponent } from '../providers-logs-modal/providers-logs-modal.component';

@Component({
  selector: 'tgx-admin-providers-table',
  templateUrl: './providers-table.component.html',
  styleUrls: ['./providers-table.component.scss'],
})
export class ProvidersTableComponent implements OnInit, OnDestroy {
  selectedProviders: any[];
  selectedProvidersAux: any[];
  clientName: string;

  isLoading: boolean;

  providers: ProviderNode[];
  providersLoadSource: any[];

  $subs: Subscription[] = [];

  orgSelected: OrganizationSelector;

  header = [
    { label: 'Code', field: 'code', placeholder: 'Code' },
    { label: 'Name', field: 'name', placeholder: 'Name' },
    { label: 'IsActive', field: 'isActive', placeholder: 'Active' },
    { label: 'Options', field: 'Options', placeholder: 'Options' },
  ];

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private notificationService: NotificationService,
    private providersService: ProvidersService,
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      clientName: string;
    };

    this.clientName = state && state.clientName ? state.clientName : '';
  }

  onCustom($event): void {
    switch ($event.action) {
      case 'show-suppliers':
        this.onShowSuppliers($event);
        break;
    }
  }

  async onShowSuppliers(provider: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        providerCode: provider.code,
      },
    };
    this.router.navigate(['/entities/suppliers'], navigationExtras);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.buildOptions();
    this.retrieveAllProviders();

    //toggle activate/deactivate provider.
    this.$subs.push(
      this.providersService.onToggleTable$.subscribe((provider) => {
        if (provider) {
          const input = { search: provider.code, searchBy: 'CODE' };
          if (provider.isActiveModel) {
            const res = this.providersService.activateProvider(input);
            if (res != null) {
              this.notificationService.info('The provider was activated sucessfully');
            } else {
              this.notificationService.warning('There was a problem activating the provider');
            }
          } else {
            const res = this.providersService.deactivateProvider(input);
            if (res != null) {
              this.notificationService.info('The provider was deactivated sucessfully');
            } else {
              this.notificationService.warning('There was a problem deactivating the provider');
            }
          }
        }
      }),
    );
  }

  async retrieveAllProviders() {
    const allProviders = await this.providersService.getAllProviders();
    this.selectedProviders = [];
    this.selectedProvidersAux = [];
    if (allProviders?.length > 0) {
      this.providers = allProviders;
      this.buildProviders();
    } else {
      this.isLoading = false;
    }
  }

  selectType(field: string) {
    switch (field) {
      case 'isActive':
        return 'boolean';
      default:
        return 'text';
    }
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.ENTITIES);
  }

  buildProviders() {
    this.providersLoadSource = [];

    this.providers.forEach((mb) => {
      this.providersLoadSource.push({
        code: mb.providerData.code,
        name: mb.providerData.name,
        isActive: mb.providerData.isActive,
        isActiveModel: mb.providerData.isActive,
      });
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }
  onToggleActivateDeactiveProvider(provider: any) {
    this.providersService.onToggleTable$.next(provider);
  }

  onCreate(): void {
    const activeModal = this.modalService.open(ProviderModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.header = 'Add new Provider';
    activeModal.componentInstance.isCreating = true;

    const self = this;

    activeModal.result
      .then(async (res) => {
        if (typeof res === 'object') {
          if (res.adviseMessage && res.adviseMessage.length > 0) {
            throw new Error(res.adviseMessage[0].description);
          }

          this.notificationService.success('Provider was succesfully created.', 'Success');
          await self.retrieveAllProviders();
        }
      })
      .catch((error) => {
        this.notificationService.error(error);
      });
  }

  async onLogs(provider: any) {
    const activeModal = this.modalService.open(ProviderLogsModalComponent, {
      size: 'xl',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Provider logs: ' + provider.code;
    activeModal.componentInstance.providerCode = provider.code;
  }
}
