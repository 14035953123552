import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CacheService } from './cache.service';
import { PlatformInfoService } from './platform-info.service';
import { GtmDataLayerService } from './gtm-data-layer.service';
import { PlatformBrowserService } from './platform-browser.service';
import { GqlService, entitiesQuery } from '@tgx/shared/data-access/graphql';
import { ProfileCache, SelectorOrganization } from '@tgx/shared/interfaces';

@Injectable()
export class PlatformSelectorOrganizationService {
  allOrganizations = new BehaviorSubject<SelectorOrganization[]>([]);
  firstInitOrg = true;

  oldOrg: SelectorOrganization;
  orgSelected: SelectorOrganization;

  slugOrgUrl: any;
  typeOrgUrl: any;
  urlGeneric: any;

  // Routes to which :org/:type must be added
  routesInit = [
    'network/partners',
    'connections/content',
    'connections/myconnections',
    'connections/settings',
    'connections/mapping',
    'dashboard',
    'insights/hotel-portfolio',
    'monitoring/stats',
    'monitoring/logging',
    'monitoring/alerts',
    'onboarding',
    'settings/company-profile',
    'smart-traffic/speed',
    'smart-traffic/traffic-optimizer',
    'solutions/b2b',
    'solutions/distribution',
    'insights/hotel-portfolio',
    'insights/errors-overview',
  ];

  constructor(
    private router: Router,
    private location: Location,
    private gqlService: GqlService,
    private cacheService: CacheService,
    private gtmDataLayerService: GtmDataLayerService,
    private platformInfoService: PlatformInfoService,
    private platformBrowserService: PlatformBrowserService,
  ) {}

  // Method to initialize the service
  initialize() {
    this.router.events.subscribe((route: any) => {
      if (route instanceof NavigationEnd) {
        this.parseUrlParameters(route.urlAfterRedirects);
        this.initOrgSelector();
      }
    });

    this.platformInfoService.userAuthenticated$.subscribe((x) => {
      if (x) {
        this.getOrganizationsBasic().then((orgs: SelectorOrganization[]) => {
          this.allOrganizations.next(orgs);
          this.initOrgSelector();
        });
      }
    });
  }

  private parseUrlParameters(url: string) {
    this.slugOrgUrl = null;
    this.typeOrgUrl = null;
    this.urlGeneric = null;

    const loadParameters = this.routesInit.some((r) => url.startsWith('/' + r));

    if (!loadParameters) {
      const splitRoute = url.split('/');
      if (splitRoute.length > 3) {
        this.slugOrgUrl = splitRoute[1];
        this.typeOrgUrl = splitRoute[2];
        this.urlGeneric = splitRoute.splice(3).join('/');
      }
    } else {
      this.urlGeneric = url.replace('/', '');
    }
  }

  initOrgSelector() {
    const orgs = this.allOrganizations.value;
    if (orgs?.length > 0) {
      const orgCodeLocalStorage = this.platformBrowserService.GetItemFromLocalStorage('organizationCode');
      let orgUrl: SelectorOrganization;
      if (this.slugOrgUrl) {
        orgUrl = orgs.find((x) => x.slug === this.slugOrgUrl.toLocaleLowerCase());
      }

      this.platformInfoService.organizationSelected.subscribe((response) => {
        this.orgSelected = response;
      });

      if (!this.orgSelected || orgUrl?.code !== this.orgSelected.code) {
        if (orgUrl) {
          this.setOrganization(orgUrl.code);
        } else if (!!orgCodeLocalStorage && orgs.findIndex((x) => x.code === orgCodeLocalStorage) !== -1) {
          this.setOrganization(orgCodeLocalStorage);
        } else {
          const tgxCompanyIndex = orgs.findIndex((org) => org.code === 'tgx');
          if (tgxCompanyIndex !== -1) {
            this.setOrganization(orgs[tgxCompanyIndex].code);
          } else {
            // select first
            this.setOrganization(orgs[0].code);
          }
        }
      }
    }
  }

  async setOrganization(orgCode: string) {
    const org = await this.getOrganization(orgCode);
    if (!org) return;

    const profile = (await this.cacheService.retrieveProfilesByCodes([org.code]))[0];

    org.typeSelected = !!profile.buyerCategory ? 'buyer' : 'seller';

    const organizationTypeSelectedLocalStorage =
      this.platformBrowserService.GetItemFromLocalStorage('organizationType');

    if (
      this.typeOrgUrl &&
      ((!profile.buyerCategory && !profile.sellerCategory) ||
        (this.typeOrgUrl.toLocaleLowerCase() === 'buyer' && !!profile.buyerCategory) ||
        (this.typeOrgUrl.toLocaleLowerCase() === 'seller' && !!profile.sellerCategory))
    ) {
      org.typeSelected = this.typeOrgUrl.toLocaleLowerCase() === 'seller' ? 'seller' : 'buyer';
    } else if (
      organizationTypeSelectedLocalStorage &&
      ((!profile.buyerCategory && !profile.sellerCategory) ||
        (organizationTypeSelectedLocalStorage === 'buyer' && !!profile.buyerCategory) ||
        (organizationTypeSelectedLocalStorage === 'seller' && !!profile.sellerCategory))
    ) {
      org.typeSelected = organizationTypeSelectedLocalStorage === 'seller' ? 'seller' : 'buyer';
    }

    this.generateUrl(org);

    // localStorage.setItem('organization', JSON.stringify(org));
    localStorage.setItem('organizationCode', org.code);
    localStorage.setItem('organizationType', org.typeSelected);

    this.platformInfoService.organizationSelected.subscribe((response) => {
      this.oldOrg = response;
    });

    // comprobacion para que no se hagan dobles cargas en los servicios
    if (!this.oldOrg || JSON.stringify(this.oldOrg) !== JSON.stringify(org)) {
      this.platformInfoService.organizationSelected.next(org);
      if (this.oldOrg) this.gtmDataLayerService.setOrganization(org);
    }
  }

  // The "browsingType" parameter is used to determine if the
  // function is called from the buyer/seller type selector
  async generateUrl(org: SelectorOrganization, browsingType = false) {
    if (browsingType) {
      this.parseUrlParameters(window.location.pathname);
    }

    if (this.routesInit.find((route) => route === this.urlGeneric)) {
      if (this.slugOrgUrl !== org.slug || this.typeOrgUrl.toLocaleLowerCase() !== org.typeSelected) {
        this.location.go(`/${org.slug}/${org.typeSelected}/${this.urlGeneric}`);
      }
    }
  }

  getOrganizationsBasic(): Promise<any> {
    return new Promise(async (resolve) => {
      const [organizationsResponse, profilesResponse]: any = await Promise.all([
        this.gqlService.queryGateway(entitiesQuery.organizations.getOrganizationsSimple()),
        this.cacheService.retrieveProfiles(),
      ]);

      const organizations = organizationsResponse.admin.allOrganizations.edges
        .map((edge: any) => {
          const profile = profilesResponse.find((p) => p.code === edge.node.code);
          return this.buildOrg(edge.node.organizationsData, profile);
        })
        .filter(Boolean);

      return resolve(
        organizations.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          } else if (a.label > b.label) {
            return 1;
          } else {
            return 0;
          }
        }),
      );
    });
  }

  getOrganization(code: string): Promise<SelectorOrganization> {
    return new Promise(async (resolve) => {
      const profile: ProfileCache = (await this.cacheService.retrieveProfilesByCodes([code]))[0];

      let org: SelectorOrganization;
      // if (organization.admin.organizations?.edges && organization.admin.organizations?.edges?.length) {
      org = this.buildOrg2(profile);
      // }

      return resolve(org);
    });
  }

  buildOrg(org: any, profile: any): SelectorOrganization {
    return {
      id: org.id || org.code,
      code: org.code,
      info: org.code,
      label: profile?.name || org.label || org.code,
      isBuyer: org.isBuyer || false,
      isSeller: org.isSeller || false,
      slug: profile?.slug,
      crmID: profile?.crmId,
      country: org?.country,
      blacklisted: org?.blacklisted,
    } as SelectorOrganization;
  }

  buildOrg2(profile: ProfileCache): SelectorOrganization {
    // If the profileCache does not have the "isBuyer/isSeller"
    // info. this info is retrieved from this.allOrganizations
    const org = this.allOrganizations.value.find((x) => x.code === profile?.code);

    return {
      code: profile.code,
      label: profile?.name,
      isBuyer: profile?.isBuyer ?? org.isBuyer ?? false,
      isSeller: profile?.isSeller ?? org.isSeller ?? false,
      slug: profile?.slug,
      crmID: profile?.crmId,
      country: profile?.country,
      blacklisted: org?.blacklisted,
    } as SelectorOrganization;
  }
}
