import { gql } from 'apollo-angular';

export const getContractDiscounts = gql`
  query GetContractDiscounts($where: ContractDiscountWhereInput!) {
    contractDiscounts(where: $where) {
      id
      startDate
      endDate
      amount
      concept
      account
      createdAt
      updatedAt
    }
  }
`;
