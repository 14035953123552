<div [formGroup]="form" [ngClass]="classes">
  <input
    class="form-check-input"
    [ngClass]="inputClasses"
    type="checkbox"
    [value]="value"
    id="{{ id ?? formControlName }}"
    [formControlName]="formControlName"
    [checked]="checked"
    [disabled]="disabled"
  />
  <label class="form-check-label" [for]="id ?? formControlName">
    <span *ngIf="isHtmlLabel; else plainLabel" [innerHtml]="label"></span>
    <ng-template #plainLabel>{{ label }}</ng-template>
    <span class="xen-checkbox__tooltip-icon" *ngIf="tooltip" ngbTooltip="{{ tooltip_text }}" tooltipClass="xen-tooltip">
      <i class="fas fa-info-square ms-1"></i>
    </span>
  </label>
  <div class="xen-checkbox__feedback" [ngClass]="feedbackClasses" *ngIf="validation">{{ feedback }}</div>
</div>
