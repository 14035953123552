import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { ContractTypeService } from './../../../../../services/_index-billing.services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { ContractTypeCreateInput } from '../../../../../interfaces/_index.billing.interfaces';

@Component({
  selector: 'tgx-admin-billing-subtype-modal',
  styleUrls: ['./subtype-modal.component.scss'],
  templateUrl: 'subtype-modal.component.html',
})
export class BillingContractSubTypeModalComponent implements OnInit {
  @Input() contracType: any = null;
  @Input() header: string;

  public subTypeForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private readonly contractTypeService: ContractTypeService,
  ) {}

  async ngOnInit() {
    this.subTypeForm = this.fb.group({
      name: [this.contracType ? this.contracType.name : '', [Validators.required, Validators.minLength(3)]],
    });
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSave() {
    const createData: ContractTypeCreateInput = {
      name: this.subTypeForm.get('name').value,
    };

    this.contractTypeService
      .createContractType(createData)
      .then((rs) => {
        this.notificationService.success('Contract type succesfully created', 'Success');
        this.activeModal.close(rs);
      })
      .catch((err) => {
        this.activeModal.close(false);
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  checkSubTypeCode(event: any) {
    const newValue = event.target.value.replaceAll(' ', '_').toUpperCase().trim();
    this.subTypeForm.get('name').setValue(newValue);
  }
}
