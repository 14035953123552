import { Table } from 'primeng/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Contract, Partner, Invoice, InvoiceUpdateInput } from '../../../../../interfaces/_index.billing.interfaces';
import { PartnerService, ContractService, InvoiceService } from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';
import { OrganizationSelector } from './../../../../../../entities/interfaces/_index-entities.interfaces';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'tgx-admin-billing-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
})
export class BillingInvoicesTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) dataTable!: Table;
  $subs: Subscription[] = [];

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Amount', field: 'amount' },
    { label: 'Currency', field: 'currency' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'StartDate', field: 'startDate' },
    { label: 'EndDate', field: 'endDate' },
    { label: 'OdooCode', field: 'odooCode' },
    { label: 'Options', field: 'Options' },
  ];

  invoicesLoadSource: any[];

  contracts: Contract[];

  invoices: Invoice[];

  isLoading: boolean;

  orgSelected: OrganizationSelector;
  partner: Partner;

  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  isEditor = false;

  selectedInvoice: Invoice;

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.buildOptions();

    this.$subs.push(
      this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
        if (orgSelected?.code) {
          this.orgSelected = orgSelected;
          await this.partnerService.getPartner(orgSelected.code).then((partner) => {
            if (partner) {
              this.partner = partner;
              this.getContracts(this.partner.orgCode);
            } else {
              this.partner = null;
              this.isLoading = false;
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  getContracts(orgCode: string) {
    if (orgCode) {
      this.contractService
        .getContracts(orgCode)
        .then((cnts) => {
          this.contracts = cnts;
          if (this.contracts?.length > 0) {
            this.contractsSelector = [];
            this.buildSelector();
            this.selectedContract = this.contractsSelector[0];
            this.getInvoices();
          } else {
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    }
  }

  buildSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  async getInvoices() {
    this.isLoading = true;
    this.invoicesLoadSource = [];
    this.selectedInvoice = null;

    if (this.dataTable) {
      this.dataTable.clearFilterValues();
    }

    if (this.selectedContract?.code) {
      await this.invoiceService
        .getInvoices(Number(this.selectedContract?.code))
        .then((inv) => {
          if (inv?.length > 0) {
            inv.forEach((i) => {
              this.invoicesLoadSource.push({
                'id': i.id,
                'amount': i.amount,
                'currency': i.currency,
                'createdAt': this.datePipe.transform(i.createdAt, 'dd-MM-yyyy'),
                'startDate': this.datePipe.transform(i.startDate, 'dd-MM-yyyy'),
                'endDate': this.datePipe.transform(i.endDate, 'dd-MM-yyyy'),
                'odooCode': i.odooCode,
              });
            });
            this.isLoading = false;
            this.invoicesLoadSource = [...this.invoicesLoadSource];
            this.invoicesLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
          } else {
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
  }

  async onEdit(event): Promise<void> {
    const updateData: InvoiceUpdateInput = { amount: { set: event.amount } };

    if (event?.odooCode && event.odooCode > 0) {
      updateData.odooCode = { set: event.odooCode };
    }

    this.invoiceService
      .updateInvoice(updateData, { id: event.id })
      .then((_inv) => {
        this.notificationService.success(
          'Invoice succesfully updated, remember match with sum of its breakdowns.',
          'Success',
        );
        this.getInvoices();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  onDelete(event): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to delete invoice ' +
      event.id.toString() +
      '? Also all its breakdowns will be deleted!';
    modalRef.componentInstance.title = 'Delete Invoice';
    modalRef.componentInstance.buttonOkTitle = 'Delete';

    const self = this;

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          self.invoiceService
            .deleteInvoice({ id: event.id })
            .then((rs) => {
              this.notificationService.success('Invoice succesfully deleted', 'Success');
              modalRef.close(rs);
              self.getInvoices();
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      });
  }

  getInvoiceAmount(): string {
    if (this.selectedInvoice) {
      const total = Number(this.selectedInvoice.amount);
      return parseFloat(total.toFixed(2)).toLocaleString('en');
    }
    return '';
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
