import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationService } from './../../../../../../@core/services/_index-core.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../@core/shared/modal-delete/modal-delete.component';
import { OrganizationModalComponent } from './../organitzations-modal/organization-modal.component';
import { OrganizationNetworkService } from '../../../../services/organization-network.service';
import { Organization } from '@tgx/web/feature-connections';

@Component({
  selector: 'tgx-organizations-table',
  templateUrl: './organizations-table.component.html',
  styleUrls: ['./organizations-table.component.scss'],
})
export class OrganizationsTableComponent implements OnInit, OnDestroy {
  $subs: Subscription[] = [];
  isLoading: boolean;
  public queryForm: UntypedFormGroup;
  allOrgs = [];
  totalOrgs = 0;

  constructor(
    private notificationService: NotificationService,
    private organizationNetworkService: OrganizationNetworkService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.initTable();

    this.$subs.push(
      this.organizationNetworkService.onToggleArchived$.subscribe((org) => {
        this.onToggleArchive(org);
      }),
    );
  }

  async initTable() {
    this.totalOrgs = 0;
    this.isLoading = true;
    this.allOrgs = [];

    this.getCurrentOrgs().then(() => {
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildForm() {
    this.queryForm = this.fb.group({
      includeArchived: false,
    });
  }

  async getCurrentOrgs(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const includeArchived = this.queryForm.get('includeArchived')?.value ?? false;

      this.organizationNetworkService
        .getOrganizations(null, includeArchived)
        .then((rs) => {
          if (rs?.edges?.length === 0) {
            resolve();
          }

          this.allOrgs = rs.edges.map((org) => {
            const orgData = org.node.organizationsData;

            const { label, code, isTest } = orgData;
            const organization = `${label} (${code})`;
            const isArchived = !!org.node.deletedAt;
            const isBlacklisted = org.node.organizationsData.blacklisted;
            const isPublic = org.node.organizationsData.isPublic;
            const isTestOrganization = org.node.organizationsData.isTest;
            const giata = org.node.organizationsData.giata;
            const members = orgData.members?.map((mem) => {
              return { code: mem.member.code, label: mem.member.code };
            });
            const ownerMember = orgData.members?.filter((mem) => mem.role === 'OWNER')?.map((mem) => mem.member.code);
            const owner = orgData.owner?.organizationsData
              ? {
                  code: orgData.owner.organizationsData.code,
                  label: orgData.owner.organizationsData.label,
                  ownerField: `${orgData.owner.organizationsData.label} (${orgData.owner.organizationsData.code})`,
                }
              : '';

            return {
              label,
              code,
              organization,
              isTest,
              owner,
              ownerMember,
              isArchived,
              members,
              isBlacklisted,
              giata,
              isPublic,
            };
          });

          this.totalOrgs = rs.totalCount;
          resolve();
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
          reject(err);
        });
    });
  }

  onToggleArchive(org): void {
    if (!org) {
      return;
    }

    const isArchived = org.isArchived;
    const action = isArchived ? 'archived' : 'unarchived';
    const actionTitle = isArchived ? 'Archive' : 'Unarchive';

    const message = `Are you sure you want to ${action} organization ${org.label}?`;

    this.openConfirmationModal(message, actionTitle, org)
      .then((confirmed) => {
        if (confirmed) {
          const success = isArchived
            ? this.organizationNetworkService.archiveOrganization(org.code)
            : this.organizationNetworkService.unArchiveOrganization(org.code);

          if (success) {
            this.notificationService.success(`Organization successfully ${action}`, 'Success');
          } else {
            this.notificationService.warning(`Organization NOT  ${action}`, 'Warning');
          }
        } else {
          org.isArchived = !org.isArchived;
        }
      })
      .catch((error) => {
        console.log(error);
        this.notificationService.error(error);
      });
  }

  onToggleTest(org): void {
    if (!org) {
      return;
    }

    const isTest = org.isTest;
    const action = isTest ? 'set as Test' : 'unset as Test';
    const actionTitle = isTest ? 'Set as Test' : 'Unset as Test';

    const message = `Are you sure you want to ${action} the organization: ${org.label}?`;

    this.openGenericConfirmationModal(message, actionTitle, org)
      .then((confirmed) => {
        if (confirmed) {
          const where = { 'search': org.code, 'searchBy': 'CODE' };

          this.organizationNetworkService
            .setTestValueOrganization(where, isTest)
            .then(() => {
              this.notificationService.success(`Organization successfully ${action}`, 'Success');
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        } else {
          org.isTest = !org.isTest;
        }
      })
      .catch((error) => {
        console.log(error);
        this.notificationService.error(error);
      });
  }

  onToggleIsPublic(org): void {
    if (!org) {
      return;
    }

    const isPublic = org.isPublic;
    const action = isPublic ? 'public' : '(un) public';
    const actionTitle = isPublic ? 'public' : '(un) public';

    const message = `Are you sure you want to ${action} the organization: ${org.label}?`;

    this.openGenericConfirmationModal(message, actionTitle, org)
      .then((confirmed) => {
        if (confirmed) {
          const where = { 'search': org.code, 'searchBy': 'CODE' };

          this.organizationNetworkService
            .setIsPublicValueOrganization(where, isPublic)
            .then(() => {
              this.notificationService.success(`Organization successfully ${action}`, 'Success');
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        } else {
          org.isPublic = !org.isPublic;
        }
      })
      .catch((error) => {
        console.log(error);
        this.notificationService.error(error);
      });
  }

  onToggleBlacklisted(org): void {
    if (!org) {
      return;
    }

    const isBlacklisted = org.isBlacklisted;
    const action = isBlacklisted ? 'blacklist' : '(un) blacklist';
    const actionTitle = isBlacklisted ? 'blacklist' : '(un) blacklist';

    const message = `Are you sure you want to ${action} organization ${org.label}?`;

    this.openGenericConfirmationModal(message, actionTitle, org)
      .then((confirmed) => {
        if (confirmed) {
          const where = { 'search': org.code, 'searchBy': 'CODE' };
          const blacklistAction = isBlacklisted
            ? this.organizationNetworkService.blackListOrganization(where)
            : this.organizationNetworkService.unBlackListOrganization(where);

          blacklistAction
            .then(() => {
              this.notificationService.success(`Organization successfully ${action}`, 'Success');
            })
            .catch((err) => {
              this.notificationService.handleGatewayAndGraphqlErrors(err);
            });
        } else {
          org.isBlacklisted = !org.isBlacklisted;
        }
      })
      .catch((error) => {
        console.log(error);
        this.notificationService.error(error);
      });
  }

  async openGenericConfirmationModal(message: string, actionTitle: string, org: any): Promise<boolean> {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = `${actionTitle}`;
    modalRef.componentInstance.buttonOkTitle = actionTitle;
    modalRef.dismissed.subscribe(() => {
      org.isBlacklisted = !org.isBlacklisted;
    });

    return modalRef.result.then((res) => res === true).finally(() => modalRef.close());
  }

  async openConfirmationModal(message: string, actionTitle: string, org: any): Promise<boolean> {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = `${actionTitle} organization`;
    modalRef.componentInstance.buttonOkTitle = actionTitle;
    modalRef.dismissed.subscribe(() => {
      org.isArchived = !org.isArchived;
    });

    return modalRef.result.then((res) => res === true).finally(() => modalRef.close());
  }

  onCustom($event): void {
    switch ($event.action) {
      case 'edit':
        this.onEdit($event.data);
        break;
    }
  }

  onEdit(org): void {
    const organization: Organization = org;

    const modalRef = this.modalService.open(OrganizationModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const tmpOrgs = [...this.allOrgs];
    tmpOrgs.unshift({ code: '', label: 'NO PARTNER' });

    modalRef.componentInstance.header = 'Edit Organization';
    modalRef.componentInstance.organization = organization;
    modalRef.componentInstance.partners = tmpOrgs;

    modalRef.result.then(() => {
      this.router.navigate(['/entities/organizations']);
    });
  }
}
