export enum ModuleType {
  BILLING = 'Billing',
  NETWORK = 'Network',
  ENTITIES = 'Entities',
  DASHBOARD = 'Dashboard',
  BANNER = 'Banner' /* Banner and Notifications */,
}

export enum ActionType {
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
}

export interface AdminUserPermissions {
  dashboard?: ActionType;
  entities?: ActionType;
  network?: ActionType;
  billing?: ActionType;
  banner?: ActionType /* Banner and Notifications */;
}
