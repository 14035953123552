<nb-card class="col-md-12 col-lg-12 col-xxxl-12">
  <nb-card-body *ngIf="isEditor">
    <form [formGroup]="dateForm">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label class="mb-1" for="date1"><b>First Month: </b><span class="mandatory ml-1">*</span> </label>
            <p-calendar
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="date1"
              view="month"
              dateFormat="mm/yy"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
              placeholder="Select a month.."
              (onClose)="onConfigChange()"
            ></p-calendar>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="mb-1" for="date2"><b>Second Month: </b><span class="mandatory ml-1">*</span> </label>
            <p-calendar
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="date2"
              view="month"
              dateFormat="mm/yy"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
              placeholder="Select a month.."
              (onClose)="onConfigChange()"
            ></p-calendar>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>

<nb-card class="col-md-12 col-lg-12 col-xxxl-12" *ngIf="dateForm.valid">
  <nb-card-header> Post steps after bill </nb-card-header>
  <nb-card-body *ngIf="!isEditor">
    You are in the billing group, but you must be an admin to view and execute this operation.
  </nb-card-body>
  <nb-card-body *ngIf="isEditor">
    <nb-stepper orientation="horizontal" #stepper>
      <nb-step label="First">
        <!-- <h5>Amount per currency VS last month</h5> -->
        <tgx-admin-billing-invoicing-process-post-first></tgx-admin-billing-invoicing-process-post-first>
        <button
          class="btn btn-md btn-primary"
          [disabled]="dateForm.invalid"
          (click)="changeCurrentStep(2)"
          nbStepperNext
        >
          Next
        </button>
      </nb-step>
      <nb-step label="Second">
        <!-- <h5>Billing per contract VS last month</h5> -->
        <tgx-admin-billing-invoicing-process-post-second></tgx-admin-billing-invoicing-process-post-second>
        <button class="btn btn-md btn-danger" (click)="changeCurrentStep(1)" nbStepperPrevious>Prev</button>
        <button class="btn btn-md btn-primary" (click)="finishStepper()">Finish</button>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>
