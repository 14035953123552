import {
  ProfileCreateInput,
  ProfileUpdateInput,
  ProfileWhereUniqueInput,
} from './../interfaces/_index-network.interfaces';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ProfileWhereInput } from '../interfaces/_index-network.interfaces';
import { GraphqlNetworkQueries } from './queries/_index-network.queries';
import { GraphqlNetworkMutations } from './mutations/_index-network.mutations';
import { GqlContextName, GqlService } from '@tgx/shared/data-access/graphql';
import { CategoryCreateInput } from '../interfaces/inputs/category-create-input';
import { CategoryUpdateInput } from '../interfaces/inputs/category-update-input';
import { SubCategoryUpdateInput } from '../interfaces/inputs/sub-category-update-input copy';
import { SubCategoryWhereUniqueInput } from '../interfaces/inputs/sub-category-where-unique-input.interface';
import { CategoryWhereUniqueInput } from '../interfaces/inputs/category-where-unique-input.interface';
import { SubCategoryCreateInput } from '../interfaces/inputs/sub-category-create-input';
import { getBuyerTechPlatform } from './queries/getBuyerTechPlatform';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { getWidgetProfiles } from './queries/getWidgetProfiles';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Profile } from '@tgx/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProfileNetworkService {
  prfCodes = new BehaviorSubject<string[]>(null);

  constructor(
    private apollo: Apollo,
    private gqlService: GqlService,
    private http: HttpClient,
  ) {}

  async getCategories() {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY, GraphqlNetworkQueries.getCategories)
        .then((res: any) => {
          if (res?.social?.allCategories?.edges) {
            resolve((((res || {}).social || {}).allCategories || {}).edges.map((prf) => prf.node));
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createCategory(data: CategoryCreateInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.createCategory, { data })
        .then((res: any) => {
          if (res?.social?.createCategory?.edges) {
            resolve((((res || {}).social || {}).createCategory || {}).edges.map((prf) => prf.node));
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteCategory(where: CategoryWhereUniqueInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.deleteCategory, { where })
        .then((res: any) => {
          if (res?.social?.deleteCategory?.adviseMessage) {
            resolve(((((res || {}).social || {}).deleteCategory || {}).adviseMessage || {}).description);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateCategory(data: CategoryUpdateInput, where: CategoryWhereUniqueInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.updateCategory, { data, where })
        .then((res: any) => {
          if (res?.social?.updateCategory) {
            resolve(((res || {}).social || {}).updateCategory);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateSubCategory(data: SubCategoryUpdateInput, where: SubCategoryWhereUniqueInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.updateSubCategory, { data, where })
        .then((res: any) => {
          if (res?.social?.updateSubCategory) {
            resolve(((res || {}).social || {}).updateSubCategory);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteSubCategory(where: SubCategoryWhereUniqueInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.deleteSubCategory, { where })
        .then((res: any) => {
          if (res?.social?.deleteSubCategory?.adviseMessage) {
            resolve(((((res || {}).social || {}).deleteSubCategory || {}).adviseMessage || {}).description);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createSubCategory(data: SubCategoryCreateInput, where: CategoryWhereUniqueInput) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.createSubCategory, { data, where })
        .then((res: any) => {
          if (res?.social?.createSubCategory?.edges) {
            resolve((((res || {}).social || {}).createSubCategory || {}).edges.map((prf) => prf.node));
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getProfiles(where: ProfileWhereInput): Promise<Profile[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY, GraphqlNetworkQueries.getProfiles, { where: where })
        .then((res: any) => {
          if (res?.social?.allProfilesV2?.edges) {
            resolve((((res || {}).social || {}).allProfilesV2 || {}).edges.map((prf) => prf.node));
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTotalProfiles(): Promise<Profile[]> {
    let prfs: any;

    return await new Promise((resolve, reject) => {
      this.gqlService
        .query(GqlContextName.GATEWAY, GraphqlNetworkQueries.getTotalProfiles)
        .then((res: any) => {
          prfs = res;
          const profiles = prfs.social.allProfilesV2.edges.map((prof) => prof.node);
          resolve(profiles);
        })
        .catch((err) => reject(err));
    });
  }

  async createProfile(input: ProfileCreateInput): Promise<Profile> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('gateway')
        .mutate({
          mutation: GraphqlNetworkMutations.createProfile,
          variables: { data: input },
        })
        .subscribe(
          (res: any) => {
            if (
              res?.data?.social?.createProfile?.adviseMessage?.length > 0 &&
              res?.data?.social?.createProfile?.adviseMessage[0].level === 'ERROR'
            ) {
              reject(((((res || {}).data || {}).social || {}).createProfile || {}).adviseMessage[0]);
            } else {
              const prf: Profile = {
                code: res.data.social.createProfile.code,
                createdAt: res.data.social.createProfile.createdAt,
                updatedAt: res.data.social.createProfile.updatedAt,
                profileData: res.data.social.createProfile.profileData,
              };
              resolve(prf);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async updateProfile(data: ProfileUpdateInput, where: ProfileWhereUniqueInput): Promise<Profile> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .mutation(GqlContextName.GATEWAY, GraphqlNetworkMutations.updateProfile, { data: data, where: where })
        .then((res: any) => {
          if (
            res?.social?.updateProfile?.adviseMessage?.length > 0 &&
            res?.social?.updateProfile?.adviseMessage[0].level === 'ERROR'
          ) {
            reject((((res || {}).social || {}).updateProfile || {}).adviseMessage[0]);
          } else {
            const prf: Profile = {
              code: res.social.updateProfile.code,
              createdAt: res.social.updateProfile.createdAt,
              updatedAt: res.social.updateProfile.updatedAt,
              profileData: res.social.updateProfile.profileData,
            };
            resolve(prf);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async deleteProfile(where: ProfileWhereUniqueInput): Promise<Profile> {
    return await new Promise((resolve, reject) => {
      this.apollo
        .use('gateway')
        .mutate({
          mutation: GraphqlNetworkMutations.deleteProfile,
          variables: { where: where },
        })
        .subscribe(
          (res: any) => {
            if (
              res?.data?.social?.deleteProfile?.adviseMessage?.length > 0 &&
              res?.data?.social?.deleteProfile?.adviseMessage[0].level === 'ERROR'
            ) {
              reject(((((res || {}).data || {}).social || {}).deleteProfile || {}).adviseMessage[0]);
            } else {
              const prf: Profile = {
                code: res.data.social.deleteProfile.code,
                createdAt: res.data.social.deleteProfile.createdAt,
                updatedAt: res.data.social.deleteProfile.updatedAt,
                profileData: res.data.social.deleteProfile.profileData,
              };
              resolve(prf);
            }
          },
          (err) => {
            return reject(err);
          },
        );
    });
  }

  async retrieveTechPlatforms(partnerType: string) {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(getBuyerTechPlatform)
        .then((res: any) => {
          if (res?.social?.allProfilesV2?.edges) {
            if (partnerType === 'buyer') {
              const list = (((res || {}).social || {}).allProfilesV2 || {}).edges
                .map((prf) => prf.node)
                .filter((prf) => {
                  return prf.profileData.isDemandBridge && !prf.profileData.owner.organizationsData?.isTest;
                });
              resolve(list);
            } else {
              const list = (((res || {}).social || {}).allProfilesV2 || {}).edges
                .map((prf) => prf.node)
                .filter((prf) => {
                  return prf.profileData.isSupplyBridge && !prf.profileData.owner.organizationsData?.isTest;
                });
              resolve(list);
            }
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getProfilesWidget(): Promise<{ data: Map<string, string>; mapPrfsNoOrgs?: Map<string, string>; error?: any }> {
    const mapPrfsWithOrgs = new Map<string, string>();
    const mapPrfsNoOrgs = new Map<string, string>();

    return this.gqlService
      .queryGateway(getWidgetProfiles)
      .then((res: any) => {
        const edges = res?.social?.allProfilesV2?.edges;
        if (edges?.length > 0) {
          edges.forEach((elem: any) => {
            const prfData = elem?.node?.profileData;
            if (prfData?.owner.code) {
              mapPrfsWithOrgs.set(elem.node.code, prfData.owner.code);
            } else {
              mapPrfsNoOrgs.set(elem.node.code, prfData.name);
            }
          });

          return { data: mapPrfsWithOrgs, mapPrfsNoOrgs: mapPrfsNoOrgs, total: edges.length };
        } else {
          return { data: null, mapPrfsNoOrgs: null, error: 'The social response is empty' };
        }
      })
      .catch((err) => {
        return { data: null, mapPrfsNoOrgs: null, error: err };
      });
  }

  setProfilesCodes(prfCodes) {
    this.prfCodes.next(prfCodes);
  }

  async getProfilesDataCsv(): Promise<Profile[]> {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryGateway(GraphqlNetworkQueries.getProfilesCsvData)
        .then((res: any) => {
          if (res?.social?.allProfilesV2?.edges) {
            resolve((((res || {}).social || {}).allProfilesV2 || {}).edges.map((prf) => prf.node));
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async allRegions(): Promise<string[]> {
    const result = await lastValueFrom(this.http.get<string[]>(`${environment.apis.social}rest-api/get-regions`));
    if (!Array.isArray(result)) {
      throw new Error('Response is not an array');
    } else return result;
  }

  async getAllCurrentContracts() {
    return await new Promise((resolve, reject) => {
      this.gqlService
        .queryBilling(GraphqlNetworkQueries.getAllCurrentContracts)
        .then((res: any) => {
          if (res.getAllCurrentContracts) {
            console.log({ res });
            resolve(res.getAllCurrentContracts);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
