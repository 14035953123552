//apps\web-admin\src\app\features\content\modules\enqueuer\components\enqueuer-main\enqueuer-table.component.ts
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../../../../@core/services/_index-core.services';
import { Options } from '../../../../../../@core/interfaces/_index.interfaces';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SuppliersService, AccessesService } from '../../../../../entities/services/_index-entities.services';
import { Masters, EnqueuerFilter, Task } from '../../../../interfaces/_index-content.interfaces';
import { TaskManagerService } from '../../../../services/task-manager.service';
import { TaskManagerAddTaskResult } from '../../../../models/task-manager.models';

const operationsConfig = {
  enrichOperations: ['Configuration', 'MetaData', 'GIATAList', 'MappingTgx'],
  singleSupplierOperations: [
    'HotelList',
    'DescriptiveInfo',
    'GeographicTree',
    'MealPlanList',
    'CategoryList',
    'RoomTypeList',
    'CurrencyList',
    'MarketList',
    'AmenitiesList',
    'MappingTgx',
  ],
  enableTestAccessOperations: [
    'HotelList',
    'DescriptiveInfo',
    'GeographicTree',
    'MealPlanList',
    'CategoryList',
    'RoomTypeList',
    'CurrencyList',
    'MarketList',
    'AmenitiesList',
  ],
  enableDeleteIndexOperations: ['GeographicTree', 'MealPlanList', 'CategoryList', 'CurrencyList', 'MarketList'],
};

@Component({
  selector: 'tgx-billing-enqueuer-table',
  templateUrl: './enqueuer-table.component.html',
  styleUrls: ['./enqueuer-table.component.scss'],
})
export class EnqueuerTableComponent implements OnInit {
  public searchForm: UntypedFormGroup;
  isLoading = false;
  suppliersSelector: Options[] = [];
  filteredSuppliersSelector: Options[] = [];
  accessSelector: Options[] = [];
  operationsSelector: Options[] = [];
  serviceType = 'Hotel';
  filter: EnqueuerFilter;
  showFullInventory = false;
  showEnableTestAccesses = true;
  showDeleteIndex = false;
  isSingleSupplier = false;
  activeLetter = 'A';
  masterData: Masters;
  addTaskResult: TaskManagerAddTaskResult;

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private supplierService: SuppliersService,
    private taskManagerService: TaskManagerService,
    private accessesService: AccessesService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.buildForm();
    await Promise.all([this.getSuppliers(), this.getMasterData()]);
    this.applyDefaultValues();
    this.filterSuppliersByLetter(this.activeLetter);
    this.isLoading = false;
  }

  buildForm() {
    this.searchForm = this.fb.group({
      accesses: [''],
      suppliers: [''],
      operations: [null],
      obsoleteDays: false,
      serviceType: 'Hotel',
      force: false,
      priority: false,
      enableTestAccesses: false,
      deleteIndex: false,
    });

    this.searchForm.get('operations').valueChanges.subscribe((value) => {
      const operationCode = value.code;
      this.showFullInventory = operationCode === 'DescriptiveInfo';
      this.showEnableTestAccesses = operationsConfig.enableTestAccessOperations.includes(operationCode);
      this.showDeleteIndex = operationsConfig.enableDeleteIndexOperations.includes(operationCode);
      this.isSingleSupplier = operationsConfig.singleSupplierOperations.includes(operationCode);
      if (this.isSingleSupplier && this.searchForm.get('suppliers').value.length > 1) {
        this.deselectAllSuppliers();
      }
    });

    this.searchForm.get('suppliers').valueChanges.subscribe(() => {
      this.sortSuppliers();
      this.checkAndReapplyLetterFilter();
    });
  }

  applyDefaultValues() {
    const defaultOperation = this.operationsSelector.find((op) => op.code === 'HotelList');
    if (defaultOperation) {
      this.searchForm.patchValue({
        operations: defaultOperation,
      });
    }
  }

  async getSuppliers() {
    try {
      const supplierEdges = await this.supplierService.getAllSuppliersWithoutFilter();
      this.suppliersSelector = supplierEdges.map((sup) => ({
        code: sup.node?.supplierData?.code,
        label: `${sup.node?.supplierData?.name} (${sup.node?.supplierData?.code})`,
      }));
      this.filteredSuppliersSelector = [...this.suppliersSelector];
      this.sortSuppliers();
    } catch (err) {
      this.notificationService.handleGatewayAndGraphqlErrors(err);
    }
  }

  sortSuppliers() {
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    this.filteredSuppliersSelector.sort((a, b) => {
      const aSelected = selectedSuppliers.some((sup) => sup.code === a.code);
      const bSelected = selectedSuppliers.some((sup) => sup.code === b.code);
      return aSelected && !bSelected ? -1 : !aSelected && bSelected ? 1 : 0;
    });
  }

  checkAndReapplyLetterFilter() {
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    const filterApplies = selectedSuppliers.every(
      (sup) => sup.label.charAt(sup.label.lastIndexOf('(') + 1) === this.activeLetter,
    );

    if (!filterApplies || selectedSuppliers.length === 0) {
      this.filterSuppliersByLetter(this.activeLetter);
    }
  }

  async getAccessesSchedulderActivated(event: any) {
    const supplierCode = event?.itemValue?.code;
    if (supplierCode) {
      try {
        const accesses = await this.accessesService.getAllAccesses({ supplier_in: [supplierCode] });
        this.accessSelector = accesses
          .filter((cli) => cli.node?.accessData?.isActive && cli.node?.accessData?.isSchedulerActive)
          .map((cli) => ({
            code: cli.node?.accessData?.code,
            label: `${cli.node?.accessData?.name} (${cli.node?.accessData?.code})`,
          }));
        this.searchForm.get('accesses').setValue([]);
      } catch (err) {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      }
    } else {
      this.notificationService.warning('Please select a valid supplier.', 'Warning');
    }
  }

  async getMasterData() {
    try {
      this.masterData = await this.taskManagerService.getMasterData();
      this.buildSelects();
    } catch (err) {
      this.notificationService.handleGatewayAndGraphqlErrors(err);
    }
  }

  buildSelects() {
    if (this.masterData) {
      this.operationsSelector = this.masterData.ServiceOperations.map((so) => ({
        code: so,
        label: so,
        type: operationsConfig.enrichOperations.includes(so) ? 'Enrich' : 'Hotel',
      }));
    }
  }

  filterSuppliersByLetter(letter: string) {
    this.activeLetter = letter;
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    this.filteredSuppliersSelector = this.suppliersSelector.filter(
      (sup) =>
        sup.label.charAt(sup.label.lastIndexOf('(') + 1) === letter ||
        selectedSuppliers.some((selectedSup) => selectedSup.code === sup.code),
    );
    this.sortSuppliers();
  }

  handleFilter(event: any) {
    const filterValue = event.filter.trim().toUpperCase();
    const selectedSuppliers = this.searchForm.get('suppliers').value || [];
    if (filterValue.length > 1) {
      this.filteredSuppliersSelector = this.suppliersSelector.filter(
        (sup) =>
          sup.label.toUpperCase().includes(filterValue) ||
          selectedSuppliers.some((selectedSup) => selectedSup.code === sup.code),
      );
    } else if (filterValue.length === 0) {
      this.filterSuppliersByLetter(this.activeLetter);
    } else {
      this.filteredSuppliersSelector = this.suppliersSelector.filter(
        (sup) =>
          (sup.label.toUpperCase().includes(filterValue) &&
            sup.label.charAt(sup.label.lastIndexOf('(') + 1) === this.activeLetter) ||
          selectedSuppliers.some((selectedSup) => selectedSup.code === sup.code),
      );
    }
    this.sortSuppliers();
  }

  async enqueuer() {
    const suppliers = this.searchForm.get('suppliers').value;
    const operation = this.searchForm.get('operations').value;

    if (!operation) {
      this.notificationService.warning('Please select an operation.', 'Warning');
      return;
    }

    if (this.isSingleSupplier && suppliers.length === 0) {
      this.notificationService.warning('Please select a supplier for this operation.', 'Warning');
      return;
    }

    if (this.isSingleSupplier && suppliers.length > 1) {
      this.notificationService.warning('Please select only one supplier for this operation.', 'Warning');
      return;
    }

    const invalidSuppliers = suppliers.filter((supplier: Options) => !supplier.code);
    if (invalidSuppliers.length > 0) {
      const invalidSupplierNames = invalidSuppliers.map((supplier) => supplier.label).join(', ');
      this.notificationService.warning(`Invalid suppliers: ${invalidSupplierNames}`, 'Warning');
      return;
    }

    await this.enqueuerFilter();

    if (operationsConfig.enrichOperations.includes(operation.code)) {
      await this.executeTask(() => this.taskManagerService.addTaskAll(this.filter));
    } else {
      const accesses = this.searchForm.get('accesses').value;
      if (!accesses || accesses.length === 0 || accesses[0] === '') {
        this.notificationService.warning('Please select any access(es).', 'Data Empty');
        return;
      }
      await this.executeTask(() => this.taskManagerService.addTaskByAccess(this.filter));
    }
  }

  async executeTask(task: () => Promise<TaskManagerAddTaskResult>) {
    try {
      const rs = await task();
      this.addTaskResult = rs;
      this.buildSelects();
      if (rs.adviseMessages && rs.adviseMessages.length > 0) {
        rs.adviseMessages.forEach((o) => this.notificationService.warning(o.description, 'Warning'));
      }
      this.notificationService.success(`Number of tasks added: ${rs.addedTasks.length}`, 'Success');
    } catch (err) {
      this.notificationService.handleGatewayAndGraphqlErrors(err);
    }
  }

  async enqueuerFilter() {
    const suppliers = this.searchForm.get('suppliers').value;
    const isSupplierSelected = suppliers.length > 0;
    const serviceOperations = this.searchForm.get('operations').value.code;

    this.filter = {
      force: this.searchForm.get('force').value,
      obsoleteDays: this.searchForm.get('obsoleteDays').value ? '0' : null,
      priority: this.searchForm.get('priority').value ? 'Critical' : 'High',
      suppliers: isSupplierSelected ? this.getSuppliersCode(suppliers) : null,
      serviceOperations,
      accesses: this.getAccessesCode(this.searchForm.get('accesses').value),
      serviceType: this.searchForm.get('operations').value.type ?? 'Hotel',
      enableTestAccesses: this.searchForm.get('enableTestAccesses').value,
      deleteIndex: this.showDeleteIndex ? this.searchForm.get('deleteIndex').value : false,
      allSuppliers: isSupplierSelected ? false : !operationsConfig.singleSupplierOperations.includes(serviceOperations),
      requestSource: 'Web',
    };

    if (!isSupplierSelected && operationsConfig.singleSupplierOperations.includes(serviceOperations)) {
      this.notificationService.warning('Please select a supplier for this operation.', 'Warning');
      return;
    }
  }

  getSuppliersCode(suppliers: Options[]): string | null {
    const validSuppliers = suppliers.filter((supplier) => supplier.code);
    return validSuppliers.length > 0 ? validSuppliers[0].code : null;
  }

  getAccessesCode(accesses: Options[]): string[] {
    return accesses?.map((op) => op.code) ?? [];
  }

  async changeType(event: any) {
    const operationCode = event.value.code;
    this.serviceType = event.value.type ?? 'Hotel';
    this.showFullInventory = operationCode === 'DescriptiveInfo';
    this.showEnableTestAccesses = operationsConfig.enableTestAccessOperations.includes(operationCode);
    this.showDeleteIndex = operationsConfig.enableDeleteIndexOperations.includes(operationCode);
    this.isSingleSupplier = operationsConfig.singleSupplierOperations.includes(operationCode);
    if (this.isSingleSupplier && this.searchForm.get('suppliers').value.length > 1) {
      this.deselectAllSuppliers();
    }
  }

  deselectAllSuppliers() {
    this.searchForm.get('suppliers').setValue([], { emitEvent: false });
  }
}
