import { gql } from 'apollo-angular';

export const getOrganizations = gql`
  query Organizations($code_in: [ID!], $includeArchived: Boolean) {
    admin {
      allOrganizations(where: { includeArchived: $includeArchived, code_in: $code_in }) {
        totalCount
        edges {
          node {
            code
            organizationsData {
              id
              code
              label
              owner {
                code
                organizationsData {
                  code
                  label
                }
              }
              isTest
              members {
                member {
                  code
                }
                role
              }
              blacklisted
              isPublic
              giata {
                configuration
                isActive
              }
            }
            createdAt
            updatedAt
            deletedAt
          }
        }
        adviseMessage {
          code
          description
          level
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
