import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../../../../../..//web-admin/src/app/@core/services/notification.service';
import { WebAdminService } from '../../../../../../../../..//web-admin/src/app/@core/services/web-admin.service';
import { OrganizationSelector } from '../../../../interfaces/_index-entities.interfaces';
import { SupplierEdge } from '../../../../interfaces/models/suppliers.interface';
import { SuppliersService } from '../../../../services/suppliers.service';
import { ModuleType, Options } from './../../../../../../@core/interfaces/_index.interfaces';

import { GroupContentContextService } from '../../../../services/group-content-context.service';
import { OrganizationEntitiesService } from '../../../../services/organization-entities.service';
import { ProvidersService } from '../../../../services/providers.service';

import { Table } from 'primeng/table';
import { RetrieveAllSuppliersByGroupsInput } from '../../../../interfaces/inputs/retrieve-all-suppliers-by-groups-input';
import { GiataModalComponent } from '../giata-modal/giata-modal.component';
import { SupplierToggleActiveModalComponent } from '../supplier-ToggleActive-modal/supplier-ToggleActive-modal.component';
import { SupplierToggleIsPublicModalComponent } from '../supplier-ToggleIsPublic-modal/supplier-ToggleIsPublic-modal.component';
import { SupplierModalComponent } from '../supplier-modal/supplier-modal.component';
import { SupplierLogsModalComponent } from '../supplier-logs-modal/supplier-logs-modal.component';

@Component({
  selector: 'tgx-admin-suppliers-table',
  templateUrl: './suppliers-table.component.html',
  styleUrls: ['./suppliers-table.component.scss'],
})
export class SuppliersTableComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable') public dataTable: Table;
  selectedSuppliers: any[];
  selectedSuppliersAux: any[];
  clientName: string;
  isLoading: boolean;
  isProviderActiveClass: string;
  suppliers: SupplierEdge[];
  suppliersLoadSource: any[];
  groups: Options[];
  $subs: Subscription[] = [];
  serviceApi: string;
  api: string;

  public queryFormOrganizations: UntypedFormGroup;
  organizations: Options[];
  providers: Options[];
  groupContent_context: Options[];
  orgSelectors: OrganizationSelector[];
  selectedOrg: Options;
  selectedProvider: Options;
  selectedServiceApi: Options;

  totalSuppliers = 0;
  providerCode: string;
  selectedGroupContentContext: Options;
  assignedGroupsOptions: Options[];

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private notificationService: NotificationService,
    private suppliersService: SuppliersService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private organizationsService: OrganizationEntitiesService,
    private providersService: ProvidersService,
    private groupContentContextService: GroupContentContextService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      providerCode: string;
    };

    this.providerCode = state && state.providerCode ? state.providerCode : '';
  }

  onCustom($event): void {
    switch ($event.action) {
      case 'edit-supplier':
        this.onEdit($event);
        break;
      case 'legacy-connections':
        this.onLegacyConnections($event);
        break;
      case 'giata-info':
        this.onGiataInfo($event);
        break;
      case 'access':
        this.onAccess($event);
        break;
    }
  }

  async onGiataInfo(supplier: any) {
    try {
      const Supplier = await this.suppliersService.getSupplier(supplier.code);
      const activeModal = this.modalService.open(GiataModalComponent, {
        size: 'lg',
        backdrop: 'static',
        container: 'nb-layout',
      });
      activeModal.componentInstance.header = 'Giata | ' + supplier.name;
      activeModal.componentInstance.SupplierData = Supplier.supplierData;
    } catch (err) {
      this.notificationService.warning(
        'Error getting GIATA for Supplier code: ' + supplier.name + ' (' + supplier.code + ')',
        'Warning',
      );
    }
  }

  onLegacyConnections(supplier: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        providerCode: supplier.providerCode,
      },
    };
    this.router.navigate(['/entities/legacy-connections'], navigationExtras);
  }

  onAccess(supplier: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        supplierCode: supplier.code,
      },
    };
    this.router.navigate(['/entities/accesses'], navigationExtras);
  }

  async onEdit(supplier: any) {
    const activeModal = this.modalService.open(SupplierModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    //filter organizations to only show the related organization.
    const filteredOrganizations = this.organizations.filter((org) => {
      const slots = supplier.owner.split(' ');
      return org.code == slots[0];
    });

    this.selectedOrg = null;
    if (filteredOrganizations.length > 0) {
      this.selectedOrg = filteredOrganizations[0];
    }
    //filter providers
    const filteredProvider = this.providers.filter((prov) => prov.code === supplier.providerCode);
    if (filteredProvider.length > 0) {
      this.selectedProvider = filteredProvider[0];
    }

    this.selectedServiceApi = { code: supplier.serviceApi, label: supplier.api };

    //filter Group content context
    this.selectedGroupContentContext = { code: '', label: '' };
    const filteredGroupContentContext = this.groupContent_context.filter((group) => {
      if (supplier.groupContentCode_context) {
        return group.label === supplier.groupContentCode_context;
      }
    });
    if (filteredGroupContentContext.length > 0) {
      this.selectedGroupContentContext = filteredGroupContentContext[0];
    }

    activeModal.componentInstance.header = 'Update supplier';
    activeModal.componentInstance.organizations = this.organizations;
    activeModal.componentInstance.selectedOrg = this.selectedOrg;
    activeModal.componentInstance.selectedProvider = this.selectedProvider;
    activeModal.componentInstance.selectedGroupContentContext = this.selectedGroupContentContext;
    activeModal.componentInstance.serviceApi = this.selectedServiceApi;
    activeModal.componentInstance.groups = this.groups;
    activeModal.componentInstance.isCreating = false;
    activeModal.componentInstance.supplier = supplier;
    activeModal.componentInstance.providers = this.providers;
    activeModal.componentInstance.groupContent_context = this.groupContent_context;

    const self = this;

    activeModal.result.then(async (res) => {
      // on close
      if (typeof res === 'boolean' && res) {
        //todo  ??
      } else if (typeof res === 'boolean' && !res) {
        //todo  ??
      } else if (typeof res === 'object') {
        this.notificationService.success('Supplier succesfully updated.', 'Success');
        await self.retrieveAllSuppliers();
      }
    });
  }

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.ENTITIES);
    this.isProviderActiveClass = '';
  }

  ngOnInit(): void {
    setTimeout(() => {
      //set filter value of table
      if (this.dataTable !== undefined) {
        if (this.providerCode) this.dataTable.filter(this.providerCode, 'providerCode', 'contains');
      }
    }, 1000);
    this.buildForm();
    this.isLoading = true;
    this.buildOptions();
    this.retrieveAllSuppliers();
    this.loadAllProviders();
    this.loadGroupContentContexts();
    this.organizations = [];
    this.orgSelectors = [];

    this.$subs.push(
      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.orgSelectors = orgs;
          this.buildOrganizationsDropdown(orgs);
          this.organizations = this.organizations.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
          );
        }
      }),
      this.webAdminService.allTeams.subscribe((teams) => {
        if (teams?.length > 0) {
          this.orgSelectors = this.orgSelectors.concat(teams);
          this.buildOrganizationsDropdown(teams);
          this.organizations = this.organizations.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
          );
        }
      }),
    );

    //toggle activate/deactivate Supplier
    this.$subs.push(
      this.suppliersService.onToggleTable$.subscribe((supplier) => {
        if (supplier) {
          this.onToggleActivateDeactiveSupplier(supplier);
        }
      }),
    );

    //toggle archive/unarchive Supplier
    this.$subs.push(
      this.suppliersService.onToggleArchive$.subscribe((supplier) => {
        if (supplier) {
          this.onToggleArchiveUnarchiveSupplier(supplier);
        }
      }),
    );
  }

  async onToggleArchiveUnarchiveSupplier(supplier): Promise<void> {
    const activeModal = this.modalService.open(SupplierToggleActiveModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const operation = !supplier.deletedAt ? 'Archive' : 'Unarchive';

    activeModal.componentInstance.header = operation + ' suppliers';
    activeModal.componentInstance.message = 'Do you really want to ' + operation + ' this supplier?';
    activeModal.componentInstance.btnLabel = operation;
    activeModal.dismissed.subscribe(() => {
      supplier.isArchived = supplier.deletedAt;
    });

    activeModal.result.then((res) => {
      if (typeof res === 'boolean' && res) {
        const input = { search: supplier.code, searchBy: 'CODE' };
        if (supplier.deletedAt === true) {
          this.suppliersService
            .unArchiveSupplier(input)
            .then((res) => {
              this.notificationService.success('Supplier was unarchived');
            })
            .catch((er) => {
              this.notificationService.error(er);
            })
            .finally(() => {
              supplier.deletedAt = false;
            });
        } else {
          this.suppliersService
            .archiveSupplier(input)
            .then((res) => {
              supplier.deletedAt = true;
              supplier.isArchived = true;
              this.notificationService.success('Supplier was archived');
            })
            .catch((er) => {
              supplier.deletedAt = false;
              supplier.isArchived = false;
              this.notificationService.error(er);
            });
        }
      }
    });
  }

  async warnProviderIsDisabled() {
    this.notificationService.warning("The supplier's provider is deactivated, you cannot operate with this supplier");
  }

  async onToggleIsPublic(supplier): Promise<void> {
    const activeModal = this.modalService.open(SupplierToggleIsPublicModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const operation = !supplier.isPublicModel ? 'Unpublish' : 'Publish';
    const operationMessage = [
      'WARNING. This supplier would be made public for our partners, enabling them to connect with it. Are you sure?',
      "WARNING: This supplier would be unpublished for our partners and they won't be able to connect with it. Are you sure?",
    ];

    activeModal.componentInstance.header = operation + ' suppliers';
    activeModal.componentInstance.message = operation === 'Publish' ? operationMessage[0] : operationMessage[1];
    activeModal.componentInstance.btnLabel = operation;
    activeModal.dismissed.subscribe(() => {
      supplier.isPublicModel = supplier.isPublic;
    });

    activeModal.result.then((res) => {
      if (typeof res === 'boolean' && res) {
        supplier.isPublic = supplier.isPublicModel;
        this.setIsPublicSupplier(supplier);
      }
    });
  }

  async setIsPublicSupplier(supplier) {
    const operationMsg = supplier.isPublicModel ? 'Published' : 'Unpublished';
    this.suppliersService
      .setIsPublicSupplier({ search: supplier.code, searchBy: 'CODE' }, supplier.isPublicModel)
      .then((rs) => {
        this.notificationService.success('Supplier succesfully ' + operationMsg + '.', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async onToggleActivateDeactiveSupplier(supplier): Promise<void> {
    /* if (!supplier.providerIsActive) {
      this.notificationService.warning("The supplier's provider is deactivated, you cannot operate with this supplier");
      supplier.isActiveModel = supplier.isActive;
      return;
    } */

    const activeModal = this.modalService.open(SupplierToggleActiveModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const operation = !supplier.isActiveModel ? 'Deactivate' : 'Activate';

    activeModal.componentInstance.header = operation + ' suppliers';
    activeModal.componentInstance.message = 'Do you really want to ' + operation + ' this supplier?';
    activeModal.componentInstance.btnLabel = operation;
    activeModal.dismissed.subscribe(() => {
      supplier.isActiveModel = supplier.isActive;
    });
    activeModal.result.then((res) => {
      if (typeof res === 'boolean' && res) {
        supplier.isActive = supplier.isActiveModel;
        if (supplier.isActiveModel) {
          this.activateSupplier(supplier);
        } else {
          this.deactivateSupplier(supplier);
        }
      }
    });
  }

  async deactivateSupplier(supplier) {
    const operationMsg = supplier.isActiveModel ? 'Activated' : 'Deactivated';
    this.suppliersService
      .deactivateSupplier({ search: supplier.code, searchBy: 'CODE' })
      .then((rs) => {
        this.notificationService.success('Supplier succesfully ' + operationMsg + '.', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async activateSupplier(supplier) {
    const operationMsg = supplier.isActiveModel ? 'Activated' : 'Deactivated';
    this.suppliersService
      .activateSupplier({ search: supplier.code, searchBy: 'CODE' })
      .then((rs) => {
        this.notificationService.success('supplier succesfully ' + operationMsg + '.', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async loadGroupContentContexts() {
    this.groupContent_context = [];
    const allgroupContent_context = await this.groupContentContextService.getAll();
    allgroupContent_context.forEach((groupContentContextNode) => {
      const groupContentCodeFromData = groupContentContextNode.groupContentData.code;
      const contextFromData = groupContentContextNode.groupContentData.context.contextData.code;
      const label = this.composeContext(groupContentCodeFromData, contextFromData);

      this.groupContent_context.push({ code: groupContentCodeFromData, label: label });
    });
  }

  async getSelectedGroups(orgCodes: any) {
    const groupsPerOrganization = await this.organizationsService.getGroupsByCode(orgCodes);
    return [].concat.apply(
      [],
      groupsPerOrganization.map((value) => {
        return value.node.groupData.descendents.edges.map((descendent) => {
          return descendent.node.code;
        });
      }),
    );
  }

  async retrieveAllSuppliers() {
    //set filter in query.
    const orgCodes =
      this.queryFormOrganizations.get('codes').value !== ''
        ? this.queryFormOrganizations.get('codes').value.map((value) => value.code)
        : null;
    const input: RetrieveAllSuppliersByGroupsInput = {};
    if (orgCodes && orgCodes.length > 0) {
      const ownerCodes = await this.getSelectedGroups(orgCodes);
      input.group_in = ownerCodes && ownerCodes.length > 0 ? orgCodes : [];
    }

    const includeArchived = this.queryFormOrganizations.get('includeArchived')?.value ?? false;
    input['includeArchived'] = includeArchived;

    const allSuppliers = await this.suppliersService.getAllSuppliers(input);

    this.selectedSuppliers = [];
    this.selectedSuppliersAux = [];
    if (allSuppliers?.length > 0) {
      this.suppliers = allSuppliers;
      this.buildSuppliers();
    } else {
      this.isLoading = false;
    }
  }

  composeContext = (groupContentDataCode: string, contextDataCode: string) => {
    let context = groupContentDataCode && groupContentDataCode ? groupContentDataCode : '';
    context += ' : ';
    context += contextDataCode ? contextDataCode : '';
    return ' : ' === context ? '' : context;
  };

  buildSuppliers() {
    this.suppliersLoadSource = [];
    this.totalSuppliers = this.suppliers.length;
    this.suppliers.forEach((mb) => {
      const groupContentDataCode =
        mb.node.supplierData.groupContent && mb.node.supplierData.groupContent.code
          ? mb.node.supplierData.groupContent.code
          : '';
      const contextDataCode = mb.node.supplierData.context ?? '';
      const context = this.composeContext(groupContentDataCode, contextDataCode);

      this.suppliersLoadSource.push({
        code: mb.node.supplierData.code,
        fullName: mb.node.supplierData.name + ' (' + mb.node.supplierData.code + ')',
        name: mb.node.supplierData.name,
        isPublic: mb.node.supplierData.isPublic,
        isPublicModel: mb.node.supplierData.isPublic,
        isActive: mb.node.supplierData.isActive,
        isActiveModel: mb.node.supplierData.isActive,
        providerCode: mb.node.supplierData.provider.code
          ? mb.node.supplierData.provider.name + ' (' + mb.node.supplierData.provider.code + ')'
          : '-',
        providerIsActive: mb.node.supplierData.provider.isActive,
        owner: mb.node.supplierData.owner?.code
          ? mb.node.supplierData.owner.organizationData.label + ' (' + mb.node.supplierData.owner.code + ')'
          : '-',
        groupContentCode_context: context,
        serviceApi: mb.node.supplierData.serviceApi,
        api: mb.node.supplierData.api,
        deletedAt: !mb.node.deletedAt ? false : true,
        isArchived: !mb.node.deletedAt ? false : true,
        sharedDefault: mb.node.supplierData.sharedDefault,
      });
      this.isLoading = false;
    });
    this.addFilterToTable();
  }

  addFilterToTable(): void {
    if ('undefined' !== this.providerCode && this.providerCode !== '') {
      //TODO: Filter
      //this.source.setFilter([{ field: 'providerCode', search: this.providerCode }]);
    }
  }

  ngOnDestroy() {
    this.suppliersService.onToggleArchive$.next(null);
    this.$subs.forEach((s) => s.unsubscribe());
  }

  onCreate(): void {
    const activeModal = this.modalService.open(SupplierModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });
    activeModal.componentInstance.header = 'Add new Supplier connection';
    activeModal.componentInstance.isCreating = true;
    activeModal.componentInstance.organizations = this.organizations;
    activeModal.componentInstance.providers = this.providers;
    activeModal.componentInstance.groups = this.groups;
    activeModal.componentInstance.groupContent_context = this.groupContent_context;

    const self = this;

    activeModal.result
      .then(async (res) => {
        // on close
        if (typeof res === 'boolean' && res) {
          //do nothing
        } else if (typeof res === 'boolean' && !res) {
          //do nothing
        }
        // OK
        else if (typeof res === 'object') {
          if (res.adviseMessage && res.adviseMessage.length > 0) {
            throw new Error(res.adviseMessage[0].description);
          }

          this.notificationService.success('Supplier succesfully created.', 'Success');
          await self.retrieveAllSuppliers();
        }
      })
      .catch((error) => {
        this.notificationService.error(error);
      });
  }

  onToggleArchiveDearchiveSupplier(supplier) {
    this.suppliersService.onToggleArchive$.next(supplier);
  }

  buildForm() {
    this.queryFormOrganizations = this.fb.group({
      codes: [''],
      includeArchived: false,
    });
  }

  buildDropdown() {
    this.organizations = [];
    this.orgSelectors.forEach((org) => {
      this.organizations.push({ code: org.code, label: org.label + ' (' + org.code + ')' });
    });
  }

  buildOrganizationsDropdown(groupsToAdd: any) {
    if (this.organizations.length === 0) {
      this.organizations = [];
    }

    groupsToAdd.forEach((group) => {
      let label = group.label + ' (' + group.code + ')';
      label += group.isTeam ? ' > TEAM' : '';
      this.organizations.push({ code: group.code, label: label, isTeam: group.isTeam });
    });
  }

  async loadAllProviders() {
    this.providers = [];
    const allProviders = await this.providersService.getAllProviders();
    allProviders.forEach((providerNode) => {
      this.providers.push({ code: providerNode.providerData.code, label: providerNode.providerData.name });
    });
  }

  runQuery() {
    this.retrieveAllSuppliers();
  }

  async onLogs(supplier: any) {
    const activeModal = this.modalService.open(SupplierLogsModalComponent, {
      size: 'xl',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Supplier logs: ' + supplier.name;
    activeModal.componentInstance.supplierCode = supplier.code;
  }
}
