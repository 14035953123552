import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../../../../../../..//web-admin/src/app/@core/services/notification.service';
import { WebAdminService } from '../../../../../../../../..//web-admin/src/app/@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { Client } from '../../../../interfaces/models/client.interface';
import { OrganizationSelector } from '../../../../interfaces/_index-entities.interfaces';
import { ClientsEntitiesService } from '../../../../services/clients-entities.service';
import { Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModuleType, Options } from './../../../../../../@core/interfaces/_index.interfaces';
import { OrganizationEntitiesService } from '../../../../services/organization-entities.service';
import { ClientsToggleActiveModalComponent } from '../clients-ToggleActive-modal/clients-ToggleActive-modal.component';
import { ClientsSafeDeactivateModalComponent } from '../clients-safeDeactivate-modal/clients-safeDeactivate-modal.component';
import { ClientsLegacyPasswordModalComponent } from '../clients-legacyPassword-modal/clients-legacyPassword-modal.component';
import { ClientsLogsModalComponent } from '../clients-logs-modal/clients-logs-modal.component';

@Component({
  selector: 'tgx-admin-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
})
export class ClientsTableComponent implements OnInit, OnDestroy {
  isLoading: boolean;

  clients: Client[];
  clientsLoadSource: any[];
  groups: Options[];

  $subs: Subscription[] = [];

  public queryForm: UntypedFormGroup;
  organizations: Options[];
  orgSelectors: OrganizationSelector[];
  selectedOrg: Options;
  totalClients = 0;
  mapPageCursor: Map<number, string> = new Map<number, string>();
  page = 1;

  constructor(
    private modalService: NgbModal,
    private webAdminService: WebAdminService,
    private clientsEntitiesService: ClientsEntitiesService,
    private notificationService: NotificationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private organizationsService: OrganizationEntitiesService,
  ) {}

  buildOptions() {
    this.webAdminService.emitUserTypevalue(ModuleType.ENTITIES);
  }

  ngOnInit(): void {
    this.buildOptions();
    this.buildForm();
    this.isLoading = true;
    this.organizations = [];
    this.orgSelectors = [];
    this.retrieveClients();

    this.$subs.push(
      this.webAdminService.allOrgs.subscribe((orgs) => {
        if (orgs?.length > 0) {
          this.orgSelectors = orgs;
          this.buildOrganizationsDropdown(orgs);
        }
      }),
      this.webAdminService.allTeams.subscribe((teams) => {
        if (teams?.length > 0) {
          this.orgSelectors = this.orgSelectors.concat(teams);
          this.buildOrganizationsDropdown(teams);
          this.organizations = this.organizations.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
          );
        }
      }),
      this.clientsEntitiesService.onToggleTable$.subscribe((client) => {
        if (client) {
          this.onToggleActivateDeactiveClient(client);
        }
      }),

      this.clientsEntitiesService.onToggleArchive$.subscribe((client) => {
        if (client) {
          if (client.deletedAt === true) {
            this.clientsEntitiesService
              .unArchiveClient({ search: client.name, searchBy: 'NAME' })
              .then(() => {
                client.deletedAt = false;
                this.notificationService.info('The client was successfully unArchived!');
              })
              .catch((er) => {
                client.deletedAt = true;
                client.isArchived = true;
                this.notificationService.error(er);
              });
            client.deletedAt = false;
          } else {
            this.clientsEntitiesService
              .archiveClient({ search: client.name, searchBy: 'NAME' })
              .then(() => {
                client.deletedAt = true;
                this.notificationService.info('The client was successfully archived!');
              })
              .catch((er) => {
                client.deletedAt = false;
                client.isArchived = false;
                this.notificationService.error(er);
              });
          }
        }
      }),
    );
  }

  async onSafeDeactivate(event: any) {
    const client: any = event.data;
    const activeModal = this.modalService.open(ClientsSafeDeactivateModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Confirm deactivate: ' + client.code;
    activeModal.componentInstance.client = client;
  }

  async onToggleActivateDeactiveClient(client): Promise<void> {
    const activeModal = this.modalService.open(ClientsToggleActiveModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    const operation = !client.isActiveModel ? 'Deactivate' : 'Activate';

    activeModal.componentInstance.header = operation + ' clients';
    activeModal.componentInstance.message = 'Do you really want to ' + operation + ' this client?';
    activeModal.componentInstance.btnLabel = operation;
    activeModal.dismissed.subscribe(() => {
      client.isActiveModel = client.isActive;
    });
    activeModal.result.then((res) => {
      if (typeof res === 'boolean' && res) {
        if (client.isActiveModel) {
          this.activateClient(client);
        } else {
          this.clientsEntitiesService
            .safeDeactivateClient(client)
            .then((result) => {
              if (result.adviseMessage && result.adviseMessage.length > 0 && result.adviseMessage[0].code == '428') {
                this.onCustom({ action: 'client-deactivate', data: client });
              } else {
                this.notificationService.success('Client was successfully deactivated');
              }
            })
            .catch((err) => {
              this.notificationService.error('There was an error deactivating the Client, please contact your admin');
            });
        }
      }
    });
  }

  onToggleArchiveDearchiveClient(client) {
    this.clientsEntitiesService.onToggleArchive$.next(client);
  }

  async deactivateClient(client) {
    const operationMsg = client.isActiveModel ? 'Activated' : 'Deactivated';
    this.clientsEntitiesService
      .deactivateClient({ search: client.name, searchBy: 'NAME' })
      .then((rs) => {
        this.notificationService.success('Client succesfully ' + operationMsg + '.', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async activateClient(client) {
    const operationMsg = client.isActiveModel ? 'Activated' : 'Deactivated';
    this.clientsEntitiesService
      .activateClient({ search: client.name, searchBy: 'NAME' })
      .then((rs) => {
        this.notificationService.success('Client succesfully ' + operationMsg + '.', 'Success');
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      });
  }

  async getSelectedGroups(orgCodes: any) {
    const groupsPerOrganization = await this.organizationsService.getGroupsByCode(orgCodes);
    return [].concat.apply(
      [],
      groupsPerOrganization.map((value) => {
        return value.node.groupData.descendents.edges.map((descendent) => {
          return descendent.node.code;
        });
      }),
    );
  }

  async retrieveClients() {
    //set filter in query.
    const input = { includeArchived: false };
    input['includeArchived'] = this.queryForm.get('includeArchived')?.value ?? false;

    const clients = await this.clientsEntitiesService.getClientsByOwner(input);
    if (clients?.length > 0) {
      this.clients = clients;
      this.totalClients = clients.length;
      this.buildClients();
    } else {
      this.isLoading = false;
    }
  }

  buildClients() {
    this.clientsLoadSource = [];
    this.clients.forEach((mb) => {
      this.clientsLoadSource.push({
        code: mb.node.clientData.name + ' (' + mb.node.clientData.code + ')',
        isActive: mb.node.clientData.isActive,
        isActiveModel: mb.node.clientData.isActive,
        name: mb.node.clientData.name,
        ownerCode: mb.node.clientData.owner?.code
          ? mb.node.clientData.owner.organizationData.label + ' (' + mb.node.clientData.owner.code + ')'
          : '-',
        groupCode: mb.node.clientData.group?.code,
        deletedAt: !mb.node.deletedAt ? false : true,
        isArchived: !mb.node.deletedAt ? false : true,
      });
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.clientsEntitiesService.onToggleArchive$.next(null);
    this.$subs.forEach((s) => s.unsubscribe());
  }

  onCustom($event): void {
    switch ($event.action) {
      case 'legacy-connections':
        this.onLegacyConnections($event);
        break;
      case 'client-deactivate':
        this.onSafeDeactivate($event);
        break;
    }
  }

  onLegacyConnections(client: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        clientName: client.name,
      },
    };
    this.router.navigate(['/entities/legacy-connections'], navigationExtras);
  }

  getClientCode(input: string): string | null {
    const regex = /\(([^)]+)\)/;
    const match = input.match(regex);
    return match ? match[1] : null;
  }

  async onLegacyPassword(client: any) {
    const clientCode = this.getClientCode(client.code);
    if (!clientCode) {
      this.notificationService.error('Client name > code format error');
      return;
    }
    const legacyPassword = await this.clientsEntitiesService.getLegacyPassword(clientCode);

    const activeModal = this.modalService.open(ClientsLegacyPasswordModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Legacy password form client: ' + client.name;
    activeModal.componentInstance.password = legacyPassword.password != '' ? legacyPassword.password : ' - ';
  }

  async onLogs(client: any) {
    const regex = /\((.*?)\)/;
    const match = client.code.match(regex);
    let clientCode = '';

    if (match) {
      clientCode = match[1];
    }

    const activeModal = this.modalService.open(ClientsLogsModalComponent, {
      size: 'xl',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Client logs: ' + client.name;
    activeModal.componentInstance.clientCode = clientCode;
  }

  buildForm() {
    this.queryForm = this.fb.group({
      codes: [''],
      includeArchived: false,
    });
  }

  buildDropdown() {
    this.organizations = [];
    this.orgSelectors.forEach((org) => {
      this.organizations.push({ code: org.code, label: org.label + ' (' + org.code + ')' });
    });
  }

  buildOrganizationsDropdown(groupsToAdd: any) {
    if (this.organizations.length === 0) {
      this.organizations = [];
    }

    groupsToAdd.forEach((group) => {
      let label = group.label + ' (' + group.code + ')';
      label += group.isTeam ? ' > TEAM' : '';
      this.organizations.push({ code: group.code, label: label, isTeam: group.isTeam });
    });
  }

  resetTable() {
    this.buildForm();
    this.page = 1;
    this.mapPageCursor = new Map<number, string>();
    this.retrieveClients();
  }

  runQuery() {
    this.page = 1;
    this.mapPageCursor = new Map<number, string>();
    this.retrieveClients();
  }
}
