import { NgModule } from '@angular/core';
import { NotificationsRoutingModule } from './notifications.routing-module';
import { NotificationsComponent } from './components/notifications.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NbCardModule } from '@nebular/theme';
import { NgbModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedUiStorybookModule } from '@tgx/shared/ui/storybook';
import { SharedModule } from '../../../../@core/shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    SharedUiStorybookModule,
    ReactiveFormsModule,
    CommonModule,
    NotificationsRoutingModule,
    NbCardModule,
    NgbModule,
    NgbTooltipModule,
  ],
  declarations: [NotificationsComponent],
  providers: [NgbTooltipConfig],
})
export class NotificationsModule {}
