import { Component, OnInit, ViewChild } from '@angular/core';
import { WebAdminService } from './../../../../../../@core/services/web-admin.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbStepperComponent } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { PreAndPostCheckService } from '../../../../services/pre-and-post-check.service';

@Component({
  selector: 'tgx-invoicing-process-post-step',
  templateUrl: './invoicing-process-post-step.component.html',
  styleUrls: ['./invoicing-process-post-step.component.scss'],
})
export class InvoicingProcessPostStepComponent implements OnInit {
  @ViewChild('stepper') stepper: NbStepperComponent;

  constructor(
    private webAdminService: WebAdminService,
    private preAndPostCheckService: PreAndPostCheckService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  isEditor = false;
  public dateForm: UntypedFormGroup;

  currentStep: number;

  ngOnInit(): void {
    this.dateForm = this.fb.group({
      date1: ['', Validators.required],
      date2: ['', Validators.required],
    });

    this.buildOptions();
    this.currentStep = 1;
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  finishStepper() {
    this.stepper.reset();
    this.navigateToScheduled();
  }

  onConfigChange() {
    const startDate = this.dateForm.get('date1').value;
    const endDate = this.dateForm.get('date2').value;

    if (startDate && endDate) {
      this.preAndPostCheckService.changePostConfig({ step: this.currentStep, date_from: startDate, date_to: endDate });
    }
  }

  changeCurrentStep(step: number) {
    this.currentStep = step;
    this.onConfigChange();
  }

  navigateToScheduled() {
    this.router.navigate(['../scheduled'], { relativeTo: this.route });
  }
}
