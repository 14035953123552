import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../../@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProvidersTableComponent } from './components/providers-table/providers-table.component';
import { ProvidersComponent } from './components/providers.component';
import { ProvidersRoutingModule } from './providers-routing.module';
import { ProviderModalComponent } from './components/provider-modal/provider-modal.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';

import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbToggleModule } from '@nebular/theme';
import { ProviderLogsModalComponent } from './components/providers-logs-modal/providers-logs-modal.component';

@NgModule({
  declarations: [ProvidersTableComponent, ProvidersComponent, ProviderModalComponent, ProviderLogsModalComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbActionsModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    ProvidersRoutingModule,
    NbToggleModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
  ],
})
export class ProvidersModule {}
