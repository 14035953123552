<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'200px'"></tgx-spinner>
<nb-card>
  <nb-card-header>
    {{ isEditor ? 'Select a contract' : 'Warning' }}
  </nb-card-header>
  <nb-card-body *ngIf="!isEditor">
    You are in the billing group, but you must be an admin to view and execute this operation.
  </nb-card-body>
  <nb-card-body *ngIf="isEditor">
    <div class="row mb-3">
      <div class="col-1">
        <label class="mt-2">Contract: </label>
      </div>
      <div class="col-6">
        <p-dropdown
          [options]="contractsSelector"
          [appendTo]="'body'"
          baseZIndex="999"
          [disabled]="contractsSelector[0]?.code === null"
          [(ngModel)]="selectedContract"
          optionLabel="label"
          filterBy="label"
          (onHide)="resetForm(); getInvoices()"
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedContract">
              <div>{{ selectedContract?.label }}</div>
            </div>
          </ng-template>
          <ng-template let-ci pTemplate="ci">
            <div>
              <div>{{ ci.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<nb-card *ngIf="contractsSelector[0]?.code !== null">
  <nb-card-header> Create a Credit Note </nb-card-header>
  <nb-card-body *ngIf="!isLoading">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="mb-1" for="invoice">Invoice referenced: (Optional) </label>
          <p-dropdown
            [options]="invoicesSelector"
            [appendTo]="'body'"
            baseZIndex="999"
            [disabled]="contractsSelector[0]?.code === null || invoicesSelector?.length === 1"
            [(ngModel)]="selectedInvoice"
            optionLabel="label"
            filterBy="label"
            [filter]="true"
            (onHide)="assignStartAndEnd()"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="selectedInvoice">
                <div>{{ selectedInvoice?.label }}</div>
              </div>
            </ng-template>
            <ng-template let-ci pTemplate="ci">
              <div>
                <div>{{ ci.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>

    <form [formGroup]="createForm">
      <div class="row mt-4">
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="start"
              >Start Date
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error
              [element]="createForm.get('start')"
              [errors]="createForm.get('start').errors"
            ></tooltip-error>
            <p-calendar
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="start"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
              placeholder="Enter a date.."
            ></p-calendar>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="endDate"
              >End Date
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error [element]="createForm.get('end')" [errors]="createForm.get('end').errors"></tooltip-error>
            <p-calendar
              [disabled]="!createForm.get('start').value || selectedInvoice?.code !== null"
              [minDate]="createForm.get('start').value"
              [appendTo]="'body'"
              baseZIndex="999"
              class="form-control p-0 border-0 w-100"
              formControlName="end"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              inputId="icon"
              [readonlyInput]="true"
              placeholder="Enter a date.."
            ></p-calendar>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="concept"
              >Concept
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error
              [element]="createForm.get('concept')"
              [errors]="createForm.get('concept').errors"
            ></tooltip-error>
            <input type="text" class="form-control" name="concept" formControlName="concept" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="mb-1" for="amount"
              >Amount
              <span class="mandatory ml-1">*</span>
            </label>
            <tooltip-error
              [element]="createForm.get('amount')"
              [errors]="createForm.get('amount').errors"
            ></tooltip-error>
            <input
              type="number"
              class="form-control"
              formControlName="amount"
              name="amount"
              (blur)="checkAmountType()"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer mt-4">
        <button class="btn btn-md btn-danger" (click)="resetForm()">Reset</button>
        <button class="btn btn-md btn-primary" [disabled]="!validForm()" (click)="runCreate()">Bill</button>
      </div>
    </form>
  </nb-card-body>
  <nb-card-body *ngIf="isLoading"> Your credit note is being generated, please be patient.. </nb-card-body>
</nb-card>
