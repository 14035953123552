import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditoryService } from '../../../../services/auditory-service';

@Component({
  selector: 'tgx-members-logs-modal',
  templateUrl: './members-logs-modal.component.html',
  styleUrls: ['./members-logs-modal.component.scss'],
})
export class MembersLogsModalComponent implements OnInit {
  @Input() memberCode: string;
  @Input() header: string;

  public queryFormAuditory: UntypedFormGroup;
  protected isLoading = true;
  protected loadingIcon = 'pi pi-spin pi-spinner';

  tableHeader = [
    { label: 'Code/ID', field: 'code' },
    { label: 'Action', field: 'actionType' },
    { label: 'Field / Previous / Current', field: 'auditDetails' },
    { label: 'Date', field: 'createdAt' },
    { label: 'Modified by', field: 'modifiedBy' },
  ];

  auditorySource: any[];

  constructor(
    private activeModal: NgbActiveModal,
    private auditoryService: AuditoryService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.runQuery();
  }

  async runQuery() {
    const default_order = 'created_at_DESC';
    const where = {
      entity_type: 'MEMBER',
      code_in: [this.memberCode],
    };

    const source = await this.auditoryService.getAuditory(where, default_order);
    this.buildLogs(source);
    this.isLoading = false;
  }

  buildLogs(logs: any[]) {
    const source_logs = [];
    logs.forEach((log) => {
      const data = this.formatLogSource(log.node);
      source_logs.push(data);
    });
    this.auditorySource = source_logs;
  }

  formatLogSource(log: any) {
    return {
      code: log.entityAuditData.code ?? log.entityAuditData.id,
      actionType: log.entityAuditData.actionType,
      auditDetails: log.entityAuditData.auditDetails,
      createdAt: new Date(log.createdAt).toLocaleString(['fr-FR'], {}),
      modifiedBy: log.entityAuditData.modifiedBy,
    };
  }

  closeModal() {
    this.activeModal.close(true);
  }
}
