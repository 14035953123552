import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from 'apps/web-admin/src/app/@core/shared/not-found/not-found.component';
import { BillingInvoiceLegacyConciliateComponent } from './conciliate/components/invoice-legacy-conciliate/invoice-legacy-conciliate.component';
import { BillingInvoiceCreateComponent } from './create/components/invoice-create/invoice-create.component';
import { InvoicesComponent } from './invoices.component';
import { BillingInvoicesTableComponent } from './list/components/invoices-table/invoices-table.component';
import { BillingInvoiceNewPricingConciliateComponent } from './new-pricing-conciliate/components/invoice-newpricing-conciliate/invoice-newpricing-conciliate.component';
import { BillingCreateCreditNoteComponent } from './credit-note/components/create-credit-note/create-credit-note.component';

const routes: Routes = [
  {
    path: '',
    component: InvoicesComponent,
    children: [
      {
        path: 'list',
        component: BillingInvoicesTableComponent,
      },
      {
        path: 'credit-note',
        component: BillingCreateCreditNoteComponent,
      },
      {
        path: 'create',
        component: BillingInvoiceCreateComponent,
      },
      {
        path: 'legacy-conciliate',
        component: BillingInvoiceLegacyConciliateComponent,
      },
      {
        path: 'newpricing-conciliate',
        component: BillingInvoiceNewPricingConciliateComponent,
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvoicesRoutingModule {}
