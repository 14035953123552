import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Options } from './../../../../../../../@core/interfaces/_index.interfaces';
import { NotificationService } from './../../../../../../../@core/services/notification.service';
import { WebAdminService } from './../../../../../../../@core/services/web-admin.service';
import { Subscription } from 'rxjs';
import { OrganizationSelector } from './../../../../../../../features/entities/interfaces/_index-entities.interfaces';
import { ContractService, PartnerService, InvoiceService } from '../../../../../services/_index-billing.services';
import { Contract, Invoice, Partner } from '../../../../../interfaces/_index.billing.interfaces';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './../../../../../../../@core/shared/modal-delete/modal-delete.component';

@Component({
  selector: 'tgx-admin-billing-create-credit-note',
  templateUrl: './create-credit-note.component.html',
  styleUrls: ['./create-credit-note.component.scss'],
})
export class BillingCreateCreditNoteComponent implements OnInit, OnDestroy {
  public createForm: UntypedFormGroup;
  contractsSelector: Options[] = [{ code: null, label: 'Without contracts' }];
  selectedContract: Options;

  $subs: Subscription[] = [];
  orgSelected: OrganizationSelector;

  contracts: Contract[];
  partner: Partner;

  isLoading: boolean;
  isEditor: boolean;

  invoices: Invoice[];
  invoicesSelector: Options[] = [{ code: null, label: 'None' }];
  selectedInvoice: Options;

  constructor(
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private fb: UntypedFormBuilder,
    private partnerService: PartnerService,
    private contractService: ContractService,
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
  ) {}

  async ngOnInit() {
    this.buildForm();

    this.buildOptions();

    if (this.isEditor) {
      this.$subs.push(
        this.webAdminService.orgSelected.subscribe(async (orgSelected) => {
          if (orgSelected?.code) {
            this.orgSelected = orgSelected;
            await this.partnerService.getPartner(orgSelected.code).then(async (partner) => {
              if (partner) {
                this.partner = partner;
                this.createForm.get('partner').setValue(this.partner.id);
                await this.getContracts(this.partner.orgCode);
              } else {
                this.partner = null;
                this.withoutContracts();
                this.notificationService.warning('This partner doesnt exists in billing section.');
              }
            });
          }
        }),
      );
    }
  }

  ngOnDestroy() {
    this.$subs.forEach((s) => s.unsubscribe());
  }

  buildForm() {
    this.createForm = this.fb.group({
      partner: ['', Validators.required],
      contract: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      concept: ['', Validators.required],
      amount: ['', Validators.required],
      invoice: [''],
    });
  }

  resetForm() {
    this.createForm.get('partner').setValue(this.partner.id);
    this.createForm.get('contract').setValue(Number(this.selectedContract.code));
    this.createForm.get('start').setValue('');
    this.createForm.get('start').setErrors(null);
    this.createForm.get('end').setValue('');
    this.createForm.get('end').setErrors(null);
    this.createForm.get('concept').setValue('');
    this.createForm.get('amount').setValue('');
    this.createForm.get('concept').setErrors(null);
    this.createForm.get('amount').setErrors(null);
    this.createForm.get('invoice').setValue('');
    this.createForm.get('invoice').setErrors(null);

    this.createForm.get('start').enable();
    this.createForm.get('end').enable();
    this.createForm.get('start').markAsUntouched();
    this.createForm.get('end').markAsUntouched();

    this.selectedInvoice = null;
    if (this.invoices?.length > 0) {
      this.buildInvoicesSelector();
    } else {
      this.withoutInvoices();
    }
  }

  async getContracts(orgCode: string) {
    if (orgCode) {
      await this.contractService
        .getContracts(orgCode)
        .then(async (cnts) => {
          if (cnts?.length > 0) {
            this.contractsSelector = [];
            this.contracts = cnts;

            if (this.contracts?.length === 0) {
              this.withoutContracts();
            } else {
              this.buildContractSelector();
              this.selectedContract = this.contractsSelector[0];
              this.createForm.get('contract').setValue(Number(this.selectedContract.code));
              await this.getInvoices();
            }
          } else {
            this.withoutContracts();
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });
    }
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  buildContractSelector() {
    if (this.contracts?.length > 0) {
      this.contracts.forEach((c) => {
        this.contractsSelector.push({
          code: c.id.toString(),
          label:
            '(' +
            c.id +
            ') ' +
            c.partnerType +
            (c.modelType ? ' - ' + c.modelType : '') +
            (c.ContractType ? ' - ' + c.ContractType.name : ''),
        });
      });
    }
  }

  withoutContracts() {
    this.contractsSelector = [];
    this.contractsSelector.push({ code: null, label: 'Without contracts' });
  }

  async getInvoices() {
    this.invoices = [];

    if (this.selectedContract?.code) {
      await this.invoiceService
        .getInvoices(Number(this.selectedContract?.code))
        .then((rs) => {
          if (rs?.length > 0) {
            this.invoices = rs;
          }
        })
        .catch((err) => {
          this.notificationService.handleGatewayAndGraphqlErrors(err);
        });

      if (this.invoices?.length > 0) {
        this.buildInvoicesSelector();
      } else {
        this.withoutInvoices();
      }
    } else {
      this.withoutInvoices();
    }
  }

  withoutInvoices() {
    this.invoicesSelector = [];
    this.invoicesSelector.push({ code: null, label: 'Without invoices' });
  }

  buildInvoicesSelector() {
    this.invoicesSelector = [];
    this.invoicesSelector.push({ code: null, label: 'None' });

    this.invoices = this.invoices.slice().sort((x, y) => {
      if (x.id > y.id) {
        return -1;
      }
      if (x.id < y.id) {
        return 1;
      }
      return 0;
    });

    this.invoices?.forEach((inv) => {
      const amount = Number(inv.amount);
      if (amount > 0) {
        const startMonth = formatDate(inv.startDate, 'MMM-yy', 'en-US');

        this.invoicesSelector.push({
          code: inv.id.toString(),
          label: `InvoiceId: ${inv.id} Amount: ${inv.amount} ${inv.currency} Month: ${startMonth} OdooCode: ${inv.odooCode ? inv.odooCode : 'Without code'}`,
        });
      }
    });
  }

  validForm(): boolean {
    return (
      this.createForm.get('start').value &&
      this.createForm.get('end').value &&
      this.createForm.get('concept').value &&
      this.createForm.get('amount').value &&
      Number(this.createForm.get('amount').value) < 0
    );
  }

  assignStartAndEnd() {
    if (this.selectedInvoice?.code) {
      const index = this.invoices.findIndex((x) => x.id === Number(this.selectedInvoice?.code));
      if (index > -1) {
        this.createForm.get('start').setValue(new Date(this.invoices[index].startDate));
        this.createForm.get('end').setValue(new Date(new Date(this.invoices[index].endDate).setDate(-0.5)));
        this.createForm.get('start').disable();
        this.createForm.get('end').disable();
        return;
      }
    }
    this.createForm.get('start').setValue('');
    this.createForm.get('start').setErrors(null);
    this.createForm.get('end').setValue('');
    this.createForm.get('end').setErrors(null);
    this.createForm.get('invoice').setValue('');
    this.createForm.get('invoice').setErrors(null);
    this.createForm.get('start').enable();
    this.createForm.get('end').enable();
    this.createForm.get('start').markAsUntouched();
    this.createForm.get('end').markAsUntouched();
  }

  async runCreate() {
    const startDate = formatDate(this.createForm.get('start').value, 'yyyy-MM-dd', 'en-US');
    const endDate = formatDate(this.createForm.get('end').value, 'yyyy-MM-dd', 'en-US');
    const concept = this.createForm.get('concept').value
      ? this.createForm.get('concept').value.replaceAll('-', '')
      : '';
    const amount = Number(this.createForm.get('amount').value);

    const modalRef = this.modalService.open(ModalDeleteComponent, {
      backdrop: 'static',
      container: 'nb-layout',
    });

    modalRef.componentInstance.message =
      'Are you sure that you want to create a new credit note with these parameters?';
    modalRef.componentInstance.title = 'Create Credit Note';
    modalRef.componentInstance.buttonOkTitle = 'Bill';

    const self = this;

    const invoiceId = this.selectedInvoice?.code ? this.selectedInvoice.code : '';

    modalRef.result
      .then(async (res) => {
        // on close
        if (res) {
          this.isLoading = true;
          await this.invoiceService
            .createInvoiceManually(
              this.partner.id,
              Number(this.selectedContract.code),
              'free',
              'All',
              startDate,
              endDate,
              concept,
              amount,
              '',
              invoiceId,
            )
            .then((file) => {
              const fileName = 'billing_' + endDate + '.json';
              saveAs(file, fileName);
              this.notificationService.success(
                'Invoice succesfully generated and ' + fileName + ' downloaded!',
                'Success',
              );
            })
            .catch((err) => {
              const parsedErr = JSON.parse(err.error);
              this.notificationService.handleGatewayAndGraphqlErrors(parsedErr.errors[0]);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      })
      .catch((error) => {
        self.notificationService.handleGatewayAndGraphqlErrors(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  checkAmountType() {
    if (this.createForm.get('amount').value) {
      const amount = Number(this.createForm.get('amount').value);

      if (amount > 0) {
        this.notificationService.warning('If you create a positive invoice you have to use the other option (Create).');
      }
    }
  }
}
