<tgx-spinner [class]="'routingLoading'" [isLoading]="isLoading" [top]="'180px'"></tgx-spinner>
<div *ngIf="!isLoading">
  <form [formGroup]="queryFormOrganizations">
    <nb-card>
      <nb-card-header> Build your query </nb-card-header>
      <nb-card-body>
        <div class="row mb-3">
          <!--Owner filter-->
          <div class="col-2">
            <div class="form-group">
              <div class="d-inline-block w-100">
                <p-multiSelect
                  [options]="organizations"
                  defaultLabel="Buyer"
                  formControlName="filter_owners"
                  optionLabel="label"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  selectedItemsLabel="{0} Buyer/s selected"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>

          <!--Supplier filter-->
          <div class="col-2">
            <div class="form-group">
              <div class="d-inline-block w-100">
                <p-multiSelect
                  [options]="suppliers"
                  defaultLabel="Supplier code"
                  formControlName="filter_supplier"
                  optionLabel="label"
                  [appendTo]="'body'"
                  baseZIndex="999"
                  selectedItemsLabel="{0} Supplier/s selected"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>

          <!--AccessID filter-->
          <div class="col-2">
            <div class="form-group">
              <div class="d-inline-block w-100">
                <input type="text" formControlName="filter_accessID" placeholder="Access ID" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4">
            <div class="form-group">
              <div class="d-inline-block w-100">
                Show archived
                <nb-toggle [checked]="false" formControlName="includeArchived"></nb-toggle>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-md btn-danger" (click)="resetTable()">Reset</button>
          <button class="btn btn-md btn-primary" [disabled]="queryFormOrganizations.invalid" (click)="runQuery()">
            Run
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </form>
</div>

<nb-card *ngIf="!isLoading">
  <nb-card-header>
    <div class="row">
      <div class="col-3" *ngIf="selectedAccesses?.length === 0">
        <span>Accesses</span>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p-table
      #dt1
      [value]="accessesLoadSource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem', 'z-index': -1 }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let head of header" [pSortableColumn]="head.field">
            {{ head.label }}
            <p-sortIcon *ngIf="head.field !== 'Options'" [field]="head.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let head of header" [style]="head.field === 'Options' ? 'width: 3rem' : ''">
            <p-columnFilter
              [showClearButton]="false"
              [placeholder]="head.placeholder"
              [type]="selectType(head.field)"
              *ngIf="head.field !== 'Options'"
              [field]="head.field"
              matchMode="{{ head.field !== 'shared' ? 'contains' : 'in' }}"
              (input)="dt1.filter($event.target.value, head.field, 'contains')"
            >
              <ng-template *ngIf="head.field === 'shared'" pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  placeholder="All"
                  [ngModel]="value"
                  [options]="sharedAccesses"
                  (onChange)="filter($event.value)"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option === '' ? 'Empty' : option }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-button
              *ngIf="head.field === 'Options'"
              class="p-button"
              icon="pi pi-plus"
              (onClick)="onCreate()"
            ></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-access>
        <tr>
          <td>{{ access.code }}</td>
          <td>{{ access.name }}</td>
          <td>{{ access.ownerCode }}</td>

          <td [ngClass]="!access.isSupplierActive ? 'disabledSupplier' : ''">
            <span [title]="!access.isSupplierActive ? 'NO ACTIVE' : ''">
              {{ access.supplier }}
            </span>
          </td>
          <td>
            <p-inputSwitch
              [(ngModel)]="access.isActiveModel"
              [disabled]="access.isDeleted || !access.isSupplierActive"
              (onChange)="toggleActive(access)"
            ></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch
              [(ngModel)]="access.isDeleted"
              [disabled]="access.isActive"
              (onChange)="toggleArchive(access)"
            ></p-inputSwitch>
          </td>
          <td>{{ access.shared }}</td>
          <td>
            <i title="View Details" class="icon nb-edit" (click)="onEdit(access)"></i>
            <i class="nb-shuffle icon" title="Manage Accesses groups" (click)="onCustom(access, 'access-groups')"></i>
            <i class="nb-compose icon mx-2" title="Logs" (click)="onLogs(access)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="text-center">No accesses found.</td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
