import { gql } from 'apollo-angular';

export const getLegacyPasswordByClient = gql`
  query ($clientCode: ID!) {
    admin {
      clientLegacyPassword(where: { search: $clientCode, searchBy: CODE }) {
        code
        password
        adviseMessage {
          code
          description
        }
      }
    }
  }
`;
