export enum ChargeType {
  SETUP = 'SETUP',
  MAINTENANCE = 'MAINTENANCE',
  TRAFFIC_SCALED = 'TRAFFIC_SCALED',
  TRAFFIC_GLOBAL = 'TRAFFIC_GLOBAL',
  BOOKING_SCALED = 'BOOKING_SCALED',
  BOOKING_GLOBAL = 'BOOKING_GLOBAL',
  BOOKING_L2B = 'BOOKING_L2B',
  EXCESS = 'EXCESS',
  GMV = 'GMV',
  EXECTIME = 'EXECTIME',
  CHANNELX_COSTPERHOTEL = 'CHANNELX_COSTPERHOTEL',
  SPEEDCOST = 'SPEEDCOST',
  INSIGHTS = 'INSIGHTS',
}

export enum ContractRateType {
  TRAFFIC_SCALED = 'TRAFFIC_SCALED',
  TRAFFIC_GLOBAL = 'TRAFFIC_GLOBAL',
  BOOKING_SCALED = 'BOOKING_SCALED',
  BOOKING_GLOBAL = 'BOOKING_GLOBAL',
  BOOKING_L2B = 'BOOKING_L2B',
  GMV = 'GMV',
  EXECTIME = 'EXECTIME',
  NEW_PRICING_GMV = 'NEW_PRICING_GMV',
  NEW_PRICING_BOOKING = 'NEW_PRICING_BOOKING',
  PLUGIN = 'PLUGIN',
}

export enum PaymentType {
  STANDARD = 'STANDARD',
  SUPPLEMENT = 'SUPPLEMENT',
  SUPPLEMENT_CM = 'SUPPLEMENT_CM',
  SUPPLEMENT_FS = 'SUPPLEMENT_FS',
  SUPPLEMENT_AF = 'SUPPLEMENT_AF',
  FREE = 'FREE',
}

export enum SubDataType {
  OK = 'OK',
  CANCELLED = 'CANCELLED',
}

export enum PartnerType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  OTHER = 'OTHER',
}

export enum PartnerModelType {
  POWER = 'POWER',
  SELECTIVE_PREMIUM = 'SELECTIVE_PREMIUM',
  FULL_PREMIUM = 'FULL_PREMIUM',
  CM22 = 'CM22',
  CM23 = 'CM23',
}

export enum ProductType {
  CONNECTION = 'CONNECTION',
  PLUGIN = 'PLUGIN',
}

export enum ProductSubType {
  HOTEL = 'HOTEL',
  TRANSPORTATION = 'TRANSPORTATION',
  CAR = 'CAR',
  ACTIVITIES = 'ACTIVITIES',
  PACKAGE = 'PACKAGE',
  PAYMENT = 'PAYMENT',
  TRANSFERS = 'TRANSFERS',
  VIRTUALACCOUNT = 'VIRTUALACCOUNT',
  CHANNELX = 'CHANNELX',
  SPEED = 'SPEED',
  INSIGHTS = 'INSIGHTS',
}

export enum CostType {
  COST = 'COST',
  CONCILIATION = 'CONCILIATION',
  MINIMUM = 'MINIMUM',
  DISCOUNT = 'DISCOUNT',
}

export enum ConnectionType {
  STANDARD = 'STANDARD',
  STANDARD_SUPPLEMENT = 'STANDARD_SUPPLEMENT',
  FREE = 'FREE',
}

export enum requestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum connectionApi {
  HOTEL = 'HOTEL',
  TRANSPORTATION = 'TRANSPORTATION',
  CAR = 'CAR',
  ACTIVITIES = 'ACTIVITIES',
  PACKAGE = 'PACKAGE',
  PAYMENT = 'PAYMENT',
  TRANSFERS = 'TRANSFERS',
  VIRTUALACCOUNT = 'VIRTUALACCOUNT',
}

export enum pluginType {
  CHANNELX = 'CHANNELX',
  SPEED = 'SPEED',
  INSIGHTS = 'INSIGHTS',
  DEFAULT = 'DEFAULT',
}
