import { BillingContractSubTypeModalComponent } from './../subtype-modal/subtype-modal.component';
import { Table } from 'primeng/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractType } from '../../../../../interfaces/_index.billing.interfaces';
import { ContractTypeService } from '../../../../../services/_index-billing.services';
import { NotificationService, WebAdminService } from './../../../../../../../@core/services/_index-core.services';

@Component({
  selector: 'tgx-admin-billing-subtypes-table',
  templateUrl: './subtypes-table.component.html',
  styleUrls: ['./subtypes-table.component.scss'],
})
export class BillingContractsSubtypeTableComponent implements OnInit {
  @ViewChild('dt', { static: false }) dataTable!: Table;

  header = [
    { label: 'ID', field: 'id' },
    { label: 'Name', field: 'name' },
    { label: 'IsCalculable', field: 'isCalculable' },
    { label: 'CreatedAt', field: 'createdAt' },
    { label: 'UpdatedAt', field: 'updatedAt' },
  ];

  isLoading: boolean;

  isEditor = false;

  subtypesLoadSource: any[];

  contractTypes: ContractType[];

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private webAdminService: WebAdminService,
    private contractTypeService: ContractTypeService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildOptions();
    await this.getSubTypes();
  }

  buildOptions() {
    this.isEditor = this.webAdminService.isBillingEditorOrAdmin();
  }

  async getSubTypes() {
    this.isLoading = true;

    await this.contractTypeService
      .getContractTypes()
      .then((rs) => {
        this.contractTypes = rs;
        this.buildSubTypes();
      })
      .catch((err) => {
        this.notificationService.handleGatewayAndGraphqlErrors(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  buildSubTypes() {
    this.subtypesLoadSource = [];

    if (this.contractTypes?.length > 0) {
      this.contractTypes?.forEach((subType) => {
        this.subtypesLoadSource.push({
          'id': subType.id,
          'name': subType.name,
          'isCalculable': subType.isCalculable,
          'createdAt': subType.createdAt,
          'updatedAt': subType.updatedAt,
        });
      });
      this.subtypesLoadSource = [...this.subtypesLoadSource];
      this.subtypesLoadSource.sort((a, b) => (a.id < b.id ? 1 : -1));
    }
  }

  getFilterType(field: string) {
    switch (field) {
      case 'isCalculable':
        return 'boolean';
      default:
        return 'text';
    }
  }

  async onCreate(): Promise<void> {
    const activeModal = this.modalService.open(BillingContractSubTypeModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
    });

    activeModal.componentInstance.header = 'Create SubType';
    const self = this;
    activeModal.result.then(async (res) => {
      // OK
      if (typeof res === 'object') {
        await self.getSubTypes();
      }
    });
  }

  filter(value: any, field: string) {
    if (value) {
      this.dataTable.filter(value, field, 'contains');
    }
  }
}
